import React, {Component, Fragment, useImperativeHandle, useRef} from 'react';

import {
    Select, MenuItem, FormControl, InputLabel,
    InputBase, withStyles, Input, Checkbox,
    ListItemText,
} from '@material-ui/core'

import { Label, Input as BootInput } from 'reactstrap';
import PropTypes from 'prop-types';

import '../../assets/components/select/select.scss'
import {nullToBlank} from "../../utils/commUtils";

import $ from 'jquery'
import {BootstrapAutoComplete} from "../Autocomplete/Autocomplete";
import { commonLang } from '../../utils/commonLang';

function SimpleSelect( props ) {
    const {id, label, name, formClassName, labelClassName, selectClassName, value, onChange, menuItems, ...otherProperties} = props;

    const menuItemArr = [];
    menuItems.map(
        menuItem => ( menuItemArr.push(<MenuItem value={menuItem.value} key={menuItem.key} > {menuItem.content} </MenuItem>) )
    );

    return (
        <FormControl className={formClassName}>
            <InputLabel htmlFor="simple" className={labelClassName}>{label}</InputLabel>
            <Select
                value={value}
                className={selectClassName}
                inputProps={{
                    name: name,
                    id: id,
                }}
                onChange={onChange}
                {...otherProperties}
            >
                {menuItemArr}
            </Select>
        </FormControl>
    );
}


function SimpleNativeSelect( props ) {
    const {id, label, name, formClassName, labelClassName, selectClassName, value, onChange, menuItems, ...otherProperties} = props;

    // TODO
    const menuItemArr = [ <option value="" key=""/> ];
    menuItems.map(
        menuItem => ( menuItemArr.push(<option value={menuItem.value} key={menuItem.key} > {menuItem.content} </option>) )
    );

    return (
        <FormControl className={formClassName}>
            <InputLabel htmlFor={id} className={labelClassName}>{label}</InputLabel>
            <Select
                native
                value={value}
                className={selectClassName}
                inputProps={{
                    name: name,
                    id: id,
                }}
                onChange={onChange}
                {...otherProperties}
            >
                {menuItemArr}
            </Select>
        </FormControl>
    );
}

SimpleNativeSelect.propTypes = {
    menuItems: PropTypes.array
}



const BootstrapInput = withStyles(theme => ({
    root: {
      'label + &': {
        marginTop: theme.spacing(3),
      },
    },
    input: {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: theme.palette.background.paper,
      border: '1px solid #ced4da',
      fontSize: 16,
      padding: '10px 26px 10px 12px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      '&:focus': {
        borderRadius: 4,
        borderColor: '#80bdff',
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
      },
    },
}))(InputBase);
function CustomizedSelect(props) {
    const {label, name, id, formClassName, labelClassName, selectClassName, value, onChange, menuItems, ...otherProperties} = props


    // TODO
    const menuItemArr = [  ];
    menuItems.map(
        menuItem => ( menuItemArr.push(<MenuItem value={menuItem.value} key={menuItem.key} > {menuItem.content} </MenuItem>) )
    );

    return (
        <FormControl className={formClassName}>
            <InputLabel htmlFor={id} className={labelClassName}>{label}</InputLabel>

            <Select
                value={value}
                input={<BootstrapInput
                    name={name}
                    id= {id} />
                }
                onChange={onChange}
                className={selectClassName}
                {...otherProperties}
            >
            {menuItemArr}
            </Select>
        </FormControl>
    );
}
function CustomizedNativeSelect(props) {
    const {label, id, name, formClassName, labelClassName, selectClassName, value, onChange, menuItems, ...otherProperties} = props

    // TODO
    const menuItemArr = [ <option value="" key="" /> ];
    menuItems.map(
        menuItem => ( menuItemArr.push(<option value={menuItem.value} key={menuItem.key} > {menuItem.content} </option>) )
    );

    return (
        <FormControl className={formClassName}>
            <InputLabel htmlFor={id} className={labelClassName}>{label}</InputLabel>

            <Select
                native
                value={value}
                input={<BootstrapInput
                    name={name}
                    id= {id} />
                }
                onChange={onChange}
                className={selectClassName}
                {...otherProperties}
            >
            {menuItemArr}
            </Select>
        </FormControl>
    );
}


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
};
function MultiSelect(props) {
    const { label = 'multi', name, id, menuItems, formClassName, selectClassName, value, onChange, ...otherProperties } = props;

    const [allCheckState, setAllCheckState] = React.useState({
        value: "전체선택", checked: false
    })


    let temItems = [];

    if(menuItems !== null && menuItems !== 'undefined') {
        temItems = menuItems.map((item) => {
            return {...item, checked: false}
        });
    }
    const [menuItemState, setMenuItemState] = React.useState({
        items: temItems
    })

    const handleAllCheck = (event) => {
        const {onChange, } = props;

        let data = event.target.value
        let lastData = data[data.length-1];

        let modifedDates = []

        if ( lastData.value === allCheckState.value ) {
            // 전체선택 버튼 클릭
            var checked = allCheckState.checked;
            setAllCheckState({...allCheckState, checked: !checked});

            // 모든 item 를 체크한다.
            setMenuItemState(
                checked
                    ? { items: modifedDates=menuItemState.items.map(item => ({...item, checked: false})) }
                    : { items: modifedDates=menuItemState.items.map(item => ({...item, checked: true})) }
            )
        } else {
            // item 버튼 클릭
            modifedDates = handleClick(lastData.key);

            // 하나라도 체크가 풀리면 전체선택 체크를 해제한다.
            modifedDates.find((item)=> item.checked === false )
                ? setAllCheckState({...allCheckState, checked: false})
                : setAllCheckState({...allCheckState, checked: true});
        }

        if (onChange !== undefined) onChange( modifedDates.filter(item=> ( item.checked === true )) );
    }

    // 클릭한 item 를 찾아 변경하고 변경한 배열을 반환
    // modifedDates 를 사용한 이유는 setState 는 바로 적용되지 않기 때문
    const handleClick = (key) => {
        let modifedDates = menuItemState.items.map(
            (item) => {
                if (item.key === key) {
                    return {...item, checked:!item.checked}
                } else {
                    return item
                }
            }
        )

        setMenuItemState({
            items: modifedDates
        });

        return modifedDates;
    }

    return (
        <FormControl className={formClassName}>
            <InputLabel htmlFor={id}> {label} </InputLabel>
            <Select
                multiple
                value={value}
                onChange={handleAllCheck}
                input={<Input
                    name={name}
                    id={id}
                />}
                renderValue={
                    selected => {
                        let temp = selected.map((value)=> {
                            return value.value;
                        });
                        return temp.join(', ');
                    }
                }
                MenuProps={MenuProps}
                className={selectClassName}
                {...otherProperties}
            >

                <MenuItem value={{value: allCheckState.value, key: allCheckState.key}}>
                    <Checkbox checked={allCheckState.checked} />
                    <ListItemText primary={allCheckState.value} />
                </MenuItem>

                {menuItemState.items.map(item => (
                    <MenuItem key={item.key} value={{value: item.value, key: item.key}}>
                        <Checkbox checked={item.checked} />
                        <ListItemText primary={item.value} />
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}

const GridMultiSelect = (props) => {
    let { names, className, onChangeFunc, value } = props;
    let mValue;

    if (value === null || value === "" || value === undefined) {
        mValue = [];
    } else {
        mValue = value.split(", ");
    }

    const [selValue, setSelValue] = React.useState(mValue);

    const onChangeHandler = (event) => {
        setSelValue(event.target.value)
    }

    const onBlurHandler = (event) => {
        if (onChangeFunc) {
            onChangeFunc(selValue, props)
        }
    }
    return (
        <FormControl style={{width:"100%"}} >

            <Select
                autoWidth
                multiple
                value={selValue}
                onChange={onChangeHandler}
                input={<Input id="select-multiple-checkbox" />}
                renderValue={selected => selected.join(',')}
                MenuProps={MenuProps}
                onClose={onBlurHandler}
            >

                {names.map(name => (
                    <MenuItem key={name.KEY} value={name.COMN_CODE_NAME} >
                        <Checkbox checked={selValue.indexOf(name.COMN_CODE_NAME) > -1} />
                        <ListItemText primary={name.COMN_CODE_NAME} />
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}

class GridSelectRenderer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value: props.value,
        };
    }
    render() {
        const onChangeHandler = (event) => {
            console.log(this.props);
            console.log(event.target.value);
            this.props.setValue(event.target.value);
            // this.setState({value : event.target.value });
            
        }

        return (
            <div style={{width: '100%', height: '100%' }} className="select-field">
                <BootInput 
                    type="select" 
                    id={`${this.props.column.colId}_${this.props.rowIndex}`} 
                    name="select" 
                    className="dateFieldType"  
                    style={{ fontSize: '0.7rem', padding: '0rem 0.8rem 0rem 0.2rem' }}
                    value={this.state.value == null ? "" : this.state.value} 
                    onChange={onChangeHandler}
                    innerRef={this.myRef}         
                    onClick={(event) =>{
                    }}
                    onBlur={(event) => {
                    }}
                    // style={{
                    //     padding: 0,
                    //     ...this.state.style
                    // }}
                >
                    <option disabled selected></option>
                    {/* <option value={this.props.names[0].value}> {this.props.names[0].label} </option>
                    <option value={this.props.names[1].value}> {this.props.names[1].label} </option> */}
                    {/* <option value={this.props.names[0].value}> {this.props.names[0].label} </option>
                    <option value={this.props.names[1].value}> {this.props.names[1].label} </option> */}
                    {this.props.names.map(name => {
                        return (
                            <option value={name.value} key={name.key}>
                                {name.label}
                            </option>
                        );
                    })}
                </BootInput>
            </div>
        );
    }
}

class GridSelect extends Component {
    constructor(props) {
        super(props);
        
        this.focusAfterAttached = props.cellStartedEdit;
        this.myRef = React.createRef();
        
        let style = {}

        if (this.props.inputProps && this.props.inputProps.style) 
            style = this.props.inputProps.style;
        
        this.state = {
            value : this.props.value,
            size: 1,
            focus: false,
            open: false,
            style: style,
        }
    }

    // componentDidMount = () => {
    //     console.log('componentDidMount');
    //     console.log($(`#${this.props.column.colId}_${this.props.rowIndex}`));
    //     console.log($(`#${this.props.column.colId}_${this.props.rowIndex}`).closest('.ag-react-container'));
    //     $(`#${this.props.column.colId}_${this.props.rowIndex}`).closest('.ag-react-container').addClass('ag-popup-editor');
    // }

    afterGuiAttached() {
        if (this.focusAfterAttached) {
            // this.myRef.current.style.borderColor = "#dc3545";
            this.myRef.current.focus();
            this.setState({
                focus: true,
                open: true,
            })
            // document.getElementById(`${this.props.colDef.field}_id${this.props.rowIndex}`).focus();
            // document.getElementById(`${this.props.colDef.field}_id${this.props.rowIndex}`).select();
        } else {
            // this.myRef.current.style.borderColor = "#fff";
        }
    };

    isCancelBeforeStart () {
        // $("#"+`${this.props.colId}_${this.props.rowIndex}`).closest('.ag-react-container').addClass('ag-popup-editor');
        return false;
    }
    isCancelAfterEnd() {
        return false;
    }

    destroy() {
        // this.myRef.current.style.borderColor = "#fff";
    }

    focusIn() {
        this.myRef.current.focus();
        // this.myRef.current.style.borderColor = "#dc3545";
        // this.myRef.current.onMouseDown();
        // $("#"+`${this.props.colId}_${this.props.rowIndex}`).closest('.ag-cell').css('overflow', 'visible');
        // $("#"+`${this.props.colId}_${this.props.rowIndex}`).closest('.ag-center-cols-viewport').css('overflow-y', 'visible');
        // $("#"+`${this.props.colId}_${this.props.rowIndex}`).css("line-height", "normal");    
        // this.myRef.current.setAttribute('size', 7);
        // this.myRef.current.style.zIndex = 9999;
        
        this.setState({
            focus: true,
            open: true,
        })
        return true;
    }

    focusOut() {
        this.setState({
            focus: false,
            open: false,
        })
        return true;
    }

    getValue() {
        return this.state.value;
    }

    isPopup() {
        return false;
        // return true;
    }

    render() {
        // $(`#${this.props.column.colId}_${this.props.rowIndex}`).closest('.ag-react-container').addClass('ag-popup-editor');

        const onChangeHandler = (event) => {
            // if (this.props.callbackFunc2) {
            //     this.props.callbackFunc2(this.props, event);
            // }

            this.setState({value : event.target.value });

            if (this.props.callbackFunc) {
                this.props.setValue(event.target.value);
                this.props.callbackFunc();
            }

            if (this.props.onlyCallbackFunc) {
                this.props.onlyCallbackFunc(event.target.value, this.props.rowIndex);
            }

            if (this.props.svcCallbackFunc) {
                this.props.svcCallbackFunc(this.props, event);
            }
        }

        const onKeyPressHandler = (event) => {
            if (event.which === 13) {
                event.preventDefault();
            }
        }

        return (
            <>
                {this.props.calledType === "editGrid" ? (
                    <div style={{width: '100%', height: '100%', marginTop: '6px'}} className="select-field">
                        <BootInput 
                            id={`${this.props.column.colId}_id${this.props.rowIndex}`} 
                            type="select" name="select" className="dateFieldType"  
                            value={this.state.value == null ? "" : this.state.value} 
                            onChange={onChangeHandler}
                            innerRef={this.myRef}         
                            onClick={(event) =>{
                                // console.log(event)
                                // console.log(this.state.value)
                            } }
                            onBlur={(event) => {
                                // console.log('onBlur');
                                // this.myRef.current.style.borderColor = "#fff";
                            }}
                            style={{
                                padding: 0,
                                ...this.state.style
                            }}
                            onKeyPress={onKeyPressHandler}
                            disabled={this.props.disableYn === 'Y' ? (this.props.data.ROWTYPE == "2" ? false : true) : false}
                        >
                            <option value=''></option>
                            {this.props.names.map(name => {
                                
                                return (
                                    <option value={name.value} key={name.key} >
                                        {name.label}
                                    </option>
                                );
                            })}
                        </BootInput>
                    </div>
                ) : (
                    <div style={{width: '100%', height: '100%' }} className="select-field">
                        <BootInput 
                            id={`${this.props.column.colId}_id${this.props.rowIndex}`} 
                            type="select" name="select" className="dateFieldType"  
                            value={this.state.value == null ? "" : this.state.value} 
                            onChange={onChangeHandler}
                            innerRef={this.myRef}         
                            onClick={(event) =>{
                                // console.log(event)
                                // console.log(this.state.value)
                            } }
                            onBlur={(event) => {
                                // console.log('onBlur');
                                // this.myRef.current.style.borderColor = "#fff";
                            }}
                            style={{
                                padding: 0,
                                ...this.state.style
                            }}
                            onKeyPress={onKeyPressHandler}
                        >
                            <option disabled selected></option>
                            {this.props.names.map(name => {
                                
                                return (
                                    <option value={name.value} key={name.key} >
                                        {name.label}
                                    </option>
                                );
                            })}
                        </BootInput>
                    </div>
                )}
            </>

            //    <FormControl style={{width:"100%"}} >
            //     <Select
            //         id={this.props.colDef.field+"_id"+this.props.rowIndex}
            //         value={this.state.value == null ? "" : this.state.value}
            //         onChange={onChangeHandler}
            //         open={this.state.open}
            //         autoFocus={this.state.focus}
            //         onOpen={(event) => {
                        
            //             const KEY_ENTER = 13;
            //             if (event.keyCode === KEY_ENTER) 
            //                 return false;
            //             console.log('onOpen');
            //             this.setState({
            //                 open: true,
            //             });
            //         }}
            //         onClose={() => {
            //             console.log('onClose');
            //             this.setState({
            //                 open: false,
            //             });
            //         }}
            //     >
            //         {this.props.names.map(name => (
            //             <MenuItem key={name.key} value={name.value} >
            //                 {name.label}
            //             </MenuItem>
            //         ))}
            //     </Select> 
            // </FormControl>
        )
    }
}

class GridSelect2 extends Component {
    constructor(props) {
        super(props);

        this.focusAfterAttached = props.cellStartedEdit;

        this.state = {
            value : this.props.value,
            focus: false,
            open: false,
        }
    }

    afterGuiAttached() {
        if (this.focusAfterAttached) {
            console.log(' select afterGuiAttached')
            this.setState({
                focus: true,
                open: true,
            })
            // document.getElementById(`${this.props.colDef.field}_id${this.props.rowIndex}`).focus();
            // document.getElementById(`${this.props.colDef.field}_id${this.props.rowIndex}`).select();
        }
    };


    focusIn() {
        console.log(' select focusIn')
        this.setState({
            focus: true,
            open: true,
        })
        return true;
    }

    focusOut() {
        console.log(' select focusOut')
        this.setState({
            focus: false,
            open: false,
        })
        return true;
    }
    getValue() {
        return this.state.value;
    }

    isPopup() {
        return false;
    }

    render() {
        const parse = require('html-react-parser');

        const onChangeHandler = (event) => {
            this.setState({value : event.target.value });

            if (this.props.callbackFunc) {
                this.props.setValue(event.target.value);
                this.props.callbackFunc();
            }
        }

        return (
            <Fragment>
                {/* <div style={{margin:'0 auto', padding:'6px 11px', lineHeight:'normal', alignItems:'center', display:'flex'}}>
                    <BootInput type="select" id={this.props.colDef.field+"_id"+this.props.rowIndex} value={this.state.value} onChange={onChangeHandler} style={{width:"100%", height:"34px"}}>
                        {
                            this.props.names.map(item => (
                                <option value={item.value} key={item.key}>{item.label}</option>
                            ))
                        }
                    </BootInput>
                </div> */}

                <FormControl style={{width:"100%"}} >
                    <Select
                        id={this.props.colDef.field+"_id"+this.props.rowIndex}
                        value={this.state.value}
                        onChange={onChangeHandler}
                        open={this.state.open}
                        autoFocus={this.state.focus}
                        onOpen={(event) => {
                            
                            const KEY_ENTER = 13;
                            if (event.keyCode === KEY_ENTER) 
                                return false;
                            console.log('onOpen');
                            this.setState({
                                open: true,
                            });
                        }}
                        onClose={() => {
                            console.log('onClose');
                            this.setState({
                                open: false,
                            });
                        }}
                    >
                        {this.props.names.map(item => (
                            <MenuItem key={item.key} value={item.value} >
                                {parse(item.label)}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Fragment>
        )
    }
}

class GridSelect3 extends Component {
    constructor(props) {
        super(props);
        
        this.focusAfterAttached = props.cellStartedEdit;
        this.myRef = React.createRef();
        
        let style = {}

        if (this.props.inputProps && this.props.inputProps.style) 
            style = this.props.inputProps.style;
        
        this.state = {
            value : this.props.value,
            size: 1,
            focus: false,
            open: false,
            style: style,
        }
    }

    afterGuiAttached() {
        if (this.focusAfterAttached) {
            this.myRef.current.focus();
            this.setState({
                focus: true,
                open: true,
            })
        }
    };

    isCancelBeforeStart () {
        return false;
    }
    isCancelAfterEnd() {
        return false;
    }

    destroy() {
    }

    focusIn() {
        this.myRef.current.focus();
        
        this.setState({
            focus: true,
            open: true,
        })
        return true;
    }

    focusOut() {
        this.setState({
            focus: false,
            open: false,
        })
        return true;
    }

    getValue() {
        return this.state.value;
    }

    isPopup() {
        return false;
    }

    render() {
        const onChangeHandler = (event) => {
            this.setState({value : event.target.value });

            if (this.props.callbackFunc) {
                this.props.setValue(event.target.value);
                this.props.callbackFunc();
            }

            if (this.props.onlyCallbackFunc) {
                this.props.onlyCallbackFunc(event.target.value, this.props.rowIndex);
            }

            if (this.props.svcCallbackFunc) {
                this.props.svcCallbackFunc(this.props, event);
            }
        }

        const onKeyPressHandler = (event) => {
            if (event.which === 13) {
                event.preventDefault();
            }
        }

        return (
            <>
                <div style={{ width: '100%', height: '100%' }} className="select-field">
                    <BootInput
                        id={`${this.props.column.colId}_id${this.props.rowIndex}`}
                        type="select" name="select" className="dateFieldType"
                        value={this.state.value == null ? "" : this.state.value}
                        onChange={onChangeHandler}
                        innerRef={this.myRef}
                        onClick={(event) => {
                            console.log(event)
                            console.log(this.state.value)
                        }}
                        style={{
                            padding: 0,
                            ...this.state.style
                        }}
                        onKeyPress={onKeyPressHandler}
                    >
                        <option disabled></option>
                        {this.props.names.map(name => {

                            return (
                                <option value={name.value} key={name.key} >
                                    {name.label}
                                </option>
                            );
                        })}
                    </BootInput>
                </div>
            </>
        )
    }
}

const BootstrapNativeLabelSelect = (props) => {
    let { label, labelClass, options, selectClass, onChangeFunc, value, required, ...etc } = props;

    if (!options) {
        options = [];
    }

    return (
        <Fragment>
            <Label className={labelClass}>
                {label}
                {
                    required === true &&
                    <div className="badge badge-dot badge-dot-sm badge-danger ml-1">필수항목</div>
                }
            </Label>
            <BootInput type="select" className={selectClass} value={value} onChange={onChangeFunc} {...etc}>
                {
                    options.map(item => (
                        <option value={item.value} key={item.key}>{item.label}</option>
                    ))
                }
            </BootInput>
        </Fragment>
    );
}

const BootstrapNativeSelect = (props) => {
    let {options, selectClass, onChangeFunc, value, ...etc } = props;

    if (!options) {
        options = [];
    }

    return (
        <Fragment>
            <BootInput type="select" className={selectClass} value={value} onChange={onChangeFunc} {...etc}>
                {
                    options.map(item => (
                        <option value={item.value} key={item.key}>{item.label}</option>
                    ))
                }
            </BootInput>
        </Fragment>
    );
}

let tempHtmlTag = "";

class EmailCellRender extends Component {
    constructor(props) {
        super(props);
        console.log("EmailCellRender = constructor");

        this.focusAfterAttached = props.cellStartedEdit;

        this.emailRef = React.createRef()

        let style = {}
        if (this.props.inputProps && this.props.inputProps.style) 
            style = this.props.inputProps.style;

        this.state = {
            value: this.props.value,
            text:this.props.data.EMAIL,
            text_yn:'',
            cellWidth: {
                select: '100%',
                input: '0%'
            },
            focus: false,
            open: false,
            style: style,
        };
    }


    afterGuiAttached() {
        if (this.focusAfterAttached) {
            console.log(' select afterGuiAttached')
            this.setState({
                focus: true,
                open: true,
            })
            // document.getElementById(`${this.props.colDef.field}_id${this.props.rowIndex}`).focus();
            // document.getElementById(`${this.props.colDef.field}_id${this.props.rowIndex}`).select();
        }
    };

    focusIn() {
        console.log(' select focusIn')
        this.setState({
            focus: true,
            open: true,
        })
        return true;
    }

    focusOut() {
        console.log(' select focusOut')
        this.setState({
            focus: false,
            open: false,
        })

        this.props.node.setDataValue(this.props.column.colId, this.state.value);

        // this.props.api.updateRowData({
        //     update: this.state.value
        // })
        return true;
    }

    getValue = () => {
        return {
            value: this.state.value,
            text: this.state.text,
            text_yn: this.state.text_yn,
            type: 'customSelect'
        };
    }
    isPopup = () => {return false;}

    componentWillMount(){
        console.log(' select componentWillMount')
        let textCheck
        if (typeof(this.props.data.EMAIL_SENDER_SET_CN) === "object") {
            textCheck = this.props.names.filter(item => item.value === this.props.data.EMAIL_SENDER_SET_CN.value);
        } else {
            textCheck = this.props.names.filter(item => item.value === this.props.data.EMAIL_SENDER_SET_CN);
        }
        console.log(this.props)
        console.log(textCheck)

        if(textCheck.length > 0){
            if(textCheck[0].text_yn === 'Y'){
                this.setState({cellWidth : {
                    select: '40%',
                    input: '60%'
                }});

                setTimeout(() => {
                    $(this.props.eGridCell.offsetParent).children().eq(3).children().css('display', '');
                }, 100);
            }else{
                setTimeout(() => {
                    $(this.props.eGridCell.offsetParent).children().eq(3).children().css('display', 'none');
                }, 100);
            }
        }
    }

    // componentDidMount() {
    //     if (typeof(this.props.data.EMAIL_SENDER_SET_CN) === "object" && this.props.data.EMAIL_SENDER_SET_CN.text_yn === 'Y') {
    //         this.emailRef.current.focus();
    //     } 
    // }

    render() {
        const parse = require('html-react-parser');

        const onChangeHandler = (event) => {
            // let selDataset = event._targetInst.stateNode.dataset;

            let selDataset = event._targetInst.stateNode.selectedIndex;

            if (selDataset=== 1) {
                $(this.props.eGridCell.offsetParent).children().eq(3).children().css('display', '');

                this.setState({
                    cellWidth : {
                        select: '40%',
                        input: '60%'
                    },
                    text_yn : 'Y'
                })
            } else {
                this.setState({
                    cellWidth : {
                        select: '100%',
                        input: '0%'
                    },
                    text_yn : 'N'
                })

                $(this.props.eGridCell.offsetParent).children().eq(3).children().css('display', 'none');
            }

            this.setState({value: parseInt(event.target.value)});
        }

        const onTextChangeHandler = (event) => {
            this.setState({
                text : event.target.value,
                text_yn: 'Y',
            });
        }

        return (
            <div className="emailGridSelect" style={{
                position: 'none',
            }}>
                {/* <div style={{ 
                    marginLeft: 11, marginRight: 11,
                    }}> */}
                    <BootInput 
                        id={`${this.props.column.colId}_${this.props.rowIndex}`} 
                        type="select" name="select" 
                        className="selectBox"  
                        value={this.state.value == null ? "" : this.state.value} 
                        onChange={onChangeHandler}
                        innerRef={this.myRef}
                        size={this.size}                    
                        onClick={(event) =>{
                            console.log(event)
                            console.log(this.state.value)
                        } }
                        onBlur={(event) => {
                            console.log('onBlur');
                            // this.myRef.current.style.borderColor = "#fff";
                        }}
                        style={{
                            padding:"0.275rem 0.12rem",
                            width: this.state.cellWidth.select,
                            ...this.state.style,
                            // marginRight: '11px',
                            // marginLeft: '11px',
                            // height: '40px 
                            
                        }}
                        >
                        <option disabled selected></option>
                        {this.props.names.map(name => {
                            
                            return (
                                <option value={name.value} key={name.key} >
                                    {name.label}
                                </option>
                            );
                        })}
                    </BootInput>        
                {/* </div>             */}
                {/* <Select
                    value={this.state.value}
                    onChange={onChangeHandler}
                    className="selectBox"
                    open={this.state.open}
                    autoFocus={this.state.focus}
                    style={{
                        width: this.state.cellWidth.select
                    }}
                    onOpen={() => {
                        console.log('onOpen');
                        this.setState({
                            open: true,
                        });
                    }}
                    onClose={() => {
                        console.log('onClose');
                        this.setState({
                            open: false,
                        });
                    }}
                >
                    {this.props.names.map(name => (
                        <MenuItem key={name.key} value={name.value} data-textbox={name.text_yn}>
                            {parse(nullToBlank(name.label) !== "" ? name.label : "")}
                        </MenuItem>
                    ))}
                </Select> */}
                <div
                    className="ml-2 inputDiv"
                    style={{
                        width: this.state.cellWidth.input,
                        // height: '1%',
                    }}
                    
                >
                    {/* //.ag-cell-inline-editing .ag-input-wrapper */}
                    <input 
                        ref={this.emailRef}
                        // style={{

                        //     padding:"0.275rem 0.12rem",
                        // }}
                        type={"text"} placeholder={"email"} 
                        style={{
                            height: "32px",
                            width: "calc(100% - 25px)",
                            lineHeight: "normal",
                            // margin: "0 auto",
                            display: "flex",
                            alignItems: "center",
                            padding: "2px 0",
                            borderWidth: "1px",
                            borderStyle: "solid",
                            borderColor: "#ced4da",
                            borderRadius: "0.25rem",
                        }}
                        value={this.state.text} onChange={onTextChangeHandler}/>
                </div>
            </div>
        )
    }
}



class SendTypeRenderer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value: this.props.value,
            selValue: [],
            text:this.props.data.EMAIL,
            text_yn:'',
            cellWidth: {
                select: '100%',
                input: '0%'
            }
        };
    }

    componentWillMount(){
        let tempSelObject = [];

        if(nullToBlank(this.props.value) !== "") {
            if (nullToBlank(this.props.value.replace(/#/g, '')) !== "") {
                let valueListArr = this.props.value.split("#");
                let valuesArr = valueListArr[0].split(",");
                let namesArr = valueListArr[1].split(",");
                let traceArr = valueListArr[2].split(",");
                let activeArr = valueListArr[3].split(",");

                valuesArr.map((item, index) => {
                    tempSelObject.push({
                        value : parseInt(item),
                        label : namesArr[index],
                        trace : traceArr[index],
                        active: activeArr[index],
                    })
                })
            }
        }

        this.setState({
            selValue : [...tempSelObject]
        })
    }

    getValue = () => {
        let sendValue = "";
        let returnValues = "";
        let returnNames = "";
        let returnTrace = "";
        let returnActive = "";

        this.state.selValue.map((item, index) => {
            if(index === 0){
                returnValues = item.value;
                returnNames = item.label;
                returnTrace = nullToBlank(item.trace);
                returnActive = nullToBlank(item.active);
            }else{
                returnValues += ","+ item.value;
                returnNames += ","+ item.label;
                returnTrace += ","+ nullToBlank(item.trace);
                returnActive += ","+ nullToBlank(item.active);
            }
        });

        sendValue = returnValues +"#"+ returnNames +"#"+ returnTrace +"#"+ returnActive;

        return sendValue;

        /*
        return {
            value: sendValue,
            oriValue: this.props.value
        };
        */
    }

    emailAlertChange = (value) => {
        this.setState({
            selValue : [...value]
        })
    }

    render() {
        const AutoClose = id => {
            $("#"+id).closest('.ag-cell').css('overflow', 'hidden');
            $("#"+id).closest('.ag-center-cols-viewport').css('overflow-y', 'hidden');
        }
    
        const AutoOpen = (id) => {
    
            $("#"+id).closest('.ag-cell').css('overflow', 'visible');
            $("#"+id).closest('.ag-center-cols-viewport').css('overflow-y', 'visible');
            $("#"+id).css("line-height", "normal");
        }

        return (
            <BootstrapAutoComplete
                id="cnNoAutoComplete"
                options={this.props.names}
                selectList={this.state.selValue}
                onChangeFunc={this.emailAlertChange}
                multiple={true}
                includeSelectAll={false}
                includeFilter={true}
                placeholder=""
                keepOpen={false}
                // className={"d-md-inline-block align-bottom picky-edit-cell"}
                dropdownHeight={200}
                // selectAllText={commonLang().SELECT_MESSAGE_ALL_SELECTED}
                onOpen={AutoOpen.bind(this, 'cnNoAutoComplete')}
                onClose={AutoClose.bind(this, 'cnNoAutoComplete')}
            />
        )
    }
}
export {
    SimpleSelect,
    SimpleNativeSelect,
    CustomizedSelect ,
    CustomizedNativeSelect ,
    MultiSelect,
    GridMultiSelect,
    GridSelectRenderer,
    GridSelect,
    BootstrapNativeLabelSelect,
    BootstrapNativeSelect,
    GridSelect2,
    GridSelect3,
    EmailCellRender,
    SendTypeRenderer
};


