export default {
    " grid.header.gold" : `Gold Medal`,
    "DownloadReasonPopup.alert.noGridData" : `There is no data in grid`,
    "FAQ" : `Q&A`,
    "TB_DE_DB_MESSAGE_CODE_LIST.MSG_COMT_MKEY.2079500" : `This is an email form that has already been registered. Please register after changing or deleting.`,
    "TB_IF_CM_RS.ADDR" : `Address`,
    "TB_IF_CM_RS.ADULT_CNT" : `Adult`,
    "TB_IF_CM_RS.ARRV_DATE" : `Arrival Date`,
    "TB_IF_CM_RS.ARRV_EXPECTED_TIME" : `Expedted Arrival Time`,
    "TB_IF_CM_RS.CALC_PAY_CN" : `Payment Code No.`,
    "TB_IF_CM_RS.CHILD_CNT" : `Child`,
    "TB_IF_CM_RS.CM_RA_TY_NO" : `CMS Rate Type No.`,
    "TB_IF_CM_RS.CM_RM_TY_NO" : `CMS Room Type No.`,
    "TB_IF_CM_RS.CM_RS_NO" : `CMS Reservation No.`,
    "TB_IF_CM_RS.CNCL_CL_CHARGE" : `Cancellation Penalty`,
    "TB_IF_CM_RS.CNCL_DATE" : `Cancellation date`,
    "TB_IF_CM_RS.CNCL_POLICY" : `Cancellation policy`,
    "TB_IF_CM_RS.CN_CALC_INFO" : `Channel Information retrieval`,
    "TB_IF_CM_RS.CN_NO" : `Channel No.`,
    "TB_IF_CM_RS.CN_PM_NO" : `Channel Hotel No.`,
    "TB_IF_CM_RS.CN_RA_TY_NO" : `Channel Rate Type No.`,
    "TB_IF_CM_RS.CN_RM_TY_NO" : `Channel Room Type No.`,
    "TB_IF_CM_RS.CN_RS_DT" : `Channel Reservation Date`,
    "TB_IF_CM_RS.CN_RS_NO" : `Channel Reservation No.`,
    "TB_IF_CM_RS.COMT" : `Comment`,
    "TB_IF_CM_RS.CORP_CUSTM_NO" : `Corp Guest No.`,
    "TB_IF_CM_RS.CRDT_CARD_CVC_NO" : `Credit Card CVC No.`,
    "TB_IF_CM_RS.CRDT_CARD_NO" : `Credit Card No.`,
    "TB_IF_CM_RS.CRDT_CARD_OWNER_NAME" : `Credit card owner name`,
    "TB_IF_CM_RS.CRDT_CARD_TYPE_CN" : `Credit Card Type Code`,
    "TB_IF_CM_RS.CRDT_CARD_VAL_PERIOD" : `Credit card validity period`,
    "TB_IF_CM_RS.CREATED" : `Reservation data has been created`,
    "TB_IF_CM_RS.CURNCY_CN" : `Currency Code`,
    "TB_IF_CM_RS.CYBER_ID" : `Cyber ID`,
    "TB_IF_CM_RS.DELETED" : `Reservation data has been deleted`,
    "TB_IF_CM_RS.DEPT_DATE" : `Departure Date`,
    "TB_IF_CM_RS.DEPT_EXPECTED_TIME" : `Expedted Departurel Time`,
    "TB_IF_CM_RS.DPSIT_PAY_INFO" : `Deposit Info.`,
    "TB_IF_CM_RS.FIRST_REG_DT" : `Initial Registration Date`,
    "TB_IF_CM_RS.FIRST_REG_USER_IP" : `Initial Registration User IP`,
    "TB_IF_CM_RS.FIRST_REG_USER_NO" : `Initial Registration User No.`,
    "TB_IF_CM_RS.INFANT_CNT" : `Number of Infants`,
    "TB_IF_CM_RS.INHS_GEST_EMAIL" : `Guest E-Mail`,
    "TB_IF_CM_RS.INHS_GEST_FIRST_NAME" : `Guest First Name`,
    "TB_IF_CM_RS.INHS_GEST_LAST_NAME" : `Guest Last Name`,
    "TB_IF_CM_RS.INHS_GEST_MOBILE_NO" : `Guest Mobile No.`,
    "TB_IF_CM_RS.INHS_GEST_SEX_CN" : `Guest Sex Code No.`,
    "TB_IF_CM_RS.INHS_GEST_TEL_NO" : `Guest Phone No.`,
    "TB_IF_CM_RS.INHS_GEST_TITLE" : `Guest TITLE`,
    "TB_IF_CM_RS.INPUT_CN_RA_TY_CODE" : `Input Channel Rate Type Code`,
    "TB_IF_CM_RS.INPUT_CN_RA_TY_NAME" : `Input Channel Rate Type Name`,
    "TB_IF_CM_RS.INPUT_CN_RM_TY_CODE" : `Input Channel Room Type Code`,
    "TB_IF_CM_RS.INPUT_CN_RM_TY_NAME" : `Input Channel Room Type Name`,
    "TB_IF_CM_RS.INPUT_CN_TOTRM_RA" : `Input Channel Total Room Rate`,
    "TB_IF_CM_RS.LANG_CN" : `Language Code No.`,
    "TB_IF_CM_RS.LAST_CHNG_DT" : `Last Updating Date`,
    "TB_IF_CM_RS.LAST_CHNG_USER_IP" : `Last Updating User IP`,
    "TB_IF_CM_RS.LAST_CHNG_USER_NO" : `Last Update user number`,
    "TB_IF_CM_RS.MAIL_NO" : `Post Code`,
    "TB_IF_CM_RS.MARKET_TY" : `Market Type`,
    "TB_IF_CM_RS.MASTER_CN_RSNO" : `Master Channel Reservation Number`,
    "TB_IF_CM_RS.NAT_CN" : `Country Code`,
    "TB_IF_CM_RS.PAY_METHOD" : `Payment Method`,
    "TB_IF_CM_RS.PMS_RS_NO" : `PMS Reservation Number`,
    "TB_IF_CM_RS.PMS_RS_SEQ_NO" : `PMS Reservation Sequence Number`,
    "TB_IF_CM_RS.PM_ARRV_DATE" : `Hotel Arrival Date`,
    "TB_IF_CM_RS.PM_CM_RS_SEND_ERR_MSG" : `Hotel CMS Reservation sending error message`,
    "TB_IF_CM_RS.PM_CM_RS_SEND_STATUS_CN" : `Hotel CMS Reservation sending status code`,
    "TB_IF_CM_RS.PM_DEPT_DATE" : ` Hotel Departure Date`,
    "TB_IF_CM_RS.PM_NO" : `Hotel Phone Number`,
    "TB_IF_CM_RS.PM_PKG_CODE" : `Hotel Package Code`,
    "TB_IF_CM_RS.PM_RA_TY_CODE" : `Hotel Rate Type Code`,
    "TB_IF_CM_RS.PM_RMS" : `Hotel Room Count`,
    "TB_IF_CM_RS.PM_RM_TY_CODE" : `Hotel Room Type Code`,
    "TB_IF_CM_RS.PM_RM_TY_NO" : `Hotel Room Type No.`,
    "TB_IF_CM_RS.PM_RS_CNCL_DATE" : ` Hotel Reservation Cancellation Date`,
    "TB_IF_CM_RS.PM_RS_STATUS_CN" : `Hotel Reservation Status Code No.`,
    "TB_IF_CM_RS.PM_TOT_RM_RA" : `Hotel Total Room Rate`,
    "TB_IF_CM_RS.PRE_HIS_CRE_NO" : ``,
    "TB_IF_CM_RS.PRMT_EMP_ID" : `Sales Person`,
    "TB_IF_CM_RS.REGION_CN" : `Area Code`,
    "TB_IF_CM_RS.RM_CNT" : `Room Count`,
    "TB_IF_CM_RS.ROW_UNIQUE_NO" : `ROW UNIQUE Number`,
    "TB_IF_CM_RS.RSVN_GEST_EMAIL" : `Booker E-Mail`,
    "TB_IF_CM_RS.RSVN_GEST_FIRST_NAME" : `Booker First Name`,
    "TB_IF_CM_RS.RSVN_GEST_LAST_NAME" : `Booker Last Name`,
    "TB_IF_CM_RS.RSVN_GEST_MOBILE_NO" : `Booker Mobile number`,
    "TB_IF_CM_RS.RSVN_GEST_SEX_CN" : `Booker Sex Code No.`,
    "TB_IF_CM_RS.RSVN_GEST_TEL_NO" : `Booker Phone Number`,
    "TB_IF_CM_RS.RSVN_GEST_TITLE" : `Booker TITLE`,
    "TB_IF_CM_RS.RS_DATE" : `Booking Date`,
    "TB_IF_CM_RS.RS_STATUS_CN" : `Reservation Status Code No.`,
    "TB_IF_CM_RS.SORC_TY" : `Source Type`,
    "TB_IF_CM_RS.SOURCE_CODE" : `Channel Source`,
    "TB_IF_CM_RS.TOT_RM_RA" : `Total Room Rate`,
    "TB_IF_CM_RS.TOT_SVC_AMT" : `Total Service Charge `,
    "TB_IF_CM_RS.TRACE_NO" : `Trace Number`,
    "TB_IF_CM_RS.USER_CNFM_DT" : `User Confirmed date`,
    "TB_IF_CM_RS_INTER_MEMO.CM_RS_NO" : `CMS Reservation Number`,
    "TB_IF_CM_RS_INTER_MEMO.FIRST_REG_DT" : `Original Registration Date`,
    "TB_IF_CM_RS_INTER_MEMO.FIRST_REG_USER_IP" : `Original Registration user IP`,
    "TB_IF_CM_RS_INTER_MEMO.FIRST_REG_USER_NO" : `Original Registration user number`,
    "TB_IF_CM_RS_INTER_MEMO.INTER_MEMO_COMT" : `Inter Memo Content`,
    "TB_IF_CM_RS_INTER_MEMO.INTER_MEMO_SEQ_NO" : `Inter Memo Order No`,
    "TB_IF_CM_RS_INTER_MEMO.LAST_CHNG_DT" : `Last Update date`,
    "TB_IF_CM_RS_INTER_MEMO.LAST_CHNG_USER_IP" : `Last Update user IP`,
    "TB_IF_CM_RS_INTER_MEMO.LAST_CHNG_USER_NO" : `Last Update user number`,
    "TB_IF_CM_RS_INTER_MEMO.PRE_HIS_CRE_NO" : ``,
    "TB_IF_CM_RS_INTER_MEMO.ROW_UNIQUE_NO" : `ROW UNIQUE Number`,
    "TB_IF_CM_RS_INTER_MEMO.TRACE_NO" : `Trace Number`,
    "TB_IF_CM_RS_RM_MATE.CM_RS_NO" : `CMS Reservation Number`,
    "TB_IF_CM_RS_RM_MATE.FIRST_REG_DT" : `Original Registration Date`,
    "TB_IF_CM_RS_RM_MATE.FIRST_REG_USER_IP" : `Original Registration user IP`,
    "TB_IF_CM_RS_RM_MATE.FIRST_REG_USER_NO" : `Original Registration user number`,
    "TB_IF_CM_RS_RM_MATE.LAST_CHNG_DT" : `Last Update date`,
    "TB_IF_CM_RS_RM_MATE.LAST_CHNG_USER_IP" : `Last Update user IP`,
    "TB_IF_CM_RS_RM_MATE.LAST_CHNG_USER_NO" : `Last Update user number`,
    "TB_IF_CM_RS_RM_MATE.PRE_HIS_CRE_NO" : ``,
    "TB_IF_CM_RS_RM_MATE.ROOMMATE_FIRST_NAME" : ` Accompany First Name`,
    "TB_IF_CM_RS_RM_MATE.ROOMMATE_LAST_NAME" : `Accompany Last Name`,
    "TB_IF_CM_RS_RM_MATE.ROOMMATE_SEQ_NO" : `Roommate Sequence Number`,
    "TB_IF_CM_RS_RM_MATE.ROOMMATE_SEX_CN" : `Roommate Gender Code`,
    "TB_IF_CM_RS_RM_MATE.ROOMMATE_TITLE" : `Roommate Title`,
    "TB_IF_CM_RS_RM_MATE.ROW_UNIQUE_NO" : `ROW UNIQUE Number`,
    "TB_IF_CM_RS_RM_MATE.TRACE_NO" : `Trace Number`,
    "TB_IF_CM_RS_RM_RATE.CMSN_AMT" : `Commission`,
    "TB_IF_CM_RS_RM_RATE.CM_RM_RA" : `CMS Room Rate`,
    "TB_IF_CM_RS_RM_RATE.CM_RS_NO" : `CMS Reservation Number`,
    "TB_IF_CM_RS_RM_RATE.FIRST_REG_DT" : `Original Registration Date`,
    "TB_IF_CM_RS_RM_RATE.FIRST_REG_USER_IP" : `Original Registration user IP`,
    "TB_IF_CM_RS_RM_RATE.FIRST_REG_USER_NO" : `Original Registration user number`,
    "TB_IF_CM_RS_RM_RATE.LAST_CHNG_DT" : `Last Update date`,
    "TB_IF_CM_RS_RM_RATE.LAST_CHNG_USER_IP" : `Last Update user IP`,
    "TB_IF_CM_RS_RM_RATE.LAST_CHNG_USER_NO" : `Last Update user number`,
    "TB_IF_CM_RS_RM_RATE.PRE_HIS_CRE_NO" : ``,
    "TB_IF_CM_RS_RM_RATE.RM_RA" : `Room Rate`,
    "TB_IF_CM_RS_RM_RATE.ROW_UNIQUE_NO" : `ROW UNIQUE Number`,
    "TB_IF_CM_RS_RM_RATE.RS_STATUS_CN" : `Reservation Status Code Number`,
    "TB_IF_CM_RS_RM_RATE.SALE_DATE" : `Business Date`,
    "TB_IF_CM_RS_RM_RATE.SVC" : `Service Charge`,
    "TB_IF_CM_RS_RM_RATE.TRACE_NO" : `Trace Number`,
    "TB_IF_CM_RS_RM_RATE.VAT" : `VAT`,
    "TB_IF_CM_RS_SVC.AMT" : `Amount`,
    "TB_IF_CM_RS_SVC.CM_RS_NO" : `CMS Reservation Number`,
    "TB_IF_CM_RS_SVC.COMT" : `Comment`,
    "TB_IF_CM_RS_SVC.FIRST_REG_DT" : `Original Registration Date`,
    "TB_IF_CM_RS_SVC.FIRST_REG_USER_IP" : `Original Registration user IP`,
    "TB_IF_CM_RS_SVC.FIRST_REG_USER_NO" : `Original Registration user number`,
    "TB_IF_CM_RS_SVC.LAST_CHNG_DT" : `Last Update date`,
    "TB_IF_CM_RS_SVC.LAST_CHNG_USER_IP" : `Last Update user IP`,
    "TB_IF_CM_RS_SVC.LAST_CHNG_USER_NO" : `Last Update user number`,
    "TB_IF_CM_RS_SVC.PM_SVC_CODE" : `Hotel Service Code`,
    "TB_IF_CM_RS_SVC.PM_SVC_SEQ_NO" : `Hotel Service order`,
    "TB_IF_CM_RS_SVC.PRE_HIS_CRE_NO" : ``,
    "TB_IF_CM_RS_SVC.QTY" : `Quantity`,
    "TB_IF_CM_RS_SVC.ROW_UNIQUE_NO" : `ROW UNIQUE Number`,
    "TB_IF_CM_RS_SVC.SALE_DATE" : `Business Date`,
    "TB_IF_CM_RS_SVC.SVC" : `Service Charge`,
    "TB_IF_CM_RS_SVC.SVC_ORGN_TYPE_CN" : `Service occurrence classification code number`,
    "TB_IF_CM_RS_SVC.TRACE_NO" : `Trace Number`,
    "TB_IF_CM_RS_SVC.VAT" : `VAT`,
    "TB_ZZ_EMAIL_CUSTM_SET.ACTIVE_YN" : `USE Y/N`,
    "TB_ZZ_EMAIL_CUSTM_SET.CN_NO" : `Channel name contractor info`,
    "TB_ZZ_EMAIL_CUSTM_SET.CREATED" : `Email setting data has been created`,
    "TB_ZZ_EMAIL_CUSTM_SET.DELETED" : `Email setting data has been deleted`,
    "TB_ZZ_EMAIL_CUSTM_SET.EMAIL" : `Email`,
    "TB_ZZ_EMAIL_CUSTM_SET.EMAIL_CUSTM_SET_NO" : `Email receiver setting number`,
    "TB_ZZ_EMAIL_CUSTM_SET.EMAIL_SENDER_SET_CN" : `Email sender setting code number`,
    "TB_ZZ_EMAIL_CUSTM_SET.EMAIL_SET_DATE" : `Email setting date`,
    "TB_ZZ_EMAIL_CUSTM_SET.FIRST_REG_DT" : `Original Registration Date`,
    "TB_ZZ_EMAIL_CUSTM_SET.FIRST_REG_USER_IP" : `Original Registration user IP`,
    "TB_ZZ_EMAIL_CUSTM_SET.FIRST_REG_USER_NO" : `Original Registration user number`,
    "TB_ZZ_EMAIL_CUSTM_SET.INCHARGE_NAME" : `Name`,
    "TB_ZZ_EMAIL_CUSTM_SET.LAST_CHNG_DT" : `Last Update date`,
    "TB_ZZ_EMAIL_CUSTM_SET.LAST_CHNG_USER_IP" : `Last Update user IP`,
    "TB_ZZ_EMAIL_CUSTM_SET.LAST_CHNG_USER_NO" : `Last Update user number`,
    "TB_ZZ_EMAIL_CUSTM_SET.TRACE_NO" : `Trace Number`,
    "TB_ZZ_USER.ACTIVE_YN" : `Status`,
    "TB_ZZ_USER.CN_NO" : `Channel`,
    "TB_ZZ_USER.DATE_TYPE_CN" : `Date Classification`,
    "TB_ZZ_USER.DEL_YN" : `Delete Status`,
    "TB_ZZ_USER.DORMANT_GUID_DATE" : `Inactive Announce Date`,
    "TB_ZZ_USER.DORMANT_MEMBER_STATUS_CN" : `Inavtive Member Status`,
    "TB_ZZ_USER.DORMANT_START_DATE" : `Inactive Start Date`,
    "TB_ZZ_USER.DUTY_CN" : `Job Code Number`,
    "TB_ZZ_USER.ETIQUETTE_SET_YN" : `Etiquett Mode Y/N`,
    "TB_ZZ_USER.FIRST_NAME" : `Name`,
    "TB_ZZ_USER.FIRST_REG_DT" : `Original Registration Date`,
    "TB_ZZ_USER.FIRST_REG_USER_IP" : `Original Registration user IP`,
    "TB_ZZ_USER.FIRST_REG_USER_NO" : `Original Registration user number`,
    "TB_ZZ_USER.GMT_TIME_GAP" : `GMT Time Difference`,
    "TB_ZZ_USER.INCHARGE_YN" : `Have a person in charge`,
    "TB_ZZ_USER.LANG_CN" : `Language Code`,
    "TB_ZZ_USER.LAST_CHNG_DT" : `Last Update date`,
    "TB_ZZ_USER.LAST_CHNG_USER_IP" : `Last Update user IP`,
    "TB_ZZ_USER.LAST_CHNG_USER_NO" : `Last Update user number`,
    "TB_ZZ_USER.LAST_LOGIN_DATE" : `Last login Date`,
    "TB_ZZ_USER.LAST_NAME" : `Last Name`,
    "TB_ZZ_USER.LOGIN_FAIL_TIMES" : `Number of failed logins`,
    "TB_ZZ_USER.LOGIN_PWD_CHNG_YN" : `Whether to change the login password`,
    "TB_ZZ_USER.MOBILE_NO" : `Mobile Number`,
    "TB_ZZ_USER.PM_NO" : `Hotel`,
    "TB_ZZ_USER.PWD_CHNG_DATE" : `Password Change Date`,
    "TB_ZZ_USER.ROW_UNIQUE_NO" : `ROW UNIQUE Number`,
    "TB_ZZ_USER.SMS_SET_YN" : `SMS Setting Status`,
    "TB_ZZ_USER.TEL_NO" : `Phone Number`,
    "TB_ZZ_USER.TRACE_NO" : `Trace Number`,
    "TB_ZZ_USER.TUTORIAL_VIEW_YN" : `Tutorial Y/N`,
    "TB_ZZ_USER.USER_EMAIL" : `User E-Mail`,
    "TB_ZZ_USER.USER_ID" : `User ID`,
    "TB_ZZ_USER.USER_ID_LOCK_YN" : `User ID LOCK status`,
    "TB_ZZ_USER.USER_NO" : `User No.`,
    "TB_ZZ_USER.USER_PWD" : `User Password`,
    "TB_ZZ_USER.USER_ROLE_CN" : `User Role`,
    "ZZ02_0500.message.cert_key_expired" : `Certificaction Code is expired.`,
    "ZZ02_0500.message.cert_key_not_exist" : `Certcode is not existing.`,
    "ZZ10_0101.title.subject_manual_management" : `Manual Management`,
    "ZZ10_0102.title.subject_service_management" : `Service Management`,
    "ZZ10_0103.title.subject_source_management" : `B2B Account Management`,
    "ZZ10_0104.title.subject_operation_management" : `Operation/Management`,
    "ZZ13_0200.buttom.init" : `Initialization`,
    "ZZ13_0200.buttom.search" : `Search`,
    "ZZ13_0200.etc.required" : `Required`,
    "ZZ13_0200.title.gridHeaderCrtDate" : `Process Date`,
    "ZZ13_0200.title.gridHeaderDeadline" : `Sales Status`,
    "ZZ13_0200.title.gridHeaderHotelRoomName" : `Hotel Room`,
    "ZZ13_0200.title.gridHeaderPropertyName" : `Property`,
    "ZZ13_0200.title.gridHeaderReserveCount" : `Sold (Reservation)`,
    "ZZ13_0200.title.gridHeaderResiduaryCount" : `Left (Remaining)`,
    "ZZ13_0200.title.gridHeaderSaleDate" : `Sales Date`,
    "ZZ13_0200.title.searchAll" : `Total`,
    "ZZ13_0200.title.searchDivision" : `Division`,
    "ZZ13_0200.title.searchHotelRoomType" : `Hotel Room`,
    "ZZ13_0200.title.searchProperty" : `Property`,
    "ZZ13_0200.title.searchperiodType" : `Process Date`,
    "ZZ13_0200.validation.warningMessageProcDate" : ` Processing period cannot exceed 31 days.`,
    "appFooter.title.message" : `Manual - In WINGS CMS user can look up the manual by each menu. Click the menu you are curious about on the left side menu tap.`,
    "autocomplete.etc.allOption" : `Total`,
    "certpopup.title.cancel" : `Cancel`,
    "certpopup.title.certification" : `Authentication`,
    "certpopup.title.checkInput" : `Please write (  )`,
    "certpopup.title.close" : `Exit`,
    "certpopup.title.complate" : `Completed`,
    "certpopup.title.password" : `Password`,
    "certpopup.title.username" : `User Name`,
    "cm01_0100.button.accept" : `Customer Service`,
    "cm01_0100.button.confirm" : `Confirm`,
    "cm01_0100.button.confirmNo" : `No`,
    "cm01_0100.button.confirmYes" : `Yes`,
    "cm01_0100.button.resend" : `Resend`,
    "cm01_0100.button.search" : `Search`,
    "cm01_0100.button.searchInitialize" : `Reset`,
    "cm01_0100.message.noDataChannel" : `Please Select the Channel.`,
    "cm01_0100.message.resendAlertConfirmMessage" : `Resend is failed. (@(0)/3) <br/><br/>Please make manually an unscheduled reservation in PMS<br/>Click the [Send Error] button to send the error information to Sanhait.<br/>The collected data is used only to solve the problem.<br/>`,
    "cm01_0100.message.resendAlertMessage" : `Sending Result (@(0)/3) <br/><br/>System will attempt to send again after clicking [Resend].<br/>If you want to report error to Sanha, please click [Report to Sanha].<br/>Reported data will solely be used for troubleshooting.<br/>`,
    "cm01_0100.title.adr" : `Room Rate/Day`,
    "cm01_0100.title.all" : `Total`,
    "cm01_0100.title.cancelReservation" : `Cancelled`,
    "cm01_0100.title.channelName" : `Channel`,
    "cm01_0100.title.channelNameCondition" : `Channel`,
    "cm01_0100.title.channelRsvnNo" : `Channel RSVN NO.`,
    "cm01_0100.title.channelRsvnNoCondition" : `Channel RSVN NO.`,
    "cm01_0100.title.checkInDate" : `Arrival`,
    "cm01_0100.title.checkOutDate" : `Departure`,
    "cm01_0100.title.cmsRsvnNo" : `CMS RSVN NO.`,
    "cm01_0100.title.cmsRsvnNoCondition" : `CMS RSVN NO.`,
    "cm01_0100.title.cmsStatus" : `CMS Status`,
    "cm01_0100.title.complete" : `Complete`,
    "cm01_0100.title.confirmMessage" : `Channel reservation number: Would you like to resend the reservation data of @(0), @(1)?`,
    "cm01_0100.title.errorEmailSuccess" : `Error content has been sent.`,
    "cm01_0100.title.guestName" : `Guest`,
    "cm01_0100.title.hotelRoom" : `Hotel Room`,
    "cm01_0100.title.inhouseName" : `Guest`,
    "cm01_0100.title.modifyDate" : `Modified Date`,
    "cm01_0100.title.modifyDate_1" : `Modified`,
    "cm01_0100.title.night" : `Nights`,
    "cm01_0100.title.normalReservation" : `Confirmed`,
    "cm01_0100.title.pmsRsvnNo" : `PMS RSVN NO.`,
    "cm01_0100.title.pmsRsvnNoCondition" : `PMS RSVN NO.`,
    "cm01_0100.title.pmsStatus" : `PMS Status`,
    "cm01_0100.title.popupSuccess" : `Success`,
    "cm01_0100.title.realCheckOutDate" : `Actual Check-out`,
    "cm01_0100.title.resendMessage" : `<div style='text-align:right;margin-top:-20px;margin-bottom:20px;'>Data was not successfully transferred to the PMS.</span></div><div>If you click the [Resend] button, it will resend again.</div><div>When you click the [Customer Center] button, an e-mail is automatically sent to the customer center of SanhaIt.</div><br/><div>The requeset can be checked in the email.</div>`,
    "cm01_0100.title.resendMessage_1" : `Sending Result (@(1)/3) <br/><br/>System will attempt to send again after clicking [Resend].<br/>If you want to report error to Sanha, please click [Report to Sanha].<br/>Reported data will solely be used for troubleshooting.<br/>`,
    "cm01_0100.title.reservationDate" : `Book`,
    "cm01_0100.title.reservationList" : `Reservation List`,
    "cm01_0100.title.restore" : `Restore`,
    "cm01_0100.title.revenue" : `Revenue`,
    "cm01_0100.title.roomCount" : `Rooms`,
    "cm01_0100.title.sendResult" : `Sending Result`,
    "cm01_0100.title.sendStatus" : `Sending Result`,
    "cm01_0100.title.successMessage" : `Reservation No.: @(0) has been sent to PMS`,
    "cm01_0100.title.todaySaleResult" : `Today's Sales Result`,
    "cm01_0100.title.tooltipMessage1" : `If the reservations cannot be sent to PMS from CMS, ‘Resend’ or ‘Fail’ will be activated. Please send the reservations to PMS.`,
    "cm01_0100.title.tooltipMessage2" : `If the transmission fails, the resend button`,
    "cm01_0100.title.tooltipMessage3" : `It will be activated soon. Please attempt the reservation sending to PMS.`,
    "cm01_0100.title.totalAmount" : `Total Amount`,
    "cm01_0100.title.userCnfmDt" : `RSVN Confirm`,
    "cm01_0110.title.addOption" : `CMS Special Service`,
    "cm01_0110.title.afterData" : `After Change`,
    "cm01_0110.title.amount" : `Total`,
    "cm01_0110.title.beforeData" : `Before Change`,
    "cm01_0110.title.bfKidFee" : `Breakfast Child Rate`,
    "cm01_0110.title.bfKidFee_1" : `Special Request`,
    "cm01_0110.title.cancelDate" : `Cancel Date`,
    "cm01_0110.title.ccCvcExp" : `CC / Exp.`,
    "cm01_0110.title.ccInfo" : `CC Details`,
    "cm01_0110.title.changeMessage" : `@(0) has been changed from '@ (1)' to '@ (2)'.`,
    "cm01_0110.title.changeMessageWithMore" : `@(0) has been changed.`,
    "cm01_0110.title.channelName" : `Channel`,
    "cm01_0110.title.channelRsvnNo" : `Channel RSVN Number`,
    "cm01_0110.title.checkinDate" : `Arrival Date`,
    "cm01_0110.title.checkoutDate" : `Departure Date`,
    "cm01_0110.title.close" : `Close`,
    "cm01_0110.title.cmsRsvnNo" : `CMS RSVN Number`,
    "cm01_0110.title.commision" : `Commission`,
    "cm01_0110.title.count" : `QTY`,
    "cm01_0110.title.date" : `Date`,
    "cm01_0110.title.detailInfo" : `Details`,
    "cm01_0110.title.detailViewer" : `Details`,
    "cm01_0110.title.etcInfo" : `Other`,
    "cm01_0110.title.fee" : `Rate`,
    "cm01_0110.title.history" : `History`,
    "cm01_0110.title.inhouseGuestEmail" : `E-Mail`,
    "cm01_0110.title.inhouseGuestInfo" : `Guest Details`,
    "cm01_0110.title.inhouseInfo" : `General`,
    "cm01_0110.title.inhouseName" : `Guest Name`,
    "cm01_0110.title.interMemo" : `Inter Memo`,
    "cm01_0110.title.memo" : `Memo`,
    "cm01_0110.title.mobileNo" : `Mobile`,
    "cm01_0110.title.nationAndCurreny" : `Nationality / Currency`,
    "cm01_0110.title.noneData" : `Space`,
    "cm01_0110.title.otaRsvnNo" : `OTA RSVN Number`,
    "cm01_0110.title.payAndStep" : `Payment / Status`,
    "cm01_0110.title.personCount" : `Adult/Child/Infant`,
    "cm01_0110.title.pmsRoomType" : `Channel/PMS Room Type`,
    "cm01_0110.title.pmsRsvnNo" : `PMS RSVN Number`,
    "cm01_0110.title.receiveHistory" : `수신 이력`,
    "cm01_0110.title.reservationName" : `Booker`,
    "cm01_0110.title.roomCount" : `Number of Rooms`,
    "cm01_0110.title.roomFee" : `Room Rate`,
    "cm01_0110.title.roomFeeInsvc" : `OTA Add-on Service`,
    "cm01_0110.title.roomType" : `Channel/CMS RoomType`,
    "cm01_0110.title.roommateName" : `Accompany`,
    "cm01_0110.title.rsvnDate" : `RSVN Date`,
    "cm01_0110.title.rsvnDetailPopup" : `예약 목록 상세 팝업`,
    "cm01_0110.title.rsvnInfo" : `Booker Infomation`,
    "cm01_0110.title.saleInfo" : `Sales Info`,
    "cm01_0110.title.sendStatus" : `Send status`,
    "cm01_0110.title.source" : `Source`,
    "cm01_0110.title.storeSob" : `Source Type`,
    "cm01_0110.title.svc" : `Service`,
    "cm01_0110.title.svcName" : `Service Name`,
    "cm01_0110.title.telNo" : `Phone`,
    "cm01_0110.title.title" : `Prefix`,
    "cm01_0110.title.tooltipMessage" : `If the reservations cannot be sent to PMS from CMS, ‘Resend’ or ‘Fail’ will be activated. Please send the reservations to PMS.`,
    "cm01_0110.title.totalAmount" : `Total`,
    "cm01_0110.title.updateContent" : `Update details`,
    "cm01_0110.title.updateDate" : `Update Date`,
    "cm01_0110.title.vat" : `VAT`,
    "cm01_0200.button.init" : `Initialization`,
    "cm01_0200.button.search" : `Search`,
    "cm01_0200.message.notSyncDate" : `동기화는 오늘 날짜 기준 최대 365일까지 가능합니다.`,
    "cm01_0200.message.selNotRm" : `동기화를 위해 선택된 객실이 없습니다. <br /> 확인 후 다시 시도해주세요.`,
    "cm01_0200.message.success" : `동기화 요청이 완료되었습니다. <br /> 동기화는 최대 5분 정도 소요됩니다.`,
    "cm01_0200.title.all" : `All`,
    "cm01_0200.title.cancel" : `Cancel`,
    "cm01_0200.title.date" : `Date`,
    "cm01_0200.title.friday" : `Fri`,
    "cm01_0200.title.monday" : `Mon`,
    "cm01_0200.title.pmsSync" : `PMS Sync`,
    "cm01_0200.title.propertyName" : `Property`,
    "cm01_0200.title.room" : `Room`,
    "cm01_0200.title.roomOccupancy" : `Occupancy`,
    "cm01_0200.title.roomRowTotal" : `Rooms`,
    "cm01_0200.title.roomTotal" : `Total`,
    "cm01_0200.title.roomTotal_1" : `Total`,
    "cm01_0200.title.roomType" : `Room Type`,
    "cm01_0200.title.roomTypeCode" : `Code`,
    "cm01_0200.title.saturday" : `Sat`,
    "cm01_0200.title.sellRoomCount" : `Sold Rooms`,
    "cm01_0200.title.startDate" : `Start Date`,
    "cm01_0200.title.sunday" : `Sun`,
    "cm01_0200.title.sync" : `Sync`,
    "cm01_0200.title.thursday" : `Thu`,
    "cm01_0200.title.title" : `Room Available`,
    "cm01_0200.title.today" : `Today`,
    "cm01_0200.title.tuesday" : `Tue`,
    "cm01_0200.title.wednesday" : `Wed`,
    "cm01_0300.title.baseTitle" : `Sales Analysis`,
    "cm01_0300.title.dateReportEmptyMessage" : `No Performance on @(1) @(2), @(0).`,
    "cm01_0300.title.daySale" : `Sale Analysis_Daily`,
    "cm01_0300.title.monthReportEmptyMessage" : `There are no performance data for @(1) month of @(0) year.`,
    "cm01_0300.title.monthReportEmptyMessage_1" : `No Performance on @(0).`,
    "cm01_0300.title.monthSale" : `Sale Analysis_Monthly`,
    "cm01_0300.title.sliderCount" : ` `,
    "cm01_0300.title.weekSale" : `Sale Analysis_Weekly`,
    "cm01_0300.title.weeklyReportEmptyMessage" : `There are no performance data for @(0) week.`,
    "cm01_0300.title.weeklyReportEmptyMessage_1" : `No Performance on @(1) @(2), @(0) ~ @(4) @(5), @(3).`,
    "cm01_0301.buttom.init" : `Initialization`,
    "cm01_0301.buttom.search" : `Search`,
    "cm01_0301.etc.gridUnitCount" : ` `,
    "cm01_0301.etc.gridUnitDay" : ` `,
    "cm01_0301.etc.gridUnitNumber" : ` `,
    "cm01_0301.etc.required" : `Required`,
    "cm01_0301.etc.sliderCount" : `count`,
    "cm01_0301.title.all" : `All`,
    "cm01_0301.title.channel" : `Channel`,
    "cm01_0301.title.filterChannel" : `Channel`,
    "cm01_0301.title.filterCmRaTy" : `상품그룹명`,
    "cm01_0301.title.filterOption" : `Condition`,
    "cm01_0301.title.filterProperty" : `Property`,
    "cm01_0301.title.filterStructure" : `Contents`,
    "cm01_0301.title.filterperiod" : `Date`,
    "cm01_0301.title.filterperiodType" : `Period Type`,
    "cm01_0301.title.gridColCount" : `Row Number`,
    "cm01_0301.title.gridHeaderAdr" : `ADR`,
    "cm01_0301.title.gridHeaderCancelCnt" : `Cancel`,
    "cm01_0301.title.gridHeaderGuestCnt" : `Number of guests`,
    "cm01_0301.title.gridHeaderHigntCnt" : `Room Nights`,
    "cm01_0301.title.gridHeaderNoshCnt" : `Noshow`,
    "cm01_0301.title.gridHeaderRoomRev" : `Revenue`,
    "cm01_0301.title.rev" : `Reservation`,
    "cm01_0301.title.searchProperty" : `Property`,
    "cm01_0301.title.searchperiod" : `Date`,
    "cm01_0301.title.sliderTitle" : `Sales Performance of Today`,
    "cm01_0302.buttom.init" : `Initialization`,
    "cm01_0302.buttom.search" : `Search`,
    "cm01_0302.etc.gridUnitDay" : ` `,
    "cm01_0302.etc.gridUnitNumber" : ` `,
    "cm01_0302.etc.required" : `Required`,
    "cm01_0302.etc.sliderCount" : `count`,
    "cm01_0302.title.all" : `All`,
    "cm01_0302.title.filterChannel" : `Channel`,
    "cm01_0302.title.filterCmRaTy" : `상품그룹명`,
    "cm01_0302.title.filterOption" : `Condition`,
    "cm01_0302.title.filterProperty" : `Property`,
    "cm01_0302.title.filterStructure" : `Contents`,
    "cm01_0302.title.filterperiod" : `Date`,
    "cm01_0302.title.filterperiodType" : `Period Type`,
    "cm01_0302.title.gridColCount" : `Row Number`,
    "cm01_0302.title.gridHeaderFri" : `Friday`,
    "cm01_0302.title.gridHeaderMon" : `Monday`,
    "cm01_0302.title.gridHeaderSat" : `Saturday`,
    "cm01_0302.title.gridHeaderSun" : `Sunday`,
    "cm01_0302.title.gridHeaderThu" : `Thursday`,
    "cm01_0302.title.gridHeaderTue" : `Tuesday`,
    "cm01_0302.title.gridHeaderWeb" : `Wednesday`,
    "cm01_0302.title.proc_dt" : `Total`,
    "cm01_0302.title.searchProperty" : `Property`,
    "cm01_0302.title.searchperiod" : `Date`,
    "cm01_0302.title.sliderTitle" : `Sales Performance of this week`,
    "cm01_0302.title.userid" : `Channel`,
    "cm01_0302.title.username" : `Category`,
    "cm01_0303.buttom.init" : `Initialization`,
    "cm01_0303.buttom.search" : `Search`,
    "cm01_0303.etc.gridUnitDay" : ` `,
    "cm01_0303.etc.gridUnitNumber" : ` `,
    "cm01_0303.etc.required" : `Required`,
    "cm01_0303.etc.sliderCount" : `count`,
    "cm01_0303.title.all" : `All`,
    "cm01_0303.title.filterChannel" : `Channel`,
    "cm01_0303.title.filterCmRaTy" : `상품그룹명`,
    "cm01_0303.title.filterOption" : `Condition`,
    "cm01_0303.title.filterProperty" : `Property`,
    "cm01_0303.title.filterStructure" : `Contents`,
    "cm01_0303.title.filterperiod" : `Date`,
    "cm01_0303.title.filterperiodType" : `Period Type`,
    "cm01_0303.title.gridColCount" : `Row Number`,
    "cm01_0303.title.gridHeaderApr" : `April`,
    "cm01_0303.title.gridHeaderAug" : `August`,
    "cm01_0303.title.gridHeaderFeb" : `February`,
    "cm01_0303.title.gridHeaderJan" : `January`,
    "cm01_0303.title.gridHeaderJul" : `July`,
    "cm01_0303.title.gridHeaderJun" : `June`,
    "cm01_0303.title.gridHeaderMar" : `March`,
    "cm01_0303.title.gridHeaderMay" : `May`,
    "cm01_0303.title.gridHeaderSept" : `September`,
    "cm01_0303.title.gridHeaderdev" : `December`,
    "cm01_0303.title.gridHeadernov" : `November`,
    "cm01_0303.title.gridHeaderoct" : `October`,
    "cm01_0303.title.proc_dt" : `Total`,
    "cm01_0303.title.searchProperty" : `Property`,
    "cm01_0303.title.searchperiod" : `Period`,
    "cm01_0303.title.sliderTitle" : `Sales performance of this month`,
    "cm01_0303.title.userid" : `Channel`,
    "cm01_0303.title.username" : `Category`,
    "cm01_0400.buttom.init" : `Initialization`,
    "cm01_0400.buttom.search" : `Search`,
    "cm01_0400.etc.required" : `Required`,
    "cm01_0400.title.gridHeaderChangeDate" : `Change Date`,
    "cm01_0400.title.gridHeaderChannelName" : `Channel`,
    "cm01_0400.title.gridHeaderChannelProductName" : `Channel Product`,
    "cm01_0400.title.gridHeaderHotelRoomName" : `Hotel Room`,
    "cm01_0400.title.gridHeaderProductGorupName" : `Product Group`,
    "cm01_0400.title.gridHeaderPropertyName" : `Property`,
    "cm01_0400.title.gridHeaderRoomGroupName" : `Room Group`,
    "cm01_0400.title.gridHeaderSaleDate" : `Sales Date`,
    "cm01_0400.title.gridHeaderUserIp" : `Processor IP`,
    "cm01_0400.title.gridHeaderUserName" : `Processor`,
    "cm01_0400.title.gridHeaderWorkHistory" : `Work History`,
    "cm01_0400.title.searchChannel" : `Channel`,
    "cm01_0400.title.searchChannelProductName" : `Channel Product`,
    "cm01_0400.title.searchDivision" : `Menu`,
    "cm01_0400.title.searchHotelRoomName" : `Hotel Room`,
    "cm01_0400.title.searchPeriodSale" : `Sales Period`,
    "cm01_0400.title.searchProductGroupName" : `Product Group`,
    "cm01_0400.title.searchProperty" : `Property`,
    "cm01_0400.title.searchRoomGroupName" : `Room Group`,
    "cm01_0400.title.searchUserName" : `Processor`,
    "cm01_0400.title.searchperiodType" : `Change Date`,
    "cm01_0400.validation.warningMessageProcDate" : `The processing period cannot exceed 31 days.`,
    "cm01_0600.buttom.init" : `Initialization`,
    "cm01_0600.buttom.search" : `Search`,
    "cm01_0600.etc.required" : `Required`,
    "cm01_0600.title.filterChannelProductName" : `Channel Product`,
    "cm01_0600.title.filterDivision" : `Menu`,
    "cm01_0600.title.filterHotelRoomName" : `Hotel Room`,
    "cm01_0600.title.filterPeriodSale" : `Sales Date`,
    "cm01_0600.title.filterProductGroupName" : `Product Group`,
    "cm01_0600.title.filterProperty" : `Channel`,
    "cm01_0600.title.filterRoomGroupName" : `Room Group`,
    "cm01_0600.title.filterUserName" : `Processor`,
    "cm01_0600.title.filterperiodType" : `Period of Change`,
    "cm01_0600.title.filterperiodType_1" : `Send Date`,
    "cm01_0600.title.gridHeaderAllocationNumber" : `Allocation Number`,
    "cm01_0600.title.gridHeaderChannelChargeType" : `Channel Product`,
    "cm01_0600.title.gridHeaderChannelName" : `Channel`,
    "cm01_0600.title.gridHeaderChannelRoomName" : `Channel Room`,
    "cm01_0600.title.gridHeaderCheckInDeadline" : `Close to Arrival`,
    "cm01_0600.title.gridHeaderCheckOutDeadline" : `Close to Departure`,
    "cm01_0600.title.gridHeaderDeadline" : `Availability`,
    "cm01_0600.title.gridHeaderEndDate" : `End Date`,
    "cm01_0600.title.gridHeaderMaxStayPeriod" : `Minimum Length of Stay`,
    "cm01_0600.title.gridHeaderMinStayPeriod" : `Maximum Length of Stay`,
    "cm01_0600.title.gridHeaderRoomRate" : `Room Rate`,
    "cm01_0600.title.gridHeaderStartDate" : `Start Date`,
    "cm01_0600.title.gridHeaderTransferResult" : `Sending Result`,
    "cm01_0600.title.popupClose" : `Close`,
    "cm01_0600.title.popupDetailInfo" : `Detail Information`,
    "cm01_0600.title.popupProcessingResult" : `Process result`,
    "cm01_0600.title.popupStayInfo" : `Stay Information`,
    "cm01_0600.title.popupTransferStatus" : `Send status`,
    "cm01_0600.title.popupTransferStatusMessage" : `It is possible to check whether the data transmitted from the CMS to the channel is normally transmitted.`,
    "cm01_0600.title.searchChannel" : `Channel`,
    "cm01_0600.validation.warningMessageProcDate" : ` Processing period cannot exceed 31 days.`,
    "cm02_0100.button.init" : `Initialization`,
    "cm02_0100.button.search" : `Search`,
    "cm02_0100.message.addChnlRate" : `Fee can be varied by terms & conditions of each hotel’s contract`,
    "cm02_0100.title.ADD_CHNL_AGREE_STAY" : `Connection needs to be approved.`,
    "cm02_0100.title.ADD_CHNL_BTN_NO" : `No`,
    "cm02_0100.title.ADD_CHNL_BTN_YES" : `Yes`,
    "cm02_0100.title.ADD_CHNL_NOT_OPEN_USER" : `Channel open is unavailable by users.`,
    "cm02_0100.title.ADD_CHNL_YN_MSG_1" : `Number of contract channels is exceeded. Do you want to add the channel?`,
    "cm02_0100.title.ADD_CHNL_YN_MSG_2" : `KRW 20,000(VAT Exclusive) will be charged for extra Channel Connection.`,
    "cm02_0100.title.ADD_CHNL_YN_MSG_3" : `KRW 50,000(VAT Exclusive) will be charged for extra Channel Connection.`,
    "cm02_0100.title.BTN_CERT" : `Verification`,
    "cm02_0100.title.BTN_MANUAL_NON" : `No Manual`,
    "cm02_0100.title.BTN_PREPARING" : `Preparing`,
    "cm02_0100.title.CERT_CHECK" : `Channel Reservation Number`,
    "cm02_0100.title.CERT_CHECK_TOOLTIP" : `Please input the Hotel ID at channel. You can find Hotel ID from Extranet or it could be Product ID from channel. `,
    "cm02_0100.title.CHNL_PROCESS_CANCEL" : `Cancel connection. Do you want to cancel connection?`,
    "cm02_0100.title.CM02_0100_CHNL_CMPL_RELEASE" : `Are you sure to disconnect @(0)?`,
    "cm02_0100.title.CM02_0100_CHNL_CMPL_RELEASE_FAILE" : `연동해지는 호텔관리자만 가능합니다`,
    "cm02_0100.title.CM02_0100_FREE_CHNL" : `[Free Channel]`,
    "cm02_0100.title.CM02_0100_HOTEL_ID_CHECK" : `Please enter your hotel ID.`,
    "cm02_0100.title.CM02_0100_MANAGE_SVC" : `Channel Service Code Mapping`,
    "cm02_0100.title.CM02_0100_REAL_CHNL_CNT3" : `Actual Connected Channels(Free Channel)`,
    "cm02_0100.title.CertFail" : `Channel Cert is confirmed.`,
    "cm02_0100.title.CertSuccess" : `Channel Cert Confirm`,
    "cm02_0100.title.ChannelDisconect" : `Disconnect`,
    "cm02_0100.title.ChannelURL" : `Channel Extranet`,
    "cm02_0100.title.ConnCancel" : `Connection Cancel`,
    "cm02_0100.title.ConnCompl" : `Connection complete`,
    "cm02_0100.title.ConnReq" : `Request add connection`,
    "cm02_0100.title.DownManual" : `Note`,
    "cm02_0100.title.ManageChannelInfo" : `Channel Information`,
    "cm02_0100.title.ManagePlan" : `Plan Management`,
    "cm02_0100.title.ManageRoom" : `Room & Product Management`,
    "cm02_0100.title.OpenTutorial" : `Open Tutorial`,
    "cm02_0100.title.RqChannelInfo" : `Channel Information Setting`,
    "cm02_0100.title.SaveSuccess" : `Saved.`,
    "cm02_0100.title.cancel" : `Next`,
    "cm02_0100.title.cancelSuccess" : `Your connection has been canceled`,
    "cm02_0100.title.channelInformation" : `Channel Manager Information`,
    "cm02_0100.title.complChannel" : `CONNECTED CHANNEL`,
    "cm02_0100.title.complChannelTooltip" : `It is a list of currently connected channels via CMS.<br /> If the channel is disconnected, users cannot control via CMS.`,
    "cm02_0100.title.contChnlCNT" : `Number of contract channels`,
    "cm02_0100.title.contChnlCntExceptFree" : `Connectable channels(Free channel exclusive)`,
    "cm02_0100.title.next" : `Save`,
    "cm02_0100.title.notConnChannel" : `CONNECTABLE CHANNEL`,
    "cm02_0100.title.notConnChannelTooltip" : `It is a list of extra connectable channels. Connection fee can be charged depending on the terms and conditions.`,
    "cm02_0100.title.notUseSearch" : `Inactive Room Group Included`,
    "cm02_0100.title.prev" : `Previous`,
    "cm02_0100.title.processChannel" : `CONNECTING CHANNEL`,
    "cm02_0100.title.processChannelTooltip" : `You can see the channel connection (on-going) list. Channels in connection with channel connection (on-going) are also included in the free interlocking channel.`,
    "cm02_0100.title.realChnlCNT" : `Actual Number of connected channels`,
    "cm02_0100.title.realChnlCNT2" : `Actual Connected Channel(Additional Connect)`,
    "cm02_0100.title.realChnlCNTnotfree" : `Actual Number of connected channels (exclude complimentary channels)`,
    "cm02_0100.title.releaseSuccess" : `Connection is terminated.`,
    "cm02_0100.title.searchPromotion" : `Search promotion product Group`,
    "cm02_0100.title.title" : `Channel Management`,
    "cm02_0200.title.RQ_ADD_CHANNEL" : `Additional Channel Connection Application`,
    "cm02_0200.title.RQ_ADD_CHNL_MSG_1" : `※ If you wish to apply for channel connection, please fill out the following.`,
    "cm02_0200.title.RQ_ADD_CHNL_MSG_2" : `[Approval] takes up to [3 business days] after submission. After [approval], user can set by itself.`,
    "cm02_0200.title.RQ_BTN_CANCEL" : `Cancel`,
    "cm02_0200.title.RQ_BTN_CLOSE" : `Close`,
    "cm02_0200.title.RQ_BTN_SEND" : `Submit`,
    "cm02_0200.title.RQ_BTN_SEND_MSG_1" : `Completed`,
    "cm02_0200.title.RQ_BTN_SEND_MSG_2" : `[Approval] takes up to [3 business days] maximum, after channel connection request.`,
    "cm02_0200.title.RQ_EMAIL" : `Submitter Email`,
    "cm02_0200.title.RQ_MAIL_CHECK" : `Please check the email.`,
    "cm02_0200.title.RQ_NAME" : `Submitter `,
    "cm02_0200.title.RQ_NAME_CHECK" : `Please confirm the submitter`,
    "cm02_0200.title.RQ_TEL" : `Submitter contacts`,
    "cm02_0200.title.RQ_TELL_CHECK" : `Please confirm the submitter contacts`,
    "cm02_0300.title.RQ_ADD_CHNL_MSG_1" : `※ Please enter the ID and password to access the admin page (extranet) of the channel you applied for smooth channel connection. The connection may be delayed if incorrect information is entered.`,
    "cm02_0300.title.RQ_BTN_SEND_MSG_1" : `[Connection] takes up to [14 business days] maximum, after channel connection request.`,
    "cm02_0300.title.RQ_CHANNEL" : `Requesting Channel Name`,
    "cm02_0300.title.RQ_CHANNEL_ID" : `Extranet ID`,
    "cm02_0300.title.RQ_CHANNEL_PW" : `Extranet PW`,
    "cm02_0300.title.RQ_ID_CHECK" : `Please check your Extranet ID`,
    "cm02_0300.title.RQ_PW_CHECK" : `Please check your Extranet Password.`,
    "cm02_0400.title.RQ_AGREE_CHECK" : `Please tick at 'agree' after confirming above`,
    "cm02_0400.title.RQ_CONTRACT_MSG_1" : `※ Additional channel connection`,
    "cm02_0400.title.RQ_CONTRACT_MSG_10" : `- PMS Reservation Environment Build`,
    "cm02_0400.title.RQ_CONTRACT_MSG_11" : `- OTA Rate and Room allocation Synchronization`,
    "cm02_0400.title.RQ_CONTRACT_MSG_12" : `- Open and Stabilization`,
    "cm02_0400.title.RQ_CONTRACT_MSG_13" : `- Operation Support`,
    "cm02_0400.title.RQ_CONTRACT_MSG_14" : `Total`,
    "cm02_0400.title.RQ_CONTRACT_MSG_15" : `Montly billing cost`,
    "cm02_0400.title.RQ_CONTRACT_MSG_16" : `Submit Date`,
    "cm02_0400.title.RQ_CONTRACT_MSG_2" : `(Currency : KRW, VAT Included)`,
    "cm02_0400.title.RQ_CONTRACT_MSG_3" : `Division`,
    "cm02_0400.title.RQ_CONTRACT_MSG_4" : `History`,
    "cm02_0400.title.RQ_CONTRACT_MSG_5" : `Unit Price`,
    "cm02_0400.title.RQ_CONTRACT_MSG_6" : `Quantity`,
    "cm02_0400.title.RQ_CONTRACT_MSG_7" : `Amount`,
    "cm02_0400.title.RQ_CONTRACT_MSG_8" : `Remark`,
    "cm02_0400.title.RQ_CONTRACT_MSG_9" : `CMS additional channel connection`,
    "cm02_0500.title.CM02_0500_CHNL_REQ_ERR" : `There is no process of the request channel. <br/> Please contact the administrator.`,
    "cm02_0600.title.ACCOUNT" : `Account`,
    "cm02_0600.title.ACCOUNT_TIP" : `Enter Account code (Numbers only)`,
    "cm02_0600.title.ACCOUNT_TOOLTIP" : `Enter Account/Company Profile No. <br/>(If user use only WINGS CMS without WINGS PMS, please enter random number.)`,
    "cm02_0600.title.CHNL_VAT_TYPE" : `Hotel Pay VAT included`,
    "cm02_0600.title.CM02_0600_CN_HOTEL_ID_CHECK" : `This is the channel hotel ID already registered. Please check again.`,
    "cm02_0600.title.CM02_0600_DELETE_MSG1" : `If you ‘Reset’ the Hotel ID, all information of ever step will be reset.`,
    "cm02_0600.title.CM02_0600_DELETE_MSG2" : `Are you sure to Reset?`,
    "cm02_0600.title.COMMITION" : `Commission(%)`,
    "cm02_0600.title.EXTRANET" : `Extranet`,
    "cm02_0600.title.HOTEL_ID" : `Hotel ID`,
    "cm02_0600.title.HOTEL_ID_INPUT" : `Channel allocation / Rate send URL (TEST)`,
    "cm02_0600.title.HOTEL_ID_TOOLTIP" : `It is channel's unique hotel ID. Please enter the hotel ID of the channel's extranet or the authentication ID provided by the channel (Product ID / EQC Key, etc.).`,
    "cm02_0600.title.HOTEL_INPUT_CHECK" : `Enter the hotel information in the channel`,
    "cm02_0600.title.MARKET_TYPE" : `Market Type`,
    "cm02_0600.title.MARKET_TYPE_TOOLTIP" : `Select "Market type" from reservations at channel. It will be indicated at PMS.`,
    "cm02_0600.title.PAY_CHANNEL" : `Channel Pay`,
    "cm02_0600.title.PAY_CHANNEL_TOOLTIP" : `Guests paid prior to the arrival. Please enter the Account only when CMS and PMS are connected.`,
    "cm02_0600.title.PAY_HOTEL" : `Hotel Pay`,
    "cm02_0600.title.PAY_HOTEL_TOOLTIP" : `Guests pay on site. Please enter the Account only when CMS and PMS are connected.`,
    "cm02_0600.title.PAY_SELECT" : `Hotel Pay / Channel Pay`,
    "cm02_0600.title.PAY_SELECT_TOOLTIP" : `To create a rate setting and reservation by separating [on-site payment] / [channel payment], check the checkbox. If you select a selling price/actual price, you will be charged according to your settings when a reservation occurs. (However, it is possible only when the channel provides the function.)`,
    "cm02_0600.title.RATE_SEND" : `Rate Send`,
    "cm02_0600.title.RATE_SEND_TOOLTIP" : `You can choose either sell rate or net rate at sending the rate from WINGS CMS to channel Extranet.`,
    "cm02_0600.title.REQUIRED_ITEM" : `Required`,
    "cm02_0600.title.RESET" : `Reset`,
    "cm02_0600.title.SALESPERSON" : `Sales Person`,
    "cm02_0600.title.SALESPERSON_TOOLTIP" : `Select "Sales Person" from reservations at channel. It will be indicated at PMS.`,
    "cm02_0600.title.SORC_TYPE_TOOLTIP" : `Select the Source Type to register on PMS for the reservations from channels.`,
    "cm02_0600.title.STEP_EXTRANET_NONE" : `There is no channel extranet registered.`,
    "cm02_0600.title.STEP_ID_DELETE" : `Channel Hotel ID`,
    "cm02_0600.title.STEP_REQUIRED_CHEK" : `Submit Date`,
    "cm02_0600.title.STEP_RQ_ERROR" : `Channel Hotel ID is reset.`,
    "cm02_0600.title.VAT_TYPE" : `Tax Setting`,
    "cm02_0600.title.VAT_TYPE_TOOLTIP" : `You can select the VAT rate and Service tax rate for reservations from channel. `,
    "cm02_0600.title.airbnbConnect" : `Connection Activation`,
    "cm02_0700.btn.wyndhamCreateRoom" : `WYNDHAM 객실 추가`,
    "cm02_0700.message.noMapping" : `There are room products that are not mapped. <br/> Please do mapping.`,
    "cm02_0800.btn.wyndhamCreateProduct" : `WYNDHAM 상품 추가`,
    "cm02_0850.title.CM02_0850_EXTRA_SETTING" : `Extra Setting`,
    "cm02_0850.title.CM02_0850_NOT_CHANGE_DATA" : `No data has been changed.`,
    "cm02_0851.button.save" : `Save`,
    "cm02_0851.button.search" : `Search`,
    "cm02_0851.button.searchInitialize" : `Initialization`,
    "cm02_0851.title.SaveSuccess" : `Saved`,
    "cm02_0851.title.channelName" : `Channel`,
    "cm02_0851.title.emptyData" : `체크된 채널 서비스코드 매핑 리스트중 빈 항목이 존재합니다.`,
    "cm02_0851.title.notChangeData" : ` No data has been changed.`,
    "cm02_0851.title.reservationList" : `Service Mapping`,
    "cm02_0851.title.svcMapToolTip" : `Users can map the Service Code from the channel for the reservation. <br /> If you want to check the Service Code at the reservations, please map them out.`,
    "cm02_0851.titlen.svcMap" : `Channel Service Code Mapping`,
    "cm02_0900.title.ALLOT_CLOSE" : `Close all rooms`,
    "cm02_0900.title.ALLOT_CLOSE_TIP" : `It will be tested whether the sale is closed after all the room types connected to the channel are cloased.`,
    "cm02_0900.title.ALLOT_CLOSE_TIP_CONTENT" : `Please check the booking Availability at the channel Extranet. It should be unavailable to book.`,
    "cm02_0900.title.ALLOT_OPEN" : `Open all rooms`,
    "cm02_0900.title.ALLOT_OPEN_TIP" : `It will be tested whether the sale is opened, after all the room types connected to the channel are opened.`,
    "cm02_0900.title.ALLOT_OPEN_TIP_CONTENT" : `Please check the booking Availability at the channel Extranet. It should be available to book.`,
    "cm02_0900.title.ALLOT_TEST" : `Availability Test`,
    "cm02_0900.title.BTN_ALLOT_PAGE" : `Go to Inventory Management`,
    "cm02_0900.title.BTN_COMPLETE" : `Complete`,
    "cm02_0900.title.BTN_RATE_PAGE" : `Go to Rate Management`,
    "cm02_0900.title.BTN_RATE_SEND" : `Rate Send`,
    "cm02_0900.title.BTN_RATE_SEND_TIP" : `Test the entered rate from wings CMS to channel.`,
    "cm02_0900.title.BTN_RATE_SEND_TIP2" : `Please confirm the rate that is tested. If rate is not on the Extranet, please click “Open all rooms” first.`,
    "cm02_0900.title.CM02_0900_DATE_CHECK_2" : `The period cannot be selected for more than 30 days.`,
    "cm02_0900.title.CM02_0900_LAST_SEND_TIME" : `Fianl Trasmission Date and Time :`,
    "cm02_0900.title.CM02_0900_MESSAGE_3" : `Rooms that have not been tested for rate transfer will not be allocated / closed. Please proceed after the rate transfer test.`,
    "cm02_0900.title.CM02_0900_RATE_SEND_TEST_CHECK" : `Please proceed after the rate transfer test.`,
    "cm02_0900.title.CM02_0900_SVC_MAP" : `Channel Service Code Mapping`,
    "cm02_0900.title.CM02_0900_SVC_MAP_BTN" : `Service Mapping`,
    "cm02_0900.title.CM02_0900_SVC_MAP_TOOLTIP" : `You can map the service code basically provided in the channel. Please proceed with the service mapping to receive the service code through PMS when a reservation is occured.`,
    "cm02_0900.title.CM02_0900_TEST_CHECK" : `Please proceed with the rate, allocation and deadline test.`,
    "cm02_0900.title.CM_RA_TY_NAME" : `Product Group Name (OTA Product Name)`,
    "cm02_0900.title.COMPLETE_MSG1" : `Your connection has been completed`,
    "cm02_0900.title.COMPLETE_MSG2" : `You can manage the rate and inventories at wings CMS.`,
    "cm02_0900.title.DATA_SEND_SUCCESS" : `Sending Succesfully`,
    "cm02_0900.title.DATE_CHECK" : `You cannot select the date in the past.`,
    "cm02_0900.title.MESSAGE_1" : `Congratulation! Channel Connection is almost completed. `,
    "cm02_0900.title.MESSAGE_2" : `Please check the input data at Extranet before completion.`,
    "cm02_0900.title.PERIOD" : `Period`,
    "cm02_0900.title.PM_RM_NAME" : `Hotel Room Type`,
    "cm02_0900.title.RATE_TEST" : `Rate Test`,
    "cm02_0900.title.RATE_TEST_TOOLTIP" : `Please be careful that guests can make reservation with 0 rate. wings CMS recommends you to input RACK rate.`,
    "cm02_0900.title.RM_RA" : `Rate`,
    "cm02_0900.title.SET_RATE" : `Manual Input`,
    "cm02_0900.title.SET_RATE_NULL" : `No Input Rate`,
    "cm02_0900.title.UNUSED" : `No Use`,
    "cm02_0900.title.USE" : `Use`,
    "cm02_0900.title.addRsvnMemoMsg" : `Contents will be added automatically at InterMemo.`,
    "cm02_0900.title.beEnterpriseConnect" : `Connection of Corporate Code`,
    "cm02_0900.title.beEnterpriseControl" : `Corporate Management`,
    "cm02_0900.title.beFaciEquip" : `Facilities & Amenity`,
    "cm02_0900.title.beHotelInfo" : `Hotel Information Setting`,
    "cm02_0900.title.bePackageGroup" : `Package Group`,
    "cm02_0900.title.bePromotionControl" : `Promotion Management`,
    "cm02_0900.title.beRatePlan" : `Rate Plan(Promotion) Setting`,
    "cm02_0900.title.beReservationControl" : `Reservation Management`,
    "cm02_0900.title.beRoomInformation" : `Room Information`,
    "cm02_0900.title.beRoomPackage" : `Room & Package Information`,
    "cm02_0900.title.beServiceOption" : `Service Option`,
    "cm02_0900.title.bsSnsConnect" : `SNS Linkage`,
    "cm02_0900.title.completeNotice" : `After channel connection, hotel should check the connection status inclduing ARI(Availability, Rate and Inventory). If not, Sanha is not responsible for any of issue that can be occurred.`,
    "cm02_0900.title.completeNoticeInterpark" : `연동완료 후 인터파크에서 CMS 연동 완료 내용 전달 및 채널 상품의 동기화요청이 필요합니다. (인터파크 담당자에게 요청)`,
    "cm02_0900.title.completeNoticeTidesquare" : `테스트단계시 입력한 정보는 타이드스퀘어 엑스트라넷 내 우측 상단에 위치한 계정정보 에서 '시설명 [SH]' 계정으로 변경 후 확인 부탁 드립니다.`,
    "cm02_0900.title.completeNoticeTripbtoz" : `연동완료 후 트립비토즈에게 CMS 연동 완료 내용 전달 및 CMS 연동작업(약 1분 소요) 요청이 필요합니다. (트립비토즈 담당자에게 요청)`,
    "cm02_1000.title.ADD_INTER_MEMO" : `Add Inter Memo`,
    "cm02_1000.title.B2B_ACCOUNT" : `B2B Account`,
    "cm02_1000.title.BTN_MANAGE" : `Set`,
    "cm02_1000.title.RCPT_PRINT" : `No Rate Print`,
    "cm02_1000.title.REQUIRED_MSG" : `Please input the Required data.`,
    "cm02_1000.title.RESERVATION_INFO" : `Setting PMS Information`,
    "cm02_1000.title.SOURCE_TYPE" : `Source Type`,
    "cm02_1100.title.CN_SORC_NAME" : `Business Name`,
    "cm02_1100.title.CORP_CUSTM_NO" : `Account code`,
    "cm02_1100.title.CORP_MANAGE" : `Channel Name Caution`,
    "cm02_3600.message.account_tip" : `거래처 코드 입력 (숫자만입력)`,
    "cm02_3600.message.success" : `저장 되었습니다.`,
    "cm02_3600.message.validation" : `상품그룹과 연동채널을 확인해 주세요.`,
    "cm02_3600.title.cancel" : `취소`,
    "cm02_3600.title.pay_channel" : `채널결제`,
    "cm02_3600.title.pay_hotel" : `현장결제`,
    "cm02_3600.title.save" : `저장`,
    "cm03_0100.button.groupSetting" : `상품그룹 부가 설정`,
    "cm03_0100.button.no" : `No`,
    "cm03_0100.button.productGroupProperty" : `Product Code Attribute`,
    "cm03_0100.button.ratePlanProperty" : `Rate plan attribute`,
    "cm03_0100.button.roomTypeProperty" : `Room Type Attribute`,
    "cm03_0100.button.yes" : `Yes`,
    "cm03_0100.message.channelCallMsg" : `Retrieve all room types and rate plans from channel. Then, set the retrieved OTA data(Room types/rate plans)`,
    "cm03_0100.message.groupSetting" : `해당 기능은 요금 플랜에 대한 추가 정보를 입력할 수 있습니다.`,
    "cm03_0100.message.productGroup" : `Users can set additional setting by the product of channels. (Rate Plan V2)`,
    "cm03_0100.message.productMsg" : `CMS will send the data to the Channels after mapping the rooms and products.`,
    "cm03_0100.message.productOrderModifyMsg" : `Check out the Product mapping status in a vertical, and users can change the order.`,
    "cm03_0100.message.qChannelInfoCall" : `Do you want to retrive the channel information?`,
    "cm03_0100.message.ratePlan" : `User can set the rate plan channel sending information. (Rate Plan V2)`,
    "cm03_0100.message.roomOrderModifyMsg" : `Check out the Room type mapping status in a vertical, and users can change the order.`,
    "cm03_0100.message.roomType" : `Users can set the additional setting by the room type of channels.`,
    "cm03_0100.message.serviceCodeRegMsg" : `You can manage the Service Code that belongs to the Products.`,
    "cm03_0100.message.serviceProductMappingMsg" : `Map channel service products and hotel service products.`,
    "cm03_0100.message.settingListMsg" : `You can check the product mapping history and grouping record.`,
    "cm03_0100.title.channelCall" : `Retrieve conneting channel information`,
    "cm03_0100.title.channelSetting" : `Channel Information Setting`,
    "cm03_0100.title.friday" : `Friday`,
    "cm03_0100.title.lastUpdateDate" : `Last Update Date :`,
    "cm03_0100.title.monday" : `Monday`,
    "cm03_0100.title.noUpdate" : `No update history`,
    "cm03_0100.title.productManage" : `Room & Product Management`,
    "cm03_0100.title.productOrderModify" : `Product Order Change`,
    "cm03_0100.title.roomOrderModify" : `Room Order Change`,
    "cm03_0100.title.saturday" : `Saturday`,
    "cm03_0100.title.serviceCodeReg" : `Service Code Register`,
    "cm03_0100.title.serviceProductMapping" : `Service Product Mapping`,
    "cm03_0100.title.settingList" : `Setting History`,
    "cm03_0100.title.sunday" : `Sunday`,
    "cm03_0100.title.thursday" : `Thursday`,
    "cm03_0100.title.tuesday" : `Tuesday`,
    "cm03_0100.title.wednesday" : `Wednesday`,
    "cm03_0101.button.call" : `Sync`,
    "cm03_0101.button.channelInfoCall" : `Channel Information Sync`,
    "cm03_0101.button.no" : `No`,
    "cm03_0101.button.yes" : `Yes`,
    "cm03_0101.message.errorCall" : `An error occurred when loading the channel data.`,
    "cm03_0101.message.qChannelInfoCall" : `Would you like to load the channel information?`,
    "cm03_0101.message.successCall" : `Load Success`,
    "cm03_0101.title.callResult" : `Result`,
    "cm03_0101.title.channelCall" : `Synchronization`,
    "cm03_0101.title.channelName" : `Channel`,
    "cm03_0101.title.lastCallTime" : `Last Sync`,
    "cm03_0101.title.noData" : `No Data`,
    "cm03_0200.button.copy" : `Copy`,
    "cm03_0200.message.checkDate" : `Check the date`,
    "cm03_0200.message.checkRoomType" : `Select the room type`,
    "cm03_0200.message.daySelect" : `Please select the days.`,
    "cm03_0200.message.inputControlCurrency" : `Enter the change amount`,
    "cm03_0200.message.inputControlRate" : `Please enter the ratio of change rate.`,
    "cm03_0200.message.inputRate" : `Please input the rate.`,
    "cm03_0200.message.modifyRate1" : `<div style='color:red'>Caution! If rate is changed, rate will be applied as below.</div><br />`,
    "cm03_0200.message.modifyRate2" : `Rate set (OCC set rate) will be released.  <br />`,
    "cm03_0200.message.modifyRate3" : `Not modified rate will be appiled to previous rate set.`,
    "cm03_0200.message.modifyRate4" : `<div style='color:red'>Caution! if rate is changed, rate will be applied as below..</div><br />`,
    "cm03_0200.message.noCopyData" : `There is no data to copy.`,
    "cm03_0200.message.noZero" : `0 cannot be entered.`,
    "cm03_0200.message.personTooltip1" : `*EX) If there is 10,000 as extra charge from 3rd guest,* <br />Base Occupancy+1=Blank <br />Base Occupancy+2=10,000`,
    "cm03_0200.message.personTooltip2" : `Don’t forget to update the rate after setting. Go to “Rate and Inventory PUSH” at “Data Synchronize”.Afterwards, check the rate at the Extranet.`,
    "cm03_0200.message.personTooltip3" : `In this case, rate will be applied from Rate Management up to 2nd guest, 10,000 will be added into original price from 3rd guest.`,
    "cm03_0200.message.rateLimit" : `Min. Rate : @(0)<br /> Max. Rate : @(1)<br /> Please check the rate.`,
    "cm03_0200.message.rateLimit2" : `Minimum Rate Set : @(0) <br /> Please check the rate you entered.`,
    "cm03_0200.message.rateLimit3" : `Maximum Rate Set : @(0) <br /> Please check the rate you entered.`,
    "cm03_0200.message.saveCopySuccess" : `Copy Success`,
    "cm03_0200.message.selectChannel" : `Select the channel`,
    "cm03_0200.message.selectChannelProduct" : `Select the channel product`,
    "cm03_0200.message.selectChannelRoom" : `Please, Select the channel room type.`,
    "cm03_0200.message.selectControlType" : `Select the change type.`,
    "cm03_0200.message.selectCutoff" : `Please select the unit to be cut.`,
    "cm03_0200.message.selectProductGroup" : `Please select the product group.`,
    "cm03_0200.message.selectRateSet" : `Please select the rate set.`,
    "cm03_0200.message.selectRoomType" : `Select the PMS room type`,
    "cm03_0200.message.selectType" : `You must select one of the rate settings and the rate adjustment for each channel.`,
    "cm03_0200.popup.addGoodsGroup" : `+ Add Product Group`,
    "cm03_0200.popup.addPersonCount" : `+ Add Extra Guest`,
    "cm03_0200.popup.all" : `All`,
    "cm03_0200.popup.basicPerson" : `Base Occupancy + @(0)`,
    "cm03_0200.popup.cancle" : `Cancel`,
    "cm03_0200.popup.channel" : `Channel`,
    "cm03_0200.popup.channelByFeeSet" : `Rate Adjust by Channel`,
    "cm03_0200.popup.channelPersonCount" : `Extra Occupancy Control`,
    "cm03_0200.popup.channelRate" : `Channel Product`,
    "cm03_0200.popup.channelRateSelect" : `Please select the channel rate`,
    "cm03_0200.popup.channelRoom" : `Channel Room Type`,
    "cm03_0200.popup.channelRoomSelect" : `Please select the channel room`,
    "cm03_0200.popup.channelSelect" : `Please select the channel`,
    "cm03_0200.popup.confirm" : `Confirm`,
    "cm03_0200.popup.controlFee" : `Set Rate`,
    "cm03_0200.popup.controlType" : `Option`,
    "cm03_0200.popup.cutoff" : `Rounddown`,
    "cm03_0200.popup.day" : `Date`,
    "cm03_0200.popup.feeSetting" : `Set Rate`,
    "cm03_0200.popup.goodsAllCopy" : `Product Group Batch Setting`,
    "cm03_0200.popup.goodsGroup" : `Product Group`,
    "cm03_0200.popup.inputControl" : `Please check the adjustment type.`,
    "cm03_0200.popup.inputCutOff" : `Please check the raw unit to be cut.`,
    "cm03_0200.popup.inputNumber" : `Please check the adjustment number.`,
    "cm03_0200.popup.nightCurrency" : `Currency : @(0)`,
    "cm03_0200.popup.nightFee" : `1 Night Charge : Currency`,
    "cm03_0200.popup.period" : `Date`,
    "cm03_0200.popup.periodLimit" : `It can be set from the start date to 1 year.`,
    "cm03_0200.popup.personCount" : `Number of guests`,
    "cm03_0200.popup.personCountSet" : `Extra Occupancy Control`,
    "cm03_0200.popup.personLimit" : `You can add up to 50 people.`,
    "cm03_0200.popup.personLimit2" : `+3인까지 추가 가능합니다.`,
    "cm03_0200.popup.personLimit3" : `Extra guest available up to @(0)`,
    "cm03_0200.popup.productSelect" : `Please select the Product Group.`,
    "cm03_0200.popup.roomAllCopy" : `Hotel Rooms Batch Setting`,
    "cm03_0200.popup.save" : `Save`,
    "cm03_0200.popup.saveSuccess" : `Saved`,
    "cm03_0200.popup.saveSuccessInfo" : `If both Product Group rate and Channel rate are set, <br />Product Group will be applied.`,
    "cm03_0200.popup.tooltipMessage1" : `To prevent the error, batch setting is available up to 1 year.`,
    "cm03_0200.popup.tooltipMessage2" : `Rate can be changed by the number of the guests. <br />  Surcharge for extra guests will be applied from OTA Extranet.`,
    "cm03_0200.popup.tooltipMessage3" : `* Set the increase / decrease rate by entering + / -.`,
    "cm03_0200.title.SaleStatus" : `Sales Status`,
    "cm03_0200.title.addOptions" : `Add Options`,
    "cm03_0200.title.addSetting" : `Add Setting`,
    "cm03_0200.title.alRms" : `Inventory`,
    "cm03_0200.title.all" : `All`,
    "cm03_0200.title.allCopy" : `All Copy`,
    "cm03_0200.title.allRoomTypeSel" : `Always all Hotel Rooms`,
    "cm03_0200.title.basicPerson" : `기준인원`,
    "cm03_0200.title.cancel" : `Cancel`,
    "cm03_0200.title.channelApply" : `Product Group + Channel`,
    "cm03_0200.title.channelRate" : `Channel Price`,
    "cm03_0200.title.channelRateApply" : `Channel Product Batch Setting`,
    "cm03_0200.title.close" : `Close`,
    "cm03_0200.title.closeCause" : `Closed Reason`,
    "cm03_0200.title.closed" : `Closed`,
    "cm03_0200.title.controlFee" : `Rate Management`,
    "cm03_0200.title.countUnit" : ` `,
    "cm03_0200.title.dayCopy" : `Day Copy`,
    "cm03_0200.title.daySelect" : `Select day`,
    "cm03_0200.title.decimalInput" : `Decimal point  Input`,
    "cm03_0200.title.detailCopy" : `Detailed Copy`,
    "cm03_0200.title.detailShow" : `Show Details`,
    "cm03_0200.title.directInput" : `Input Manually`,
    "cm03_0200.title.doRateCopy" : `Rate Copy`,
    "cm03_0200.title.exposeCondition" : `Filter`,
    "cm03_0200.title.fri" : `Fri`,
    "cm03_0200.title.goodsGroup" : `Product Group`,
    "cm03_0200.title.goodsGroupCondition" : `Product <br />Group`,
    "cm03_0200.title.hotelRoom" : `Hotel Rooms`,
    "cm03_0200.title.initialize" : `Reset`,
    "cm03_0200.title.inventoryByRate" : `Inventory Management (By Product)`,
    "cm03_0200.title.limitMessage" : `Period is displayable up to a month(31 days) from Start Date.`,
    "cm03_0200.title.limitMessage2" : `Period setting can be set maxium of 90 days as of a start day.`,
    "cm03_0200.title.mon" : `Mon`,
    "cm03_0200.title.occOverClose" : `Close over OCC(%)`,
    "cm03_0200.title.occupancy" : `OCC(%)`,
    "cm03_0200.title.onlyNumber" : `Only Numbers`,
    "cm03_0200.title.open" : `Open`,
    "cm03_0200.title.openCloseCount" : `Close n or fewer available rooms`,
    "cm03_0200.title.openOneMessage" : `When there is 1 open room`,
    "cm03_0200.title.overbookingPrevent" : `Overbooking Prevention`,
    "cm03_0200.title.periodControlMessage" : `Rate Change`,
    "cm03_0200.title.periodCopy" : `Period Copy`,
    "cm03_0200.title.periodCopyMessage" : `Period Copy`,
    "cm03_0200.title.personCount" : `Search by Occupancy`,
    "cm03_0200.title.popCopyMessage1" : `To`,
    "cm03_0200.title.popCopyMessage2" : `copy`,
    "cm03_0200.title.popCopyMessage3" : `Please set the increase or decrease of the rate as +/-.`,
    "cm03_0200.title.possibleRoom" : `Inventory`,
    "cm03_0200.title.primary" : `Required`,
    "cm03_0200.title.productGroupApply" : `Product Group Only`,
    "cm03_0200.title.rate" : `Rate`,
    "cm03_0200.title.rateCategory" : ` `,
    "cm03_0200.title.rateCopy" : `Rate Copy`,
    "cm03_0200.title.rateSet" : `Rate Set`,
    "cm03_0200.title.rateSetPop" : `Rate`,
    "cm03_0200.title.remain" : `Left`,
    "cm03_0200.title.reservation" : `Sold`,
    "cm03_0200.title.saleEnd" : `sale close`,
    "cm03_0200.title.sat" : `Sat`,
    "cm03_0200.title.save" : `Save`,
    "cm03_0200.title.search" : `Search`,
    "cm03_0200.title.searchPersonCount" : `@(0) guest(s)`,
    "cm03_0200.title.settingMethod" : `Setting`,
    "cm03_0200.title.startDate" : `Date`,
    "cm03_0200.title.sun" : `Sun`,
    "cm03_0200.title.thu" : `Thu`,
    "cm03_0200.title.today" : `Today`,
    "cm03_0200.title.tooltipMessage1" : `To prevent the error, batch setting is available up to 1 year.`,
    "cm03_0200.title.tooltipMessage2" : `No overbooking after setting the below. `,
    "cm03_0200.title.tooltipMessage4" : `Calendar displays up to 15 days from the [Date].`,
    "cm03_0200.title.tooltipMessage5" : `Select the input option below then save.`,
    "cm03_0200.title.tooltipMessage6" : `You can adjust the rate by the channel.`,
    "cm03_0200.title.totalSaleStatus" : `Availability`,
    "cm03_0200.title.tue" : `Tus`,
    "cm03_0200.title.wed" : `Wed`,
    "cm03_0200.title.weekday" : `Weekday`,
    "cm03_0200.title.weekend" : `Weekend`,
    "cm03_0200.title.weekendCopy" : `Only weekday copy`,
    "cm03_0200.title.weekendOnlyCopy" : `Only Weekend copy`,
    "cm03_0200.title.whenFeeCopy" : `Rate Copy`,
    "cm03_0200.title.allOpenBtn" : `All Open`,
    "cm03_0200.title.allCloseBtn" : `All Close`,
    "cm03_0300.message.emptyContent1" : `If Blank is ticked, available rooms will be applied.`,
    "cm03_0300.message.emptyContent2" : `If Blank is ticked, Availability can be changed upon the inventory.`,
    "cm03_0300.message.emptyContent3" : `If Blank is ticked, smaller value will be applied between Room Group and Hotel Rooms.`,
    "cm03_0300.popup.addMessage1" : `Select the room type to sell when the number of remaining rooms is one.`,
    "cm03_0300.popup.addMessage2" : `Enter the number of rooms for closing according to the number of remaining rooms.`,
    "cm03_0300.popup.addMessage3" : `Enter the number of OCC(%) for closing according to the OCC(%).`,
    "cm03_0300.popup.addSetting" : `Additional Settings`,
    "cm03_0300.popup.alRmsEmpty" : `Blank`,
    "cm03_0300.popup.beforeSaleClose" : `Cut-Offs`,
    "cm03_0300.popup.channel" : `Channel`,
    "cm03_0300.popup.channelAllCopy" : `Channel Batch Setting`,
    "cm03_0300.popup.channelRoom" : `Channel Room Type`,
    "cm03_0300.popup.chechInClose" : `Closed to Arrival`,
    "cm03_0300.popup.checkOutClose" : `Closed to Departure`,
    "cm03_0300.popup.close" : `Closed`,
    "cm03_0300.popup.daySelect" : `Select day`,
    "cm03_0300.popup.freeSale" : `Free Sale`,
    "cm03_0300.popup.fri" : `Fri`,
    "cm03_0300.popup.goodsGroup" : `Product Group`,
    "cm03_0300.popup.hotelRoom" : `Hotel Rooms`,
    "cm03_0300.popup.maxCountiueDay" : `Max. Consecutive Stay`,
    "cm03_0300.popup.maxStayDay" : `Max.Los`,
    "cm03_0300.popup.message1" : `To prevent the error, batch setting is available up to 1 year.`,
    "cm03_0300.popup.minCountiueDay" : `Min. Consecutive Stay`,
    "cm03_0300.popup.minStayDay" : `Min.Los`,
    "cm03_0300.popup.mon" : `Mon`,
    "cm03_0300.popup.onlyNumber" : `Only Numbers`,
    "cm03_0300.popup.open" : `Open`,
    "cm03_0300.popup.overBooking" : `Overbooking`,
    "cm03_0300.popup.period" : `Date`,
    "cm03_0300.popup.restrictionSet" : `Restriction Setting`,
    "cm03_0300.popup.roomAllSetting" : `Hotel Room batch setting`,
    "cm03_0300.popup.room_group" : `Room Group`,
    "cm03_0300.popup.saleClose" : `Sales Status`,
    "cm03_0300.popup.sat" : `Sat`,
    "cm03_0300.popup.save" : `Save`,
    "cm03_0300.popup.selectChannel" : `Please, select the channel.`,
    "cm03_0300.popup.selectChannelRoom" : `Please select the channel room.`,
    "cm03_0300.popup.selectHotelRoom" : `Please select the hotel room.`,
    "cm03_0300.popup.selectRoomType" : `Please select the room group.`,
    "cm03_0300.popup.selectWeek" : `Please select the day of the week.`,
    "cm03_0300.popup.settingByRoom" : `Room Group Batch Setting`,
    "cm03_0300.popup.sun" : `Sun`,
    "cm03_0300.popup.thu" : `Thu`,
    "cm03_0300.popup.tue" : `Tue`,
    "cm03_0300.popup.unit" : ` `,
    "cm03_0300.popup.unitDay" : `Day`,
    "cm03_0300.popup.useRoomCount" : `Inventory`,
    "cm03_0300.popup.wed" : `Wed`,
    "cm03_0300.title." : `All`,
    "cm03_0300.title.addOptions" : `Add Options`,
    "cm03_0300.title.addSetting" : `Add Setting`,
    "cm03_0300.title.adjRmCnt" : `Overbooking`,
    "cm03_0300.title.alRms" : `Inventory`,
    "cm03_0300.title.allCopy" : `All Copy`,
    "cm03_0300.title.autoCloseRms" : `# of room to be closed`,
    "cm03_0300.title.autoClosed" : `Auto`,
    "cm03_0300.title.calcCloseYn" : `Availability`,
    "cm03_0300.title.channel" : `Channel`,
    "cm03_0300.title.channelFee" : `Channel Rate`,
    "cm03_0300.title.channelRoom" : `Channel Room`,
    "cm03_0300.title.close" : `Cancel`,
    "cm03_0300.title.closeCause" : `Closed Reason`,
    "cm03_0300.title.closeOccupancy" : `Auto OCC Closure`,
    "cm03_0300.title.closeYN" : `Sales Status`,
    "cm03_0300.title.closed" : `Closed`,
    "cm03_0300.title.condition" : `Filter`,
    "cm03_0300.title.countUnit" : `unit`,
    "cm03_0300.title.cutOffDays" : `Cut-Offs`,
    "cm03_0300.title.date" : `Date`,
    "cm03_0300.title.dayCopy" : `Day Copy`,
    "cm03_0300.title.daySelect" : `Select day`,
    "cm03_0300.title.decimalInput" : `Decimal point  Input`,
    "cm03_0300.title.detailCopy" : `Detailed Copy`,
    "cm03_0300.title.detailShow" : `Show Details`,
    "cm03_0300.title.feeSetByChannel" : `Adjust Fee by Channel`,
    "cm03_0300.title.fri" : `Fri`,
    "cm03_0300.title.hotelRoom" : `Hotel Rooms`,
    "cm03_0300.title.intialize" : `Initialization`,
    "cm03_0300.title.inventory" : `Inventory`,
    "cm03_0300.title.inventoryControl" : `Inventory Management`,
    "cm03_0300.title.maxLos" : `Max.Los`,
    "cm03_0300.title.maxStayThrou" : `Max. Consecutive Stay`,
    "cm03_0300.title.minLos" : `Min.Los`,
    "cm03_0300.title.minStayThrou" : `Min. Consecutive Stay`,
    "cm03_0300.title.mon" : `Mon`,
    "cm03_0300.title.noArrYn" : `Closed to Arrival`,
    "cm03_0300.title.noDepYn" : `Closed to Departure`,
    "cm03_0300.title.occOverClose" : `Close over OCC(%)`,
    "cm03_0300.title.occupancy" : `OCC(%)`,
    "cm03_0300.title.onlyNumber" : `Only Numbers`,
    "cm03_0300.title.open" : `Open`,
    "cm03_0300.title.openCloseCount" : `Close n or fewer available rooms`,
    "cm03_0300.title.openOneMessage" : `When there is 1 open room`,
    "cm03_0300.title.overbookingPrevent" : `Overbooking Prevention`,
    "cm03_0300.title.period" : `Date`,
    "cm03_0300.title.periodCopy" : `Period Copy`,
    "cm03_0300.title.periodCopyMessage" : `Period Copy`,
    "cm03_0300.title.primary" : `Required`,
    "cm03_0300.title.remaning" : `Left`,
    "cm03_0300.title.restriction" : `Restriction`,
    "cm03_0300.title.roomGroup" : `Room Group`,
    "cm03_0300.title.rsvnRmCnt" : `Sold`,
    "cm03_0300.title.saleClosed" : `Closed`,
    "cm03_0300.title.saleOpen" : `Open`,
    "cm03_0300.title.sat" : `Sat`,
    "cm03_0300.title.save" : `Save`,
    "cm03_0300.title.search" : `Search`,
    "cm03_0300.title.sendRestrictRms" : `# of left room to be shown`,
    "cm03_0300.title.showInfo" : `Filter`,
    "cm03_0300.title.sun" : `Sun`,
    "cm03_0300.title.thu" : `Thu`,
    "cm03_0300.title.tooltipCloseCause" : `Availability | OCC<br/>Cut-Offs | Inventory`,
    "cm03_0300.title.tooltipCloseCause1" : `Closed`,
    "cm03_0300.title.tooltipCloseCause2" : `OCC @(0)(%) Close Out`,
    "cm03_0300.title.tooltipCloseCause3" : `@(0)Close Out`,
    "cm03_0300.title.tooltipCloseCause4" : `Room Available : @(0)`,
    "cm03_0300.title.tooltipMessage1" : `Period is displayable up to a month(31 days) from Start Date.`,
    "cm03_0300.title.tooltipMessage2" : `To prevent the error, batch setting is available up to 1 year.`,
    "cm03_0300.title.tooltipMessage3" : `* Set the increase / decrease rate <br>by entering + /-.`,
    "cm03_0300.title.tooltipMessage4" : `To prevent the error, batch setting is available up to 1 year.`,
    "cm03_0300.title.tooltipMessage5" : `No overbooking after setting the below. `,
    "cm03_0300.title.tooltipMessage6" : `Calendar displays up to 15 days from the [Date].`,
    "cm03_0300.title.tue" : `Tus`,
    "cm03_0300.title.userClosed" : `User`,
    "cm03_0300.title.wed" : `Wed`,
    "cm03_0300.title.weekday" : `Weekday`,
    "cm03_0300.title.weekend" : `Weekend`,
    "cm03_0300.title.weekendCopy" : `Only weekday copy`,
    "cm03_0300.title.weekendOnlyCopy" : `Only Weekend copy`,
    "cm03_0300.title.allOpenBtn" : `All Open`,
    "cm03_0300.title.allCloseBtn" : `All Close`,
    "cm03_0400.button.cancel" : `Cancel`,
    "cm03_0400.button.complete" : `Complete`,
    "cm03_0400.button.prev" : `Previous`,
    "cm03_0400.button.save" : `Save`,
    "cm03_0400.button.sortMove" : `Order Shift`,
    "cm03_0400.message.noMapping" : `There are no mapping room products. <br /> Please do mapping first.`,
    "cm03_0400.message.noMappingInfo" : `No mapping information between Product group and channel products.<br />`,
    "cm03_0400.message.success" : `Saved.`,
    "cm03_0400.message.success1" : `Please update the ARI after room or product mapping.`,
    "cm03_0400.message.success2" : `Make sure that channel rate at Extranet and rate at CMS are equal.`,
    "cm03_0400.message.success3" : `If rate is entered at Channel product and Product group both, Product group rate will be applied.`,
    "cm03_0400.message.success4" : `Go to ‘Rate and Inventory Push’ after mapping update.`,
    "cm03_0400.message.success5" : `(Please confirm the ARI status at the Extranet!)`,
    "cm03_0400.title.notUseSearch" : `Include Inactive Product Group`,
    "cm03_0400.title.productManage" : `Product Management`,
    "cm03_0400.title.resendMove" : `Go to Rate and Inventory PUSH`,
    "cm03_0400.title.searchPromotion" : `Promotion Product Search`,
    "cm03_0401.button.add" : `Add`,
    "cm03_0401.button.cancel" : `Cancel`,
    "cm03_0401.button.save" : `Save`,
    "cm03_0401.message.channelServiceDupl" : `Duplicate service exists`,
    "cm03_0401.message.createServiceCode" : `+ Creat New Service Code`,
    "cm03_0401.message.inputChannelRoom" : `Please confirm the Channel Room Name.`,
    "cm03_0401.message.inputServiceCode" : `Please confirm the Service Code.`,
    "cm03_0401.message.inputServiceType" : `Please check the service type data.`,
    "cm03_0401.message.noModifyData" : `No data has been changed.`,
    "cm03_0401.message.noServiceCode" : `No Service Code.`,
    "cm03_0401.message.noServiceCode1" : `No registered Service Code.`,
    "cm03_0401.message.noServiceCode2" : `You can manage the Service code by clicking the [Add].`,
    "cm03_0401.message.selectChannel" : `Select the channel.`,
    "cm03_0401.message.selectRoomType" : `Please select the room type`,
    "cm03_0401.message.success" : `Saved.`,
    "cm03_0401.title.allApply" : `Batch Apply`,
    "cm03_0401.title.channel" : `Channel`,
    "cm03_0401.title.channelApply" : `Channel Apply`,
    "cm03_0401.title.channelRoomName" : `Channel Room Name`,
    "cm03_0401.title.delete" : `Delete`,
    "cm03_0401.title.manage" : `Special Service`,
    "cm03_0401.title.modify" : `Modify`,
    "cm03_0401.title.notUsed" : `Unused`,
    "cm03_0401.title.quantity" : `Unit Quantity`,
    "cm03_0401.title.roomType" : `Room Type`,
    "cm03_0401.title.serviceCode" : `Service Code`,
    "cm03_0401.title.serviceManage" : `Special Service`,
    "cm03_0401.title.serviceName" : `Service Name`,
    "cm03_0401.title.serviceRate" : `Service Charge\n(1 Unit)`,
    "cm03_0401.title.serviceType" : `Service Type`,
    "cm03_0401.title.state" : `Status`,
    "cm03_0401.title.use" : `Use`,
    "cm03_0410.button.cancel" : `Cancel`,
    "cm03_0410.button.save" : `Save`,
    "cm03_0410.message.inputGroupName" : `Enter the Product Group name.`,
    "cm03_0410.message.inputRoomType" : `Select the PMS rate type`,
    "cm03_0410.message.success" : `Saved.`,
    "cm03_0410.title.active" : `Active`,
    "cm03_0410.title.inactive" : `Inactive`,
    "cm03_0410.title.newRoomType" : `Product Group`,
    "cm03_0410.title.package" : `PMS Package`,
    "cm03_0410.title.promotionProductType" : `Promotion Product Group`,
    "cm03_0410.title.roomGroupName" : `Product Group Name`,
    "cm03_0410.title.roomType" : `PMS rate type`,
    "cm03_0410.title.useYn" : `USE Y/N`,
    "cm03_0500.button.add" : `Add`,
    "cm03_0500.button.cancel" : `Cancel`,
    "cm03_0500.button.groupMapping" : `Group code Mapping`,
    "cm03_0500.button.prev" : `Previous`,
    "cm03_0500.button.productRoomMap" : `Product Room Mapping`,
    "cm03_0500.button.remove" : `제거`,
    "cm03_0500.button.save" : `Save`,
    "cm03_0500.message.cmsTooltip" : `If product is not controlled by CMS, rate/inventory must be controlled by the channel’s extranet.`,
    "cm03_0500.message.compareDate" : `상품판매 시작일이 마감일보다 이후 날짜입니다.`,
    "cm03_0500.message.duplicate" : `Room code is duplicated.`,
    "cm03_0500.message.inputApiCode" : `Please enter the channel API rate code.`,
    "cm03_0500.message.inputChannelProductCode" : `Please input the product code from channel.`,
    "cm03_0500.message.inputChannelProductName" : `Please input the product name from channel.`,
    "cm03_0500.message.inputProductCode" : `Please enter the room code`,
    "cm03_0500.message.inputProductName" : `Please enter the room type`,
    "cm03_0500.message.noModifyData" : `No data has been changed.`,
    "cm03_0500.message.notDataProductCode" : `Please Enter the Product Code.`,
    "cm03_0500.message.notDataProductName" : `Please Enter the Product Name.`,
    "cm03_0500.message.removeDupl" : `저장하려는 상품코드 중에 삭제하려는 상품코드가 존재합니다.`,
    "cm03_0500.message.success" : `Saved.`,
    "cm03_0500.title.apiRateCode" : `Channel API Rate Code`,
    "cm03_0500.title.calledRoomType" : `Retrieved Products`,
    "cm03_0500.title.channelProduct" : `Product management by channels`,
    "cm03_0500.title.cmsManage" : `CMS Control`,
    "cm03_0500.title.endDate" : `마감일`,
    "cm03_0500.title.notCalledRoomType" : `Product Creation for Unretrieved Product`,
    "cm03_0500.title.productCode" : `Product Code`,
    "cm03_0500.title.productName" : `Product Name`,
    "cm03_0500.title.startDate" : `시작일`,
    "cm03_0501.button.save" : `Save`,
    "cm03_0501.message.inputRateCode" : `Please input channel Rate Code`,
    "cm03_0501.message.noModify" : `No data has been changed.`,
    "cm03_0501.message.saveSuccess" : `Saved.`,
    "cm03_0501.title.productRoomMap" : `Product Room Mapping`,
    "cm03_0501.title.rateCode" : `Channel rate code`,
    "cm03_0501.title.roomCode" : `Room Code`,
    "cm03_0501.title.roomName" : `Room Name`,
    "cm03_0600.button.addOcc" : `OCC Add`,
    "cm03_0600.button.apply" : `Apply`,
    "cm03_0600.button.cancel" : `Cancel`,
    "cm03_0600.button.copy" : `Copy`,
    "cm03_0600.button.manage" : `Management`,
    "cm03_0600.button.occNotUsed" : `OCC No Use`,
    "cm03_0600.button.occUse" : `OCC Use`,
    "cm03_0600.button.prev" : `Previous`,
    "cm03_0600.button.save" : `Save`,
    "cm03_0600.message.MaxRateLimit" : `Maximum Rate Set : @(0) <br /> Please check the rate you entered.`,
    "cm03_0600.message.RateLimit" : `Minimum Rate Set : @(0) <br /> Maximum Rate Set : @(1) <br /> Please check the rate you entered.`,
    "cm03_0600.message.copyContent" : `Copy to @(0).`,
    "cm03_0600.message.inputControl" : `Please check the adjustment input value.`,
    "cm03_0600.message.maxRateCheck" : `Min Rate cannot be over Max Rate.`,
    "cm03_0600.message.minRateLimit" : `Minimum Rate Set : @(0) <br /> Please check the rate you entered.`,
    "cm03_0600.message.noGroupData" : `There is no Product group information.`,
    "cm03_0600.message.occAdd" : `You can add the range up to 5.`,
    "cm03_0600.message.occContent" : `For Auto Rate Set change by OCC, Rate Set will be changed depending on the OCC at PMS.`,
    "cm03_0600.message.popupMessage" : `to`,
    "cm03_0600.message.productCopy" : `Copy the rate of previously created product group`,
    "cm03_0600.message.productMap1" : ` `,
    "cm03_0600.message.productMap2" : `This is the product code currently grouped. On the right side of the product code`,
    "cm03_0600.message.productMapping1" : `Full list of Product(s) from the channels at the Product Group above. For ungroup, please click`,
    "cm03_0600.message.productMapping2" : ` `,
    "cm03_0600.message.qGroupTerminate" : `Are you sure you want to remove @(0) from the group?`,
    "cm03_0600.message.rateContent" : `* Set the increase / decrease rate by entering + / -.`,
    "cm03_0600.message.rateSetContent" : `Users can create the Rate Set by copying it from created Rate Set of different Product Group.`,
    "cm03_0600.message.rateSetting" : `If the number is out of Minimum or Maximum rate range after setting Minimum / Maximum rate, error can be occurred.`,
    "cm03_0600.message.selControlType" : `Please select the adjustment type.`,
    "cm03_0600.message.selCutoff" : `Select truncated value`,
    "cm03_0600.message.selProductColumn" : `Select a column group to copy.`,
    "cm03_0600.message.selProductGroup" : `Please select the product group to copy.`,
    "cm03_0600.message.serviceCode1" : `If you want to journalize the service from reservations at channel, please tick [Service Included]. Then, register the service code at [Control].`,
    "cm03_0600.message.serviceCode2" : `After setting, Breakfast product will journalize the room rate and breakfast rate.`,
    "cm03_0600.message.success" : `Saved.`,
    "cm03_0600.messeage.serviceCode1Modi" : `Please click the “Setting” to breakdown the Service Code at the reservation.`,
    "cm03_0600.messeage.serviceCode2Modi" : `If reservation is created with the product from the product group, CMS will breakdown the rate into room and service code.`,
    "cm03_0600.title.OccCopy" : `OCC(%) Copy`,
    "cm03_0600.title.add" : `Add`,
    "cm03_0600.title.addOption" : `Add Options`,
    "cm03_0600.title.controlType" : `Option`,
    "cm03_0600.title.copy" : `Copy to`,
    "cm03_0600.title.createdGroup" : `Created Product group name`,
    "cm03_0600.title.cutoff" : `Rounddown`,
    "cm03_0600.title.doCopy" : `Copy`,
    "cm03_0600.title.input" : `Only Numbers`,
    "cm03_0600.title.inputPossibleNumber" : `It can be set up to 5 letter or digits.`,
    "cm03_0600.title.maxRate" : `Maximum Rate`,
    "cm03_0600.title.memoDescription" : `Remark`,
    "cm03_0600.title.memoRateName" : `Rate Set Name`,
    "cm03_0600.title.minRate" : `Minimum Rate`,
    "cm03_0600.title.no" : `No`,
    "cm03_0600.title.occCheckbox" : `Auto Rate Set Change by OCC`,
    "cm03_0600.title.pageTitle" : `Create Rate Set`,
    "cm03_0600.title.pmsPackageNo" : `PMS Package`,
    "cm03_0600.title.pmsRateType" : `PMS Rate Type`,
    "cm03_0600.title.prdGroupName" : `Product Group`,
    "cm03_0600.title.productCopy" : `Product Group Copy`,
    "cm03_0600.title.productGroupOff" : `Display product group`,
    "cm03_0600.title.productGroupOn" : `Fold product group`,
    "cm03_0600.title.productManage" : `Product Group Management`,
    "cm03_0600.title.rateCopy" : `Rate Copy`,
    "cm03_0600.title.rateSet" : `Rate set`,
    "cm03_0600.title.rateSetCopy" : `Rate Set Copy`,
    "cm03_0600.title.second" : `Over`,
    "cm03_0600.title.serviceCodeSetting" : `Service Code Set`,
    "cm03_0600.title.standardRate" : `Standard Rate`,
    "cm03_0600.title.yes" : `Yes`,
    "cm03_0601.button.add" : `Add`,
    "cm03_0601.button.cancel" : `Cancel`,
    "cm03_0601.button.save" : `Save`,
    "cm03_0601.message.channelServiceDupl" : `Duplicate service exists`,
    "cm03_0601.message.createServiceCode" : `+ Creat New Service Code`,
    "cm03_0601.message.inputChannelRoom" : `Please confirm the Channel Room Name.`,
    "cm03_0601.message.inputServiceCode" : `Please confirm the Service Code.`,
    "cm03_0601.message.inputServiceType" : `Please check the service type data.`,
    "cm03_0601.message.noModifyData" : `No data has been changed.`,
    "cm03_0601.message.noServiceCode" : `No Service Code.`,
    "cm03_0601.message.noServiceCode1" : `No registered Service Code.`,
    "cm03_0601.message.noServiceCode2" : `You can manage the Service code by clicking the [Add].`,
    "cm03_0601.message.selectChannel" : `Select the channel.`,
    "cm03_0601.message.selectRoomType" : `Please select the room type`,
    "cm03_0601.message.success" : `Saved.`,
    "cm03_0601.title.allApply" : `Batch Apply`,
    "cm03_0601.title.channel" : `Channel`,
    "cm03_0601.title.channelApply" : `Channel Apply`,
    "cm03_0601.title.channelRoomName" : `Channel Room Name`,
    "cm03_0601.title.delete" : `Delete`,
    "cm03_0601.title.manage" : `Setting`,
    "cm03_0601.title.modify" : `Modify`,
    "cm03_0601.title.notUsed" : `Unused`,
    "cm03_0601.title.quantity" : `Unit Quantity`,
    "cm03_0601.title.roomType" : `Room Type`,
    "cm03_0601.title.serviceCode" : `Service Code`,
    "cm03_0601.title.serviceManage" : `Service Management`,
    "cm03_0601.title.serviceName" : `Service Name`,
    "cm03_0601.title.serviceRate" : `Service Charge\n(1 Unit)`,
    "cm03_0601.title.serviceType" : `Service Type`,
    "cm03_0601.title.state" : `Status`,
    "cm03_0601.title.use" : `Use`,
    "cm03_0800.button.cancel" : `Cancel`,
    "cm03_0800.button.manage" : `Set`,
    "cm03_0800.button.save" : `Save`,
    "cm03_0800.message.noModify" : `No data has been changed.`,
    "cm03_0800.message.productGroupSort" : `Product Group including channel product order can be changed by Dragging.`,
    "cm03_0800.message.success" : `Saved.`,
    "cm03_0800.title.productGroupSort" : `Product Group Order`,
    "cm03_0900.button.cancel" : `Cancel`,
    "cm03_0900.button.next" : `Next`,
    "cm03_0900.button.prev" : `Previous`,
    "cm03_0900.button.save" : `Save`,
    "cm03_0900.button.sortMove" : `Order Shift`,
    "cm03_0900.message.noMapping" : `There are no mapping room products. <br /> Please do mapping first.`,
    "cm03_0900.message.noMappingInfo" : `No mapping info between room group and channel room products. <br />`,
    "cm03_0900.message.success" : `Saved.`,
    "cm03_0900.title.notUseSearch" : `Include Inactive Room Group`,
    "cm03_0900.title.roomManage" : `Room Management`,
    "cm03_0910.button.callRoomType" : `Load PMS Room Information`,
    "cm03_0910.button.cancel" : `Cancel`,
    "cm03_0910.button.no" : `No`,
    "cm03_0910.button.save" : `Save`,
    "cm03_0910.button.yes" : `Yes`,
    "cm03_0910.message.inputGroupName" : `Please enter the Room Group Name`,
    "cm03_0910.message.inputRoomType" : `Please select the hotel room type.`,
    "cm03_0910.message.pmsCall" : `Would you like to load the PMS room information?`,
    "cm03_0910.message.pmsCallSuccess" : `PMS room information is successfully loaded.`,
    "cm03_0910.message.success" : `Saved.`,
    "cm03_0910.title.active" : `Active`,
    "cm03_0910.title.inactive" : `Inactive`,
    "cm03_0910.title.newRoomType" : `New Room Type`,
    "cm03_0910.title.roomGroupName" : `Room Group Name`,
    "cm03_0910.title.roomType" : `Hotel Room Type`,
    "cm03_0910.title.useYn" : `USE Y/N`,
    "cm03_1000.button.add" : `Add`,
    "cm03_1000.button.cancel" : `Cancel`,
    "cm03_1000.button.remove" : `제거`,
    "cm03_1000.button.save" : `Save`,
    "cm03_1000.message.cmsTooltip" : `If room is not controlled by CMS, rate / inventory must be controlled by the channel’s extranet.`,
    "cm03_1000.message.duplicate" : `Room code is duplicated.`,
    "cm03_1000.message.inputChannelRoomCode" : `Please input the room code from channel.`,
    "cm03_1000.message.inputChannelRoomName" : `Please input the room type name from channel.`,
    "cm03_1000.message.inputRoomCode" : `Please enter the room code`,
    "cm03_1000.message.inputRoomName" : `Please enter the room type`,
    "cm03_1000.message.noModifyData" : `No data has been changed.`,
    "cm03_1000.message.removeDupl" : `저장하려는 객실코드 중에 삭제하려는 객실코드가 존재합니다.`,
    "cm03_1000.message.success" : `Saved.`,
    "cm03_1000.title.calledRoomType" : `Retrieved Room Type`,
    "cm03_1000.title.cmsManageYn" : `CMS Control`,
    "cm03_1000.title.notCalledRoomType" : `Create the un-retrieved room type`,
    "cm03_1000.title.roomCode" : `Room Code`,
    "cm03_1000.title.roomType" : `Room Type`,
    "cm03_1000.title.roomTypeManage" : `Room Type management by channels`,
    "cm03_1100.button.cancel" : `Cancel`,
    "cm03_1100.button.prev" : `Previous`,
    "cm03_1100.button.save" : `Save`,
    "cm03_1100.message.groupingContent" : `Full list of Room Product(s) from the channels at the Room Group above. For ungroup, please click`,
    "cm03_1100.message.groupingContent2" : ` `,
    "cm03_1100.message.noGroupData" : `Room Group data retrieval is failed. `,
    "cm03_1100.message.noModifyMapping" : `This channel cannot be disconnected.`,
    "cm03_1100.message.qGroupTerminate" : `Are you sure you want to remove @(0) from the group?`,
    "cm03_1100.message.rateCompare" : `Max Rate cannot be below than Min Rate. `,
    "cm03_1100.message.rateContent" : `If you specify the minimum / maximum rate when sending a rate to a channel, a transmission error occurs when you enter a rate below / above the set rate.`,
    "cm03_1100.message.rateNoticeComment1" : `Make sure that rate is within the Min /  Max rate range at Rate Management after setting the Minimum/Maximum rate range.`,
    "cm03_1100.message.rateNoticeComment2" : `If the rate is out of Minimum or Maximum rate range after setting Minimum / Maximum rate,  room can be unavailable due to error.`,
    "cm03_1100.message.success" : `Saved.`,
    "cm03_1100.title.inputNumber" : `Only Numbers`,
    "cm03_1100.title.maxRate" : `Maximum Rate`,
    "cm03_1100.title.minRate" : `Minimum Rate`,
    "cm03_1100.title.no" : `No`,
    "cm03_1100.title.pmsRoomType" : `PMS Room Type`,
    "cm03_1100.title.roomGroup" : `Room Group Management`,
    "cm03_1100.title.yes" : `Yes`,
    "cm03_1200.button.cancel" : `Cancel`,
    "cm03_1200.button.manage" : `Set`,
    "cm03_1200.button.save" : `Save`,
    "cm03_1200.message.noModify" : `No data has been changed.`,
    "cm03_1200.message.roomGroupSort" : `Room Group including room product order can be changed by Dragging.`,
    "cm03_1200.message.success" : `Saved.`,
    "cm03_1200.title.roomGroupSort" : `Room Group Order`,
    "cm03_2100.button.add" : `Append`,
    "cm03_2100.button.cancel" : `Cancel`,
    "cm03_2100.button.excel" : `Excel`,
    "cm03_2100.button.importServiceCode" : `PMS Service Code Retrieval`,
    "cm03_2100.button.init" : `Initialization`,
    "cm03_2100.button.no" : `No`,
    "cm03_2100.button.remove" : `Delete`,
    "cm03_2100.button.save" : `Save`,
    "cm03_2100.button.search" : `Search`,
    "cm03_2100.button.serviceCodeCall" : `Load PMS Service Code`,
    "cm03_2100.button.yes" : `Yes`,
    "cm03_2100.etc.active" : `Active`,
    "cm03_2100.etc.all" : `All`,
    "cm03_2100.etc.inactive" : `Inactive`,
    "cm03_2100.message.apiServiceCode" : `Would you like to load the service code via API?`,
    "cm03_2100.message.checkPmsDb" : `Check the hotel's PMS DB connection.`,
    "cm03_2100.message.checkServiceName" : `서비스명은 특수문자만 입력할 수 없습니다.`,
    "cm03_2100.message.duplicate" : `Duplicated Service Code. \n Please check again.`,
    "cm03_2100.message.importApi" : `Do you retrive the Service Code with API?`,
    "cm03_2100.message.init" : `Data has changed. <br /> Are you sure you want to initialize?`,
    "cm03_2100.message.inputAmount" : `Please input the amount.`,
    "cm03_2100.message.inputBaseQty" : `Please input the unit quantity.`,
    "cm03_2100.message.inputServiceCode" : `Please enter the Service Code.`,
    "cm03_2100.message.inputServiceName" : `Please enter the Service Name.`,
    "cm03_2100.message.modifyData" : `There is changed data. Please save it first.`,
    "cm03_2100.message.noModifyData" : `No data has been changed.`,
    "cm03_2100.message.pmsCallSuccess" : `PMS service code was successfully loaded.`,
    "cm03_2100.message.success" : `Saved.`,
    "cm03_2100.title.activeYn" : `Status`,
    "cm03_2100.title.addService" : `Service Add`,
    "cm03_2100.title.alarm" : `Notification`,
    "cm03_2100.title.baseQty" : `Unit Quantity`,
    "cm03_2100.title.modify" : `Modify`,
    "cm03_2100.title.pmName" : `Property`,
    "cm03_2100.title.pmSvcAmt" : `Amount`,
    "cm03_2100.title.pmSvcCode" : `Service Code`,
    "cm03_2100.title.sortOrder" : `Sort Order`,
    "cm03_2100.title.svcCodeReg" : `Service Code Register`,
    "cm03_2100.title.svcName" : `Service Name`,
    "cm03_2200.message.inputRevNo" : `Please enter the channel reservation number.`,
    "cm03_2200.message.selectChannel" : `Please select the channel.`,
    "cm03_2200.message.selectProperty" : `Please select the property.`,
    "cm03_2200.message.successCall" : `Reservation number: @(0) has been successfully received.`,
    "cm03_2200.message.successCallRsvn" : `#@(0) is retrieved successfully. \nPlease check the reservation at Reservation List.`,
    "cm03_2200.title.async" : `No Use`,
    "cm03_2200.title.channelName" : `Channel Name`,
    "cm03_2200.title.notUsed" : `No Use`,
    "cm03_2200.title.notice" : `Reservation only can be retrieved for supporting channels.`,
    "cm03_2200.title.processResult" : `Process result`,
    "cm03_2200.title.processResultDetail" : `Detail Result`,
    "cm03_2200.title.property" : `Property Name`,
    "cm03_2200.title.qAutoInput" : `Do you want to auto-enter the reservation details from re-sending channels into PMS?`,
    "cm03_2200.title.resendRequest" : `Retrieve`,
    "cm03_2200.title.revNumber" : `Channel Reservation Number`,
    "cm03_2200.title.revResend" : `Channel RSVN Retrieval`,
    "cm03_2200.title.use" : `Use`,
    "cm03_2300.button.inventoryResend" : `Rate and Inventory PUSH`,
    "cm03_2300.button.reSend" : `Resend`,
    "cm03_2300.message.inactiveBatch" : `선택한 채널은 전송이 불가능 합니다. 자세한 내용은 산하 C/S팀(1544-4172)으로 연락바랍니다.`,
    "cm03_2300.message.inputPeriod" : `Please enter the sales period.`,
    "cm03_2300.message.interpark" : `인터파크는 데이터 전송이 불가능합니다. 채널(인터파크)로 연락바랍니다.`,
    "cm03_2300.message.maxResend" : `Rate and Inventory PUSH is available up to 90 days.`,
    "cm03_2300.message.maxResendContent" : `User can set the period up to 90 days, System will push ARI data of all room types.`,
    "cm03_2300.message.noMapping" : `매핑된 상품, 객실이 없습니다.`,
    "cm03_2300.message.selectChannel" : `Please select the channel.`,
    "cm03_2300.message.selectProperty" : `Please select the property.`,
    "cm03_2300.message.selectRoomType" : `Please select the channel room.`,
    "cm03_2300.message.successApiCall" : `The rate / inventory data of @(0) was successfully transferred to @(1).`,
    "cm03_2300.message.successCall" : `The Hotel's rate / inventory information has been successfully transferred to @(0).`,
    "cm03_2300.title.channelName" : `Channel Name`,
    "cm03_2300.title.inventoryResend" : `Rate and Inventory PUSH`,
    "cm03_2300.title.processDetail" : `Detail Result`,
    "cm03_2300.title.processResult" : `Process result`,
    "cm03_2300.title.property" : `Property Name`,
    "cm03_2300.title.roomName" : `Channel Room Name`,
    "cm03_2300.title.salePeriod" : `Sales Period`,
    "cm03_2400.message.inventorySend" : `User can push the ARI(Availability, Rate and Inventories) to the channel directly.`,
    "cm03_2400.message.notice" : `1. Channel Reservation Retrieval<br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- User can retrieve the reservations during retrieval available period (ex, 30min~24H after reservation is created). If it is expired, users enter the reservation details manually.<br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- Please be aware that reservation details will be overwritten after retrieval. If user has changed the reservation details manually at PMS, those change will not be remained after retrieval.<br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- If the PMS code (market type, source type, rate type, etc.) is set incorrectly, creating the PMS reservation will fail. <br/>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- If the channel service code mapping is not done, the PMS reservation transmission will fail. <br/><br/>`,
    "cm03_2400.message.notice1" : ``,
    "cm03_2400.message.notice2" : `2. Rate and Inventory PUSH<br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- CMS will update ARI(Availability, Rate and Inventory) to the channels. If user wants to apply it for longterm, it takes long by the channels. <br/><br/>`,
    "cm03_2400.message.notice3" : `For more Inquiries, please contact Sanha IT Customer Service(help@sanhait.co.kr, globalhelp@sanhait.co.kr)`,
    "cm03_2400.message.notice4" : ``,
    "cm03_2400.message.notice5" : ``,
    "cm03_2400.message.notice6" : ``,
    "cm03_2400.message.reservationSend" : `It is a function to retrieve the reservations manually from channels in case of missing reservations.`,
    "cm03_2400.title.async" : `Data Synchronize`,
    "cm03_2400.title.notice" : `Caution`,
    "cm03_2500.btn.all" : `All`,
    "cm03_2500.btn.baseFee" : `Set Rate`,
    "cm03_2500.btn.cancel" : `Cancel`,
    "cm03_2500.btn.close" : `닫기`,
    "cm03_2500.btn.days" : `Days`,
    "cm03_2500.btn.delete" : `Delete`,
    "cm03_2500.btn.period" : `Period`,
    "cm03_2500.btn.save" : `Save`,
    "cm03_2500.btn.select" : `Select`,
    "cm03_2500.btn.unSelect" : `Unselect`,
    "cm03_2500.message.alertNoMapping" : `채널상품이 매핑되지 않은 상품그룹은 요금세트 적용을 할 수 없습니다. <br> 상품관리에서 매핑 현황을 확인해주세요.`,
    "cm03_2500.message.batchAlert1" : `<div style='margin-bottom:10px;'>After Batch application, rate will be changed as below.</div>`,
    "cm03_2500.message.batchAlert2" : `<div>1.Current Rate Set at Product group will be deleted.</div>`,
    "cm03_2500.message.batchAlert3" : `<div style='padding-left:10px;margin-bottom:10px;'>Rate Set from Batch application will be applied.</div>`,
    "cm03_2500.message.batchAlert4" : `<div>2.If Batch application is applied at Rate Set ‘A’, </div>`,
    "cm03_2500.message.batchAlert5" : `<div style='padding-left:10px;'>the 1st Rate Set will be applied to each Product Group.</div>`,
    "cm03_2500.message.batchAlert6" : `<div style='padding-left:6px;'>3.If there is no Rate set in Product Group, there will be no rate.</div>`,
    "cm03_2500.message.batchAlert7" : `<div style='padding-left:10px;margin-bottom:10px; color:red;'>(In Batch Application, Rate Set name will be indicated as alphabetical order.)</div>`,
    "cm03_2500.message.multiRegAlert" : `달력에  <i class='badge badge-primary round' style='background-color:#ffBB00;'>M</i> 표시가 있는 날짜는 해당 일자에 2개 이상의 요금세트가 적용된 경우입니다.`,
    "cm03_2500.message.occChngAlert" : `달력에  <i class='badge badge-primary round'>C</i> 표시가 있는 날짜는 요금세트의 객실 타입별 점유율이 설정된 경우입니다.`,
    "cm03_2500.title.allCreate" : `Batch application`,
    "cm03_2500.title.allCreateMethod" : `If ‘Batch Application’ is ticked, Rate set will be applied to all Product Group.`,
    "cm03_2500.title.allGoodGroup" : `ALL`,
    "cm03_2500.title.allSelect" : `All`,
    "cm03_2500.title.allUnSelect" : `Unselect all`,
    "cm03_2500.title.deleteMethod" : `Click the Rate Set and drag it into the calendar.`,
    "cm03_2500.title.deleteSuccess" : `Deleted.`,
    "cm03_2500.title.feeSet" : `Rate Set`,
    "cm03_2500.title.feeSetApply" : `Apply Rate Set`,
    "cm03_2500.title.feeTypes" : `Rate Type`,
    "cm03_2500.title.fri" : `Fri`,
    "cm03_2500.title.goodsGroup" : `Product Group`,
    "cm03_2500.title.maxDate" : `* Maximum of period setting is up to 90 day.`,
    "cm03_2500.title.mon" : `Mon`,
    "cm03_2500.title.occAlert" : `Rate would be changed automatically by OCC on the date with <i class='badge badge-primary round'>C</i>.(Auto Rate Set Change by OCC)`,
    "cm03_2500.title.percentOver" : `% or above`,
    "cm03_2500.title.periodInsert" : `Period`,
    "cm03_2500.title.sat" : `Sat`,
    "cm03_2500.title.saveSuccess" : `Saved`,
    "cm03_2500.title.selectMethod" : `Select the Product Group followed by the rate set. Then, set the Month to apply the rate set.`,
    "cm03_2500.title.sun" : `Sun`,
    "cm03_2500.title.thr" : `Thu`,
    "cm03_2500.title.tue" : `Tue`,
    "cm03_2500.title.wed" : `Wed`,
    "cm03_2700.message.noModify" : `No Change`,
    "cm03_2700.message.roomTypeProperty" : `User can check and modify Channel Room Type data connected to WINGS CMS.`,
    "cm03_2700.message.success" : `Saved.`,
    "cm03_2700.title.channelName" : `Channel`,
    "cm03_2700.title.content" : `Contents`,
    "cm03_2700.title.info" : `Information`,
    "cm03_2700.title.no" : `No`,
    "cm03_2700.title.roomType" : `Channel room type`,
    "cm03_2700.title.roomTypeProperty" : `Room Type Attribute`,
    "cm03_2700.title.roomTypePropertyInfo" : `Room type attribute information`,
    "cm03_2700.title.yes" : `Yes`,
    "cm03_2800.message.noModify" : `No Change.`,
    "cm03_2800.message.success" : `Saved.`,
    "cm03_2800.title.chRoomType" : `Channel Room`,
    "cm03_2800.title.channelName" : `Channel`,
    "cm03_2800.title.cmRateType" : `Product Group`,
    "cm03_2800.title.no" : `아니오`,
    "cm03_2800.title.productName" : `Product Group`,
    "cm03_2800.title.rateType" : `Product Group`,
    "cm03_2800.title.rateTypeProperty" : `Product Code Attribute`,
    "cm03_2800.title.yes" : `예`,
    "cm03_2900.popup.goodsAllCopy" : `Product Group Batch Setting (Inventory)`,
    "cm03_2900.popup.salesStatus" : `Sales Status`,
    "cm03_2900.title.channelByRate" : `Channel Rate`,
    "cm03_2900.title.cmRaAlRmsEmpty" : `Blank`,
    "cm03_2900.title.fri" : `Fri`,
    "cm03_2900.title.mon" : `Mon`,
    "cm03_2900.title.noSetting" : `No Rate`,
    "cm03_2900.title.sat" : `Sat`,
    "cm03_2900.title.sun" : `Sun`,
    "cm03_2900.title.thu" : `Thu`,
    "cm03_2900.title.tooltipMessage5" : `Indicate supporting channels only!`,
    "cm03_2900.title.tue" : `Tus`,
    "cm03_2900.title.wed" : `Wed`,
    "cm03_2900.title.allOpenBtn" : `All Open`,
    "cm03_2900.title.allCloseBtn" : `All Close`,
    "cm03_3100.message.alertRoomgroup" : `Please select Room Group for search`,
    "cm03_3100.title.channelGoods" : `Channel Product`,
    "cm03_3100.title.channelRoom" : `Channel Room`,
    "cm03_3100.title.closed" : `Closed`,
    "cm03_3100.title.controlRate" : `Rate Setting`,
    "cm03_3100.title.goodsGroup" : `Product Group`,
    "cm03_3100.title.hotelAll" : `Total Hotel Rooms`,
    "cm03_3100.title.hotelRoom" : `Hotel Rooms`,
    "cm03_3100.title.maxRate" : `Max Rate`,
    "cm03_3100.title.minRate" : `Min Rate`,
    "cm03_3100.title.occupancy" : `OCC`,
    "cm03_3100.title.remain" : `Inventory`,
    "cm03_3100.title.roomGroup" : `Room Group`,
    "cm03_3100.title.saleDate" : `Sales Date`,
    "cm03_3100.title.tooltipCloseCause1" : `No inventory at Total Hotel Rooms`,
    "cm03_3100.title.tooltipCloseCause10" : `No rate or 0`,
    "cm03_3100.title.tooltipCloseCause11" : `Rate is lower than Min rate of Room Group`,
    "cm03_3100.title.tooltipCloseCause12" : `Rate is higher than Max rate of Room Group`,
    "cm03_3100.title.tooltipCloseCause13" : `Rate is lower than Min rate of Product Group`,
    "cm03_3100.title.tooltipCloseCause14" : `Rate is higher than Max rate of Product Group`,
    "cm03_3100.title.tooltipCloseCause15" : `호텔전체객실에 설정된 점유율을 초과하여 마감`,
    "cm03_3100.title.tooltipCloseCause16" : `PMS객실에 설정된 점유율을 초과하여 마감`,
    "cm03_3100.title.tooltipCloseCause2" : `No inventory at Hotel Rooms`,
    "cm03_3100.title.tooltipCloseCause3" : `No inventory at Room Group`,
    "cm03_3100.title.tooltipCloseCause4" : `No inventory at Channel Room`,
    "cm03_3100.title.tooltipCloseCause5" : `Closed at Availability`,
    "cm03_3100.title.tooltipCloseCause6" : `Closed at Sales Status`,
    "cm03_3100.title.tooltipCloseCause7" : `Closed at Room Group`,
    "cm03_3100.title.tooltipCloseCause8" : `Closed at Channel Room`,
    "cm03_3100.title.tooltipCloseCause9" : `Closed at Channel Product`,
    "cm03_3200.message.inputCondition" : `Please select @(0) for search.`,
    "cm03_3200.title.applySet" : `Rate Set`,
    "cm03_3200.title.changeDate" : `Change date`,
    "cm03_3200.title.channelFee" : `Channel Product Rate`,
    "cm03_3200.title.channelProduct" : `Channel Product`,
    "cm03_3200.title.channelRoom" : `Channel Room`,
    "cm03_3200.title.closeYn" : `Close`,
    "cm03_3200.title.date" : `Sales Date`,
    "cm03_3200.title.hotelRoom" : `Hotel Room`,
    "cm03_3200.title.id" : `User`,
    "cm03_3200.title.no" : `N`,
    "cm03_3200.title.productGroup" : `Product Group`,
    "cm03_3200.title.productGroupFee" : `Product Group Rate`,
    "cm03_3200.title.rateChangeHist" : `Rate Change History`,
    "cm03_3200.title.type" : `유형`,
    "cm03_3200.title.yes" : `Y`,
    "cm03_3500.message.addDay" : `추가일수를 확인해 주세요.`,
    "cm03_3500.message.limitProperty" : `조회시 호텔 10개를 초과 할수 없습니다.`,
    "cm03_3500.message.notProperty" : `선택된 호텔이 없습니다.`,
    "cm03_3500.message.rateRange" : `금액범위를 확인해 주세요.`,
    "cm03_3500.message.startDate" : `시작일자를 확인해 주세요.`,
    "cm03_3500.message.weekendCheck" : `요일선택 조건을 확인해주세요.`,
    "cm03_3500.title.SaleDate" : `판매일자`,
    "cm03_3500.title.addDay" : `추가 일수`,
    "cm03_3500.title.channelName" : `채널명`,
    "cm03_3500.title.channelProduct" : `채널상품명`,
    "cm03_3500.title.channelRoom" : `채널객실명`,
    "cm03_3500.title.hotelName" : `호텔명`,
    "cm03_3500.title.rate" : `요금`,
    "cm03_3500.title.rateRange" : `금액범위`,
    "cm03_3500.title.rateSettingInfo" : `요금 설정 정보`,
    "cm03_3500.title.startDate" : `시작 일자`,
    "cm03_3600.message.content1" : `&#9679;&nbsp;&nbsp;&nbsp;상품그룹 부가 설정 화면에서는 각 상품그룹별로 PMS 정보(거래처, 마켓타입, 소스타입, 영업직원)를 설정할 수 있습니다.`,
    "cm03_3600.message.content2" : `&#9679;&nbsp;&nbsp;&nbsp;상품그룹 부가 설정이 없으면, 예약은 '채널 관리'의 '채널 정보 설정'에 입력된 정보를 기반으로 생성됩니다.`,
    "cm03_3600.title.account" : `거래처`,
    "cm03_3600.title.channelName" : `채널명`,
    "cm03_3600.title.empId" : `영업직원`,
    "cm03_3600.title.marketType" : `마켓타입`,
    "cm03_3600.title.pageName" : `상품그룹 부가 설정`,
    "cm03_3600.title.productGroup" : `상품그룹`,
    "cm03_3600.title.sorcType" : `예약경로`,
    "com.button.cancel" : `Cancel`,
    "com.button.complete" : `Comeplete`,
    "com.button.initialize" : `Reset`,
    "com.button.modify" : `Modify`,
    "com.button.next" : `Next`,
    "com.button.prev" : `Back`,
    "com.button.save" : `Save`,
    "com.button.search" : `Search`,
    "com.data.notAuthority" : `해당 권한이 없습니다.<br/> 호텔 관리자에게 문의 바랍니다.`,
    "com.data.notUsed" : `N`,
    "com.data.use" : `Y`,
    "com.date.fri" : `Fri`,
    "com.date.mon" : `Mon`,
    "com.date.sat" : `Sat`,
    "com.date.sun" : `Sun`,
    "com.date.thu" : `Thu`,
    "com.date.tue" : `Tue`,
    "com.date.wed" : `Wed`,
    "com.date.year" : `Year`,
    "com.email.grantChangeSuccess" : `Authority Updated`,
    "com.email.sendFail" : `Mail delivery failed.`,
    "com.email.sendSuccess" : `Email is sent succesfully.`,
    "com.error.common" : `Data 처리에 오류가 발생했습니다.<br/>시스템관리자에게 문의바랍니다.`,
    "com.error.timeout" : `The request time for the information has been exceeded to the hotel. <br/> Please contact the system administrato`,
    "com.grid.allSelected" : `All`,
    "com.grid.filter" : `Search`,
    "com.grid.htmlMsg1" : `No Results.`,
    "com.grid.htmlMsg2" : `Please search with`,
    "com.grid.htmlMsg3" : `<span class="text-danger">other conditions.</span>`,
    "com.grid.manySelected" : `@(0) selected`,
    "com.grid.noData" : `No Data`,
    "com.popup.customer" : `Request Customer Center`,
    "com.popup.error" : `Error`,
    "com.popup.ok" : `Confirm`,
    "com.popup.success" : `Success`,
    "com.popup.warning" : `Warning`,
    "com.popup.wrongToken" : `Incorrect cert token.`,
    "com.select.sample1" : `English1`,
    "com.select.sample2" : `English2`,
    "com.select.sample3" : `English3`,
    "com.select.sample4" : `English4`,
    "com.session.expired" : `Expired session information.`,
    "com.title.change" : `Changed`,
    "com.title.delete" : `Deleted`,
    "com.title.firstReg" : `Created`,
    "com.title.step" : `step`,
    "comn.message.auth_excel_assigned" : `You are assigned to extract Excel(.xlsx) file.`,
    "comn.message.auth_excel_created" : `You are authorized to extract Excel(.xlsx) file.`,
    "comn.message.auth_excel_removed" : `You are unauthorized to extract Excel(.xlsx) file.`,
    "comn.message.auth_group_changed" : `The authority group has been changed from %1 to %2.`,
    "comn.message.auth_group_created" : `The authority group of %1 was granted.`,
    "comn.message.auth_group_removed" : `The authority group of %1 has been removed.`,
    "comn.message.auth_hotel_changed" : `The hotel authority has been changed from %1 to %2.`,
    "comn.message.auth_hotel_created" : `The hotel authority of %1 has been granted.`,
    "comn.message.auth_hotel_removed" : `The hotel authority of %1 has been removed.`,
    "comn.message.auth_open_assigned" : `Screen open authority is assigned.`,
    "comn.message.auth_open_created" : `Screen open authority is deleted`,
    "comn.message.auth_open_removed" : `Screen open authority is assigned.`,
    "comn.message.auth_report_assigned" : `Report output permission is specified.`,
    "comn.message.auth_report_created" : `You have been granted permission to print the report.`,
    "comn.message.auth_report_removed" : `Report permission has been removed.`,
    "comn.message.auth_save_assigned" : `Save authority is deleted`,
    "comn.message.auth_save_created" : `Save authority is assigned.`,
    "comn.message.auth_save_removed" : `Save authority is assigned.`,
    "comn.message.operation_added" : `%2 added to %1.`,
    "comn.message.operation_assigned" : `%1 is specified as %2.`,
    "comn.message.operation_changed" : `%1 changed from %2 to %3.`,
    "comn.message.operation_created" : `%1 was created.`,
    "comn.message.operation_deleted" : `%1 has been deleted.`,
    "comn.message.operation_removed" : `The value of %1, %2 has been deleted.`,
    "comn.title.cms_system_name" : `WINGSCMS`,
    "comn.title.interface_complete" : `Complete`,
    "comn.title.interface_recovered" : `Restore`,
    "comn.title.interface_recovering_ask" : `Do you restore the interface?`,
    "comn.title.interface_run" : `Normal Connection`,
    "comn.title.interface_stop" : `Connection Stop`,
    "comn.title.time_interval_day" : `Sunday`,
    "comn.title.time_interval_hour" : `Hour`,
    "comn.title.time_interval_minute" : `Minute`,
    "comn.title.time_interval_second" : `Second`,
    "downloadReasonPopup.alert.fieldRequired" : `Required`,
    "downloadReasonPopup.alert.modifyData" : `There is changed data. Please save it first.`,
    "downloadReasonPopup.alert.noGridData" : `There is no data in grid`,
    "downloadReasonPopup.alert.popupLabelReason" : `Reason`,
    "downloadReasonPopup.alert.popupLabelUserName" : `User Name`,
    "downloadReasonPopup.alert.popupTitleDownload" : `Download`,
    "downloadReasonPopup.alert.popupValidationReason" : `You must enter the reason.`,
    "downloadReasonPopup.alert.popupValidationUserName" : `User name is empty.`,
    "error.title.content1" : `The page cannot be displayed.`,
    "error.title.content1Sub1" : `Sorry for the inconvenience to use the service.`,
    "error.title.content1Sub2" : `The page cannot be displayed because the <span class = 'text-danger'> error occurred </span> internally on the page server you are trying to connect to.`,
    "grid.grand.total" : `Total`,
    "grid.header.age" : `Age1`,
    "grid.header.athlete" : `Medalist`,
    "grid.header.bronze" : ` Bronze Medal`,
    "grid.header.country" : `Country`,
    "grid.header.receivedate" : `Pick up date`,
    "grid.header.silver" : `Silver medal`,
    "grid.header.sport" : `Type`,
    "grid.header.year" : `Year`,
    "grid.sub.total" : `Sub Total`,
    "header.button.more" : `More`,
    "header.message.noData" : `No Data`,
    "header.title.emergency" : `URGENT`,
    "header.title.important" : `Important`,
    "header.title.langChinese" : `English`,
    "header.title.langEglish" : `中國語`,
    "header.title.langJapanese" : `日本語`,
    "header.title.langKorean" : `한국어`,
    "header.title.languageChange" : `Select Language`,
    "header.title.normal" : `General`,
    "header.title.notification" : `Notification`,
    "header.title.notificationMessage" : `There was an unread message <b> @(0) </b>.`,
    "header.title.searchProperty" : `Search`,
    "header.title.selectProperty" : `Please select the property.`,
    "interLockingStep.title.interlocking" : `Connected channels management`,
    "m03_0600.message.productMapping2" : `표시를 누르면 맵핑이 해제됩니다.`,
    "manual.title.attachment" : `Attachment`,
    "manual.title.close" : `Exit`,
    "manual.title.download" : `Download`,
    "manual.title.item" : `Changed Item List`,
    "manual.title.settingDate" : `Setting Date`,
    "multiLangPopup.button.save" : `Save`,
    "multiLangPopup.title.cancel" : `Cancel`,
    "multiLangPopup.title.chinese" : `Chinese`,
    "multiLangPopup.title.close" : `Exit`,
    "multiLangPopup.title.english" : `English`,
    "multiLangPopup.title.japanese" : `Japanese`,
    "multiLangPopup.title.korean" : `Korean`,
    "multiLangPopup.title.language" : `Multillingual`,
    "page.title.searchgrid" : `Grid Display`,
    "pagetitle.button.tutorial" : `Tutorial`,
    "pagination.etc.items_per_page" : `/ page`,
    "pagination.etc.jump_to" : `Move`,
    "pagination.etc.jump_to_confirm" : `Confirm`,
    "pagination.etc.next_3" : `Next 3 pages`,
    "pagination.etc.next_5" : `Next 5 pages`,
    "pagination.etc.next_page" : `Next page`,
    "pagination.etc.of" : `of`,
    "pagination.etc.page" : ``,
    "pagination.etc.prev_3" : `Previous 3 pages`,
    "pagination.etc.prev_5" : `Previous 5 pages`,
    "pagination.etc.prev_page" : `Previous page`,
    "productManageGrid.button.add" : `Add`,
    "productManageGrid.button.no" : `No`,
    "productManageGrid.button.setting" : `Set`,
    "productManageGrid.button.yes" : `Yes`,
    "productManageGrid.message.abled" : `Activate?`,
    "productManageGrid.message.channelName" : `Select <br/> [Channel Name] to manage the product loaded from the channel.`,
    "productManageGrid.message.channelNameDesc" : `Please select the [channel] to manage the products from channels.`,
    "productManageGrid.message.disabled" : `Inactive?`,
    "productManageGrid.message.noSetting" : `Not set<br />No CMS control`,
    "productManageGrid.message.noSettingTooltip" : `If room/product is not controlled by CMS,<br /> rate/inventory must be controlled by the channel’s extranet.`,
    "productManageGrid.message.productGroup" : `Add the Product Group<br/>.`,
    "productManageGrid.message.roomGroup" : `Please add Room Group(<br />`,
    "productManageGrid.message.setting" : `Users can manage the products by creating Room/Product group.`,
    "searchFilter.button.init" : `Reset`,
    "searchFilter.button.save" : `Save`,
    "searchFilter.button.search" : `Search`,
    "searchFilter.title.all" : `All`,
    "searchFilter.title.condition" : `Search Condition`,
    "searchFilter.title.conditionAdd" : `Add condition`,
    "tutorial.button.allStopView" : `Stop all menu <BR/> tutorials`,
    "tutorial.button.stopView" : `Stop watching this menu <BR/>tutorial`,
    "tutorial.label.tutorialStopView" : `Stop tutorial`,
    "tutorial.title.exit" : `Exit tutorial`,
    "tutorial.title.newWingsCms" : `Let us introduce new <br/><b>WINGS CMS</b>.`,
    "tutorial.title.subExit" : `If you want to watch it again, you can go to <br/>[Tutorial] menu on each menu.`,
    "zz01_0100.button.login" : `Login`,
    "zz01_0100.button.searchId" : `Find the ID`,
    "zz01_0100.button.searchPassword" : `Forget Password?`,
    "zz01_0100.title.allegro" : `Allegro`,
    "zz01_0100.title.allegroAlert" : `[COMPANY ID] is not required for Allegro.`,
    "zz01_0100.title.companyId" : `Company ID`,
    "zz01_0100.title.info" : `Please access with login credential.`,
    "zz01_0100.title.join" : `Sign Up`,
    "zz01_0100.title.keepLogin" : `Keep logged in`,
    "zz01_0100.title.noneJoin" : `No account?`,
    "zz01_0100.title.password" : `Password`,
    "zz01_0100.title.userId" : `User ID / E-mail`,
    "zz01_0100.title.validationCheck" : `Please enter @(0).`,
    "zz01_0100.title.welcome" : `Welcome`,
    "zz01_0200.btn.passwordInit" : `Password Reset`,
    "zz01_0200.message.noAccess" : `Invalid Access`,
    "zz01_0200.title.lockMessage" : `It was automatically locked due to<br/>an error in entering the login information 5 times.`,
    "zz01_0200.title.lockNotice" : `Click <span class='text-primary'>Password Reset</span> and issue temporary password. Then, log in please.`,
    "zz01_0400.button.login" : `Login`,
    "zz01_0400.message.info1" : `At SanhaIt Integrated Login system, we will try to protect your valuable personal informationa as best as possible.`,
    "zz01_0400.message.info2" : `Personal data is not disclosed without consent, and it is managed safely according to personal information protection policy.`,
    "zz01_0400.title.userId" : `User ID`,
    "zz01_0500.button.initPw" : `Password Reset`,
    "zz01_0500.button.login" : `Login`,
    "zz01_0500.message.info1" : `Password Policy`,
    "zz01_0500.message.info2" : `The password must be combined with numbers, alphabet, and special characters. It must be at least 8 characters and cannot be exceeded more than 20 characters.`,
    "zz01_0500.message.info3" : `Passsword should be changed every 180 days.`,
    "zz01_0500.message.info4" : `You cannot use used password.`,
    "zz01_0500.message.validationError" : `All information is required. Please enter the information.`,
    "zz01_0500.title.allegro" : `Allegro`,
    "zz01_0500.title.allegroAlert" : `[COMPANY ID] is not required for Allegro.`,
    "zz01_0500.title.companyId" : `Company ID`,
    "zz01_0500.title.email" : `Registered Email`,
    "zz01_0500.title.forgetPw" : `Forget Password?`,
    "zz01_0500.title.forgetPwInfo" : `Please reset the password with below information.`,
    "zz01_0500.title.userId" : `user ID/Email`,
    "zz01_0500.validation.validationEmailError" : `Please enter a valid email address`,
    "zz01_0500.validation.validationEmptyError" : `All information is required. Please enter the information.`,
    "zz01_0600.message.nonAgree" : `You need approval <br/> from system manager.`,
    "zz01_0600.message.nonNotice" : `Please wait a little. Subscription authentication can take <span class = 'text-primary'> 1 ~ 2 days </span>.`,
    "zz01_0700.button.login" : `Login`,
    "zz01_0700.message.info" : `Please click the <b class='text-primary'>[Log In]</b> below, if you want to use the system again.`,
    "zz01_0700.message.title" : `For security reasons, your account is logged out automatically after you'be been inactive for long time <span class='d-sm-block'></span>.`,
    "zz01_0700.message.userlogout" : `Logged out by user`,
    "zz01_0800.button.findId" : `Find the ID`,
    "zz01_0800.button.login" : `Login`,
    "zz01_0800.message.validationError" : `All information is required. Please enter the information.`,
    "zz01_0800.title.allegro" : `Allegro`,
    "zz01_0800.title.allegroAlert" : `[COMPANY ID] is not required for Allegro.`,
    "zz01_0800.title.companyId" : `Company ID`,
    "zz01_0800.title.email" : `Registered Email`,
    "zz01_0800.title.forgetId" : `Forget your ID?`,
    "zz01_0800.title.forgetIdInfo" : `Please find the ID with below information.`,
    "zz01_0800.title.userName" : `user name`,
    "zz01_0800.validation.validationEmailError" : `Please enter a valid email address`,
    "zz01_0800.validation.validationEmptyError" : `All information is required. Please enter the information.`,
    "zz01_0900.message.choiceInfo" : `Access`,
    "zz01_0900.message.information" : `Select acccessing property.`,
    "zz01_0900.message.logoutButton" : `Logout`,
    "zz01_0900.message.noAccess" : `Invalid Access`,
    "zz01_0900.message.noSelected" : `Select acccessing property.`,
    "zz01_0900.message.selectInfo" : `Property Select...`,
    "zz01_0900.title.label1" : `Perfect compatibility with WINGS`,
    "zz01_0900.title.label2" : `<b class='text-success'>CMS</b> + <b class='text-warning'>PMS</b> Start using at same time.`,
    "zz01_1000.button.passwordChange" : `Password Change`,
    "zz01_1000.etc.passwordChangeInfo" : `We recommend to change the password every 3 months.`,
    "zz01_1000.message.checkInput" : `Please write (  )`,
    "zz01_1000.message.info01" : `Article 5 (Password Management) of measures to ensure the stability of personal information`,
    "zz01_1000.message.noAccess" : `Invalid Access`,
    "zz01_1000.message.passwordPolicyInfo02" : `If password is 3 types of combination with numbers, alphabet and special characters, it must be at least 8 characters and cannot be exceeded more than 20 characters.`,
    "zz01_1000.message.passwordPolicyInfo03" : `Password chage cycle is 90 days.`,
    "zz01_1000.message.passwordPolicyInfo04" : `You cannot use used password.`,
    "zz01_1000.success.changeNewPassword" : `Well done! Password is changed.`,
    "zz01_1000.title.newPassword" : `New Password`,
    "zz01_1000.title.newPasswordCheck" : `Confirm New Password`,
    "zz01_1000.title.oldPassword" : `Current Password`,
    "zz01_1000.title.passwordChange" : `Password Change`,
    "zz01_1000.title.passwordPolicy" : `Password Policy`,
    "zz01_1000.validate.passwordWarning01" : `Warning! New Passowrd and Password Confirmation is not matched.`,
    "zz01_1000.validate.passwordWarning02" : `Warning! Password can be from 8 characters minimum to 20 characters maximum. And it should be combined with numbers, alphabet and symbols.`,
    "zz01_1000.validate.passwordWarning03" : `Warning! Please confirm the password. Incorrect Password.`,
    "zz01_1100.button.set" : `Password Reset`,
    "zz01_1100.message.Error" : `Change failed.`,
    "zz01_1100.message.Success" : `Password is changed.`,
    "zz01_1100.message.info1" : `Password Policy`,
    "zz01_1100.message.info2" : `The password must be combined with numbers, alphabet, and special characters. It must be at least 8 characters and cannot be exceeded more than 20 characters.`,
    "zz01_1100.message.info3" : `Passsword should be changed every 180 days.`,
    "zz01_1100.message.info4" : `You cannot use used password.`,
    "zz01_1100.message.validationError" : `All information is required. Please enter the information.`,
    "zz01_1100.message.validationError1" : `The password must be combined with numbers, alphabet and special characters. Please enter the data.`,
    "zz01_1100.message.validationError2" : `Please insert the same password.`,
    "zz01_1100.title.forgetPw" : `Forget Password?`,
    "zz01_1100.title.forgetPwInfo" : `Please reset the new password.`,
    "zz01_1100.title.pw" : `New Password`,
    "zz01_1100.title.pwComfirm" : `Confirm New Password`,
    "zz01_1200.title.twofactor" : `OTP Code`,
    "zz02_0300.button.auth" : `Authentication`,
    "zz02_0300.button.authRequest" : `Verification Code Request`,
    "zz02_0300.button.cancel" : `Cancel`,
    "zz02_0300.button.change" : `Update`,
    "zz02_0300.button.etiquetteModeNotSet" : `No Setting`,
    "zz02_0300.button.etiquetteModeSet" : `Set`,
    "zz02_0300.button.management" : `Management`,
    "zz02_0300.button.save" : `Save`,
    "zz02_0300.button.smsAlarmReceiveNotSet" : `No Setting`,
    "zz02_0300.button.smsAlarmReceiveSet" : `Setting`,
    "zz02_0300.button.userAuthManagement" : `User Authority Management`,
    "zz02_0300.button.userInfo" : `User Information`,
    "zz02_0300.title.alarmSetUp" : `Real Time Notification Setting`,
    "zz02_0300.title.alarmSetUpFour" : `When Etiquett Mode is enabled, notifications will be silenced from 9PM to 9AM. Notifications during Etiquett Mode will appear in 9AM next day.`,
    "zz02_0300.title.alarmSetUpOne" : `If you agree with real-time notification, an error of data transmit and system disconnetion notification message will be sent via SMS. In case of system disconnection, if it occurs for more than 30 minutes, you will receive a notification via SMS.`,
    "zz02_0300.title.alarmSetUpThree" : `It takes up to 30 minutes till apply, after updating Push Notification setting.`,
    "zz02_0300.title.alarmSetUpTwo" : `Only Korean mobile can receive the SMS.`,
    "zz02_0300.title.authNumber" : `Verification Code`,
    "zz02_0300.title.companyId" : `Company ID`,
    "zz02_0300.title.email" : `Email Address`,
    "zz02_0300.title.etiquetteMode" : `Etiquette Mode`,
    "zz02_0300.title.first_registered_date" : `1st Sign Up Date`,
    "zz02_0300.title.lastUpdateDate" : `Last Update`,
    "zz02_0300.title.myUserInfo" : `My information`,
    "zz02_0300.title.phone" : `Mobile`,
    "zz02_0300.title.property" : `User Authority`,
    "zz02_0300.title.pwChange" : `Password Update`,
    "zz02_0300.title.pwInfo" : `Sanha IT strongly recommends to update the password every 3 months for your privacy.`,
    "zz02_0300.title.smsAlarmReceive" : `SMS Notification Receipt`,
    "zz02_0300.title.smsReceiveContact" : `SMS Notification Contact`,
    "zz02_0300.title.userId" : `Login ID`,
    "zz02_0300.title.userInfo" : `user information`,
    "zz02_0300.title.userName" : `User Name`,
    "zz02_0300.title.user_permission" : `User Authority`,
    "zz02_0400.button.auth" : `Authentication`,
    "zz02_0400.button.authRequest" : `Verification Code Request`,
    "zz02_0400.button.cancel" : `Cancel`,
    "zz02_0400.button.change" : `Update`,
    "zz02_0400.button.etiquetteModeNotSet" : `No Setting (24 hour reception)`,
    "zz02_0400.button.etiquetteModeSet" : `Setting (No Notification from 9PM ~ 9AM)`,
    "zz02_0400.button.management" : `Management`,
    "zz02_0400.button.reAuth" : `Re Certify`,
    "zz02_0400.button.save" : `Save`,
    "zz02_0400.button.smsAlarmReceiveNotSet" : `No Setting`,
    "zz02_0400.button.smsAlarmReceiveSet" : `Set`,
    "zz02_0400.title.adminPwd" : `관리자 변경`,
    "zz02_0400.title.adminPwdModify" : `관리자 비밀번호 변경`,
    "zz02_0400.title.alarmSetUp" : `Real Time Notification Setting`,
    "zz02_0400.title.alarmSetUpFour" : `When Etiquett Mode is enabled, notifications will be silenced from 9PM to 9AM. Notifications during Etiquett Mode will appear in 9AM next day.`,
    "zz02_0400.title.alarmSetUpOne" : `If you agree with real-time notification, an error of data transmit and system disconnetion notification message will be sent via SMS. In case of system disconnection, if it occurs for more than 30 minutes, you will receive a notification via SMS.`,
    "zz02_0400.title.alarmSetUpThree" : `It takes up to 30 minutes till apply, after updating Push Notification setting.`,
    "zz02_0400.title.alarmSetUpTwo" : `Only Korean mobile can receive the SMS.`,
    "zz02_0400.title.authNumber" : `Verification Code`,
    "zz02_0400.title.companyId" : `Company ID`,
    "zz02_0400.title.email" : `Email Address`,
    "zz02_0400.title.etiquetteMode" : `Etiquette Mode`,
    "zz02_0400.title.first_registered_date" : `1st Sign Up Date`,
    "zz02_0400.title.inputLabelNumber" : `Mobile`,
    "zz02_0400.title.lastUpdateDate" : `Last Update`,
    "zz02_0400.title.phone" : `Mobile`,
    "zz02_0400.title.property" : `User Authority`,
    "zz02_0400.title.propertyName" : `Property Name`,
    "zz02_0400.title.pwChange" : `Password Update`,
    "zz02_0400.title.pwInfo" : `Sanha IT strongly recommends to update the password every 3 months for your privacy.`,
    "zz02_0400.title.smsAlarmReceive" : `SMS Notification Receipt`,
    "zz02_0400.title.smsReceiveContact" : `SMS Receipt Contact`,
    "zz02_0400.title.userId" : `Login ID`,
    "zz02_0400.title.userInfo" : `user information`,
    "zz02_0400.title.userName" : `User Name`,
    "zz02_0400.title.user_permission" : `User Authority`,
    "zz02_0400.validate.auth" : `Verified`,
    "zz02_0400.validate.phoneNumber" : `Numbers only`,
    "zz02_0500.button.passwordChange" : `Password Update`,
    "zz02_0500.button.set" : `Password Reset`,
    "zz02_0500.etc.passwordChangeInfo" : `Sanha IT strongly recommends to update the password every 3 months for your privacy.`,
    "zz02_0500.message.Error" : `Change failed.`,
    "zz02_0500.message.Success" : `Password is changed.`,
    "zz02_0500.message.info01" : `Personal Information Protection Act <br /> Article 5 (Password Management) of measures to ensure the stability of personal information`,
    "zz02_0500.message.info1" : `Password Policy`,
    "zz02_0500.message.info2" : `The password must be combined with numbers, alphabet, and special characters. It must be at least 8 characters and cannot be exceeded more than 20 characters.`,
    "zz02_0500.message.info3" : `Passsword should be changed every 180 days.`,
    "zz02_0500.message.info4" : `You cannot use used password.`,
    "zz02_0500.message.passwordPolicyInfo02" : `Password is a combination of 3 different types(numbers, alphabets and special characters). It must be at least 8 characters cannot be exceeded 20 characters.`,
    "zz02_0500.message.passwordPolicyInfo03" : `The password change cycle is 90 days.`,
    "zz02_0500.message.passwordPolicyInfo04" : `You cannot use used password.`,
    "zz02_0500.message.validationError" : `All information is required. Please enter the information.`,
    "zz02_0500.message.validationError1" : `The password must be combined with numbers, alphabet and special characters. Please enter the data.`,
    "zz02_0500.message.validationError2" : `Please insert the same password.`,
    "zz02_0500.success.changeNewPassword" : `Well done! Password is changed.`,
    "zz02_0500.success.changePassword" : `비밀번호가 변경되었습니다.`,
    "zz02_0500.title.forgetPw" : `Forget Password?`,
    "zz02_0500.title.forgetPwInfo" : `Please reset the new password.`,
    "zz02_0500.title.newPassword" : `New Password`,
    "zz02_0500.title.newPasswordCheck" : `Confirm New Password`,
    "zz02_0500.title.oldPassword" : `Current Password`,
    "zz02_0500.title.passwordChange" : `Password Update`,
    "zz02_0500.title.passwordPolicy" : `Password Policy`,
    "zz02_0500.title.pw" : `New Password`,
    "zz02_0500.title.pwComfirm" : `Confirm New Password`,
    "zz02_0500.validate.inputPasswordWarning01" : `신규비밀번호와 확인이 일치하지 않습니다.`,
    "zz02_0500.validate.inputPasswordWarning02" : `비밀번호는 숫자,영문,특수문자 3가지 조합 시 최소 8자 이상 최대 20자 입니다.`,
    "zz02_0500.validate.inputPasswordWarning03" : `비밀번호를 다시 확인하세요. 비밀번호를 잘못 입력하셨습니다.`,
    "zz02_0500.validate.passwordWarning01" : `Warning! New Passowrd and Password Confirmation is not matched.`,
    "zz02_0500.validate.passwordWarning02" : `Warning! Password can be from 8 characters minimum to 20 characters maximum. And it should be combined with numbers, alphabet and symbols.`,
    "zz02_0500.validate.passwordWarning03" : `Warning! Please confirm the password. Incorrect Password.`,
    "zz02_0600.button.gridCancel" : `Cancel`,
    "zz02_0600.button.gridSave" : `Save`,
    "zz02_0600.button.init" : `Initialization`,
    "zz02_0600.button.search" : `Search`,
    "zz02_0600.button.useAll" : `All`,
    "zz02_0600.button.useN" : `Inactive`,
    "zz02_0600.button.useY" : `Active`,
    "zz02_0600.button.userAuthGrpPopupNo" : `No`,
    "zz02_0600.button.userAuthGrpPopupYes" : `Yes`,
    "zz02_0600.etc.gridUseN" : `Inactive`,
    "zz02_0600.etc.gridUseY" : `Active`,
    "zz02_0600.message.userAuthGrpPopup" : `Reassign the User Group Authority?`,
    "zz02_0600.title.gridCert" : `Cert`,
    "zz02_0600.title.gridExcel" : `Excel`,
    "zz02_0600.title.gridPrint" : `Print`,
    "zz02_0600.title.gridSave" : `Save`,
    "zz02_0600.title.gridSelect" : `Search`,
    "zz02_0600.title.gridSystemMenuName" : ` System Menu `,
    "zz02_0600.title.gridUse" : `Status`,
    "zz02_0600.title.gridUser" : `user name`,
    "zz02_0600.title.gridUserId" : `User ID`,
    "zz02_0600.title.menuAuth" : `Menu Authority`,
    "zz02_0600.title.propertyName" : `COMPANY ID_Property Name`,
    "zz02_0600.title.use" : `Status`,
    "zz02_0600.title.userAuthGrp" : `User Authority Group`,
    "zz02_0600.title.userGrpAuthPopup" : `User Group Authority`,
    "zz02_0600.title.userInfo" : `user information`,
    "zz02_0600.title.userName" : `user name`,
    "zz02_0700.button.cancel" : `Cancel`,
    "zz02_0700.button.dataReset" : `System Reset`,
    "zz02_0700.button.friday" : `FRI`,
    "zz02_0700.button.monday" : `MON`,
    "zz02_0700.button.no" : `No`,
    "zz02_0700.button.notUsed" : `Inactive`,
    "zz02_0700.button.notUsedStatus" : `Inactive`,
    "zz02_0700.button.pmsConnCheck" : `PMS Connection Check`,
    "zz02_0700.button.pmsRoomInfo" : `PMS  Room Information Retrieval`,
    "zz02_0700.button.pmsServiceInfo" : `PMS Service Information Retrieval`,
    "zz02_0700.button.roomManagement" : `Room Information Management`,
    "zz02_0700.button.saturday" : `SAT`,
    "zz02_0700.button.save" : `Save`,
    "zz02_0700.button.serviceManagement" : `Service Information Management`,
    "zz02_0700.button.sunday" : `SUN`,
    "zz02_0700.button.thursday" : `THU`,
    "zz02_0700.button.tuesday" : `TUE`,
    "zz02_0700.button.use" : `Active`,
    "zz02_0700.button.wednesday" : `WED`,
    "zz02_0700.button.yes" : `Yes`,
    "zz02_0700.message.checkPmsDb" : `Check the hotel's PMS DB connection.`,
    "zz02_0700.message.dataInit" : `Would you like to disconnect CMS of @(0)?`,
    "zz02_0700.message.dataInitSuccess" : `CMS has been disconnected.`,
    "zz02_0700.message.dataReset" : `If property changes the PMS or does not use the CMS, [System Reset] will delete all of data at CMS.`,
    "zz02_0700.message.dataResetMsg" : `If system is reset, all connected channels will be disconnected. Availability, Rate, Inventories including Reservations only can be controlled by each channels’ Extranet.`,
    "zz02_0700.message.duplRoom" : `Room type is duplicated.`,
    "zz02_0700.message.inputEmailCheck" : `Pleace check your email address`,
    "zz02_0700.message.inputLatCheck" : `Please check longitude data`,
    "zz02_0700.message.inputLonCheck" : `Please check the longitude.`,
    "zz02_0700.message.noModify" : `No data has been changed.`,
    "zz02_0700.message.pmsCallSuccess" : `PMS Information is successfully loaded.`,
    "zz02_0700.message.pmsConnectionFailed" : `Connection failed.`,
    "zz02_0700.message.pmsConnectionSuccess" : `The connection was successful.`,
    "zz02_0700.message.pmsServiceCallSuccess" : `PMS service product information was successfully loaded.`,
    "zz02_0700.message.requireInput" : `Please input @(0) .`,
    "zz02_0700.message.requireManager" : `Please input @(0) . Please contact the Manager.`,
    "zz02_0700.message.roomInfo" : `Total Number of Rooms : @(0)`,
    "zz02_0700.message.roomInfoMsg" : `Please update the room information to manage available rooms accurately.`,
    "zz02_0700.message.saveSuccess" : `Well done! Saved`,
    "zz02_0700.message.serviceInfoMsg" : `Please update the hotel's service product for mapping with the service product of the channel.`,
    "zz02_0700.message.success" : `Processed succesfully.`,
    "zz02_0700.title.add" : `Add`,
    "zz02_0700.title.adjRms" : `Use overbooking rooms`,
    "zz02_0700.title.adjRmsTooltip" : `It will transmit the number of available rooms including overbooking rooms.`,
    "zz02_0700.title.alarm" : `Notification`,
    "zz02_0700.title.autoCloseYn" : `PMS Availability`,
    "zz02_0700.title.autoToolTip" : `1. If AUTO is ON, total remaining rooms will be applied as inventories. Period cannot be set for AUTO. <br/><br/>2. If users use CMS without PMS, Inventory information will be set based on the ‘Room Information’. <br/><br/>3. If AUTO is off, the last remaining rooms will be applied as inventories.`,
    "zz02_0700.title.businessNumber" : `Corporate No.`,
    "zz02_0700.title.channelManager" : `Please check the channel room name data.`,
    "zz02_0700.title.closeMinRmRa" : `Use the lowest room rate`,
    "zz02_0700.title.closeMinRmRaTooltip" : `If the entered rate is less than the set minimum room rate, the reservation is automatically closed.`,
    "zz02_0700.title.companyId" : `Company ID`,
    "zz02_0700.title.contractCount" : `Channel Contract`,
    "zz02_0700.title.contractStatus" : `Contract Status`,
    "zz02_0700.title.currency" : `Currency`,
    "zz02_0700.title.currencyUnit" : `Currency decimal point`,
    "zz02_0700.title.cutOffDays" : `Use Closing Date(based on arrival date)`,
    "zz02_0700.title.cutOffDaysTooltip" : `Reservations cannot be made before the number of days set as of the arrival date.`,
    "zz02_0700.title.emailAddress" : `Email`,
    "zz02_0700.title.faxNumber" : `Fax`,
    "zz02_0700.title.grade" : `Property Grade`,
    "zz02_0700.title.hotelSetting" : `Hotel Setting`,
    "zz02_0700.title.importApi" : `Would you like to retrieve the PMS Room Information?`,
    "zz02_0700.title.inputData" : `Please insert data.`,
    "zz02_0700.title.labelPmsAgentUrl" : `PMS Agent URL`,
    "zz02_0700.title.latLonMessage" : `Longitude and latitude identify the accmmodation location.`,
    "zz02_0700.title.latitude" : `Latitude`,
    "zz02_0700.title.longitude" : `Longitude`,
    "zz02_0700.title.marketType" : `Market Type`,
    "zz02_0700.title.maxAdultCov" : `Max Occupancy`,
    "zz02_0700.title.maxChildCov" : `Max Child`,
    "zz02_0700.title.maxLos" : `Use Maximum stay restriction(by check in date)`,
    "zz02_0700.title.maxLosTooltip" : `Reservations cannot be made if the reservation exceeds the maximum stay based on the check in date.`,
    "zz02_0700.title.maxSta bn yThrou" : `최대 투숙기간 제한 사용 (체크인 기준)`,
    "zz02_0700.title.maxStayThrou" : `Use Maximum stay restriction(by stay date)`,
    "zz02_0700.title.maxStayThrouTooltip" : `Reservations cannot be made if the reservation exceeds the maximum stay based on the staying date.`,
    "zz02_0700.title.minAdultCov" : `Base Occupancy`,
    "zz02_0700.title.minLos" : `Use Minimum stay restriction(by check in date)`,
    "zz02_0700.title.minLosTooltip" : `Reservations cannot be made if the reservation is less than the minimum stay based on the check in date.`,
    "zz02_0700.title.minStayThrou" : `Use Minimum stay restriction(by stay date)`,
    "zz02_0700.title.minStayThrouTooltip" : `Reservations cannot be made if the reservation is less than the minimum stay based on the staying date.`,
    "zz02_0700.title.modify" : `Modify`,
    "zz02_0700.title.noArr" : `Use Restriction of Check In`,
    "zz02_0700.title.noArrTooltip" : `Reservations cannot be made to the set arrival date.`,
    "zz02_0700.title.noDep" : `Use Restriction of Check-out`,
    "zz02_0700.title.noDepTooltip" : `Reservations cannot be made to the set check-in date.`,
    "zz02_0700.title.phoneNumber" : `Phone`,
    "zz02_0700.title.pmsSetting" : `PMS Reservation Information`,
    "zz02_0700.title.printYn" : `No Rate Print`,
    "zz02_0700.title.propertyId" : `Property ID`,
    "zz02_0700.title.propertyName" : `Property Name`,
    "zz02_0700.title.radioOff" : `OFF`,
    "zz02_0700.title.radioOn" : `ON`,
    "zz02_0700.title.rateType" : `Rate Acquisition`,
    "zz02_0700.title.region" : `Country/Language`,
    "zz02_0700.title.requireRoom" : `Property Information ( Required)`,
    "zz02_0700.title.reservationConstraint" : `Restriction of Reservation Fucntion`,
    "zz02_0700.title.reservationPath" : `Source Type`,
    "zz02_0700.title.roomAddInfo" : `Property additional information`,
    "zz02_0700.title.roomInformation" : `Room Information (Required)`,
    "zz02_0700.title.roomName" : `Room Name`,
    "zz02_0700.title.roomNumber" : `Number of Rooms`,
    "zz02_0700.title.roomType" : `Room Type`,
    "zz02_0700.title.salesEmp" : `Sales Person`,
    "zz02_0700.title.sendRestrictRms" : `Use Bunker Room`,
    "zz02_0700.title.sendRestrictRmsTooltip" : `잔여 객실의 숫자를 임의로 지정하여 채널에 전송할때 사용하는 기능입니다. <br/>(* 예 : 현재 10개의 객실이 남아있으나, 판매채널의 마감임박 표시를 띄우기 위해 잔여객실을 3객실로 임의 전송) <br/>임의로 지정한 객실 수보다 실제 잔여객실이 적어지면 자동으로 실제 잔여 객실 수가 표현됩니다.`,
    "zz02_0700.title.serviceInfoImportApi" : `Would you like to retrieve the PMS Service Information?`,
    "zz02_0700.title.serviceInfomation" : `Service Information`,
    "zz02_0700.title.status" : `Status`,
    "zz02_0700.title.svc" : `Service Charge(%)`,
    "zz02_0700.title.syncFunc" : `PMS 잔여객실 동기화 사용`,
    "zz02_0700.title.syncFuncTooltip" : `잔여객실현황 화면에서 PMS 잔여객실 동기화 기능의 사용여부를 설정합니다.`,
    "zz02_0700.title.systemInit" : `System Reset`,
    "zz02_0700.title.systemManagementSetupInfo" : `System Administrator Setting`,
    "zz02_0700.title.systemStatus" : `System Status`,
    "zz02_0700.title.tax" : `VAT(%)`,
    "zz02_0700.title.taxAndSvc" : `VAT(%), Service Charge(%)`,
    "zz02_0700.title.totalRoomCnt" : `Total Rooms`,
    "zz02_0700.title.vatYn" : `Tax Setting`,
    "zz02_0700.title.weekendSetting" : `Weekend Setting`,
    "zz03_0100.btn.buttonTitleMoreView" : `More`,
    "zz03_0100.button.accept" : `Customer Service`,
    "zz03_0100.button.confirm" : `Confirm`,
    "zz03_0100.button.confirmNo" : `No`,
    "zz03_0100.button.confirmYes" : `Yes`,
    "zz03_0100.button.resend" : `Resend`,
    "zz03_0100.button.tutorial" : `Tutorial`,
    "zz03_0100.button.tutorialAdmin" : `Tutorial Manager`,
    "zz03_0100.etc.arrvDate" : `Arrival Date`,
    "zz03_0100.etc.channelReservationNumber" : `RSVN No(Ch)`,
    "zz03_0100.etc.cnPmName" : `Channel`,
    "zz03_0100.etc.hotelReservationNumber" : `RSVN No(Hotel)`,
    "zz03_0100.etc.inhtGestName" : `Guest Information`,
    "zz03_0100.etc.lastChngDt" : `Last Update`,
    "zz03_0100.etc.pmCmRsSendStatusCnName" : `Sending Result`,
    "zz03_0100.etc.tablecmRsNo" : `CMS#`,
    "zz03_0100.message.errorCall" : `Retrieve Failed.`,
    "zz03_0100.message.qChannelInfoCall" : `- Do you want to retrieve?`,
    "zz03_0100.message.resendAlertConfirmMessage" : `Resend is failed. (@(0)/3) <br/><br/>Please make manually an unscheduled reservation in PMS<br/>Click the [Send Error] button to send the error information to Sanhait.<br/>The collected data is used only to solve the problem.<br/>`,
    "zz03_0100.message.resendAlertMessage" : `Sending Result (@(0)/3) <br/><br/>System will attempt to send again after clicking [Resend].<br/>If you want to report error to Sanha, please click [Report to Sanha].<br/>Reported data will solely be used for troubleshooting.<br/>`,
    "zz03_0100.message.successCall" : `Retrieve Success.<br /> Go to Room Management.`,
    "zz03_0100.message.toolTipMessage" : `If the reservations cannot be sent to PMS from CMS,<br /> ‘Resend’ or ‘Fail’ will be activated.<br /> Please send the reservations to PMS.`,
    "zz03_0100.title.ReservationStatus" : `Real Time Reservations List`,
    "zz03_0100.title.apiCall" : `Sync`,
    "zz03_0100.title.channelName" : `Channel`,
    "zz03_0100.title.channelSync" : `Recent Channel Information Sync`,
    "zz03_0100.title.compareWeek" : `Compare with last 7 days`,
    "zz03_0100.title.currency" : `KRW`,
    "zz03_0100.title.dashboard" : `대시보드`,
    "zz03_0100.title.date" : `th`,
    "zz03_0100.title.errorEmailSuccess" : `Error status has been sent.`,
    "zz03_0100.title.friday" : `FRI`,
    "zz03_0100.title.home" : `Home`,
    "zz03_0100.title.important" : `Important`,
    "zz03_0100.title.monday" : `MON`,
    "zz03_0100.title.nightUnit" : `Nights`,
    "zz03_0100.title.noData" : `No Data`,
    "zz03_0100.title.noHistory" : `No Data`,
    "zz03_0100.title.normal" : `General`,
    "zz03_0100.title.notice" : `Notice`,
    "zz03_0100.title.openStatus" : `Connection Status`,
    "zz03_0100.title.popupSuccess" : `Success`,
    "zz03_0100.title.realTimeReservationList" : `Real Time Reservations List`,
    "zz03_0100.title.realTimeReservationListMeg" : `*Real time reservation list wiil be updated every 3 minutes from each channel.`,
    "zz03_0100.title.recent" : `Recent History`,
    "zz03_0100.title.recentSyncTime" : `Last Update`,
    "zz03_0100.title.resendMessage" : `<div style='text-align:center'>Data was not successfully transferred to the PMS.</span></div><br/><div>If you click the [Resend] button, it will send again.</div><div>When you click the [Customer Center] button, an e-mail is automatically sent to the customer center of SanhaIt.</div><br/><div>The requeset can be checked in the email.</div>`,
    "zz03_0100.title.resendMessage_1" : `Resend is failed. (@(1)/3) <br/><br/>Please make manually an unscheduled reservation in PMS<br/>Click the [Send Error] button to send the error information to Sanhait.<br/>The collected data is used only to solve the problem.<br/>`,
    "zz03_0100.title.reservationStatus" : `Today’s Sales`,
    "zz03_0100.title.revNo" : `Reservation`,
    "zz03_0100.title.revNumber" : `Reservation`,
    "zz03_0100.title.revNumberUnit" : ` `,
    "zz03_0100.title.saturday" : `SAT`,
    "zz03_0100.title.saveSuccess" : `URGENT`,
    "zz03_0100.title.successMessage" : `Reservation No.: @(0) has been sent to PMS`,
    "zz03_0100.title.sunday" : `SUN`,
    "zz03_0100.title.sync" : `Sync`,
    "zz03_0100.title.thursday" : `THU`,
    "zz03_0100.title.toDayADR" : `Today's ADR`,
    "zz03_0100.title.toDayLoDge" : `Today's Room Nights`,
    "zz03_0100.title.toDayReserVation" : `Today's Reservations `,
    "zz03_0100.title.toDaySales" : `Today’s Revenue`,
    "zz03_0100.title.totRev" : `Revenue`,
    "zz03_0100.title.total" : `All`,
    "zz03_0100.title.tuesday" : `TUE`,
    "zz03_0100.title.wednesday" : `WED`,
    "zz03_0100.title.weeklySaleList" : `Weekly Sales Report`,
    "zz03_0100.title.weeklySaleTop" : `Weekly Sales Report (TOP 3 Channels)`,
    "zz03_0210.button.init" : `Reset`,
    "zz03_0210.button.search" : `Search`,
    "zz03_0210.label.disposer" : `User`,
    "zz03_0210.label.division" : `Menu`,
    "zz03_0210.label.period" : `Process Date`,
    "zz03_0210.label.property" : `Property`,
    "zz03_0210.message.required" : `Please select required fields.`,
    "zz03_0210.title.detailLog" : `Detail`,
    "zz03_0210.title.disposer" : `User`,
    "zz03_0210.title.division" : `Menu`,
    "zz03_0210.title.gridDetailChannelName" : `Channel`,
    "zz03_0210.title.gridDetailDisposer" : `User`,
    "zz03_0210.title.gridDetailDisposerIp" : `User IP`,
    "zz03_0210.title.gridDetailHotelRoomName" : `Hotel Rooms`,
    "zz03_0210.title.gridDetailProcessDate" : `Process Date`,
    "zz03_0210.title.gridDetailProductGroupName" : `Product Group`,
    "zz03_0210.title.gridDetailPropertyName" : `Property`,
    "zz03_0210.title.gridDetailSaleDate" : `Sales Date`,
    "zz03_0210.title.gridDetailWorkLog" : `Detail`,
    "zz03_0210.title.pageTitle" : `Recent History`,
    "zz03_0210.title.processComment" : `Process Detail`,
    "zz03_0210.title.processDate" : `Date`,
    "zz03_0210.title.recentLog" : `Recent History`,
    "zz03_0400.button.list" : `List`,
    "zz03_0400.message.noCompany" : `No COMPANY ID. Please contact the Manager.`,
    "zz03_0400.title.friday" : `Fri`,
    "zz03_0400.title.impSortAsc" : `Sort by Importance( Normal> Urgent)`,
    "zz03_0400.title.impSortDesc" : `Sort by Importance( Urgent> Normal)`,
    "zz03_0400.title.important" : `Important`,
    "zz03_0400.title.monday" : `Mon`,
    "zz03_0400.title.next" : `Next`,
    "zz03_0400.title.noData" : `No Data`,
    "zz03_0400.title.normal" : `General`,
    "zz03_0400.title.notice" : `Notice`,
    "zz03_0400.title.prev" : `Previous text`,
    "zz03_0400.title.recentSort" : `Recent`,
    "zz03_0400.title.saturday" : `Sat`,
    "zz03_0400.title.saveSuccess" : `URGENT`,
    "zz03_0400.title.sunday" : `Sun`,
    "zz03_0400.title.thursday" : `Thu`,
    "zz03_0400.title.total" : `All`,
    "zz03_0400.title.tuesday" : `Tus`,
    "zz03_0400.title.wednesday" : `Wed`,
    "zz04_0000.btn.no" : `No`,
    "zz04_0000.btn.yes" : `Yes`,
    "zz04_0000.message.failAccessAuth" : `Incorrect authentication key. <br/> Please try again.`,
    "zz04_0000.message.title" : `There is information that you logged in. <br/> Would you like to continue after <span class='text-primary'>Log Out</span>?`,
    "zz04_0100.button.combineMember" : `Integrated Membership Registration`,
    "zz04_0100.message.multiProperty" : `If you have more than 2 Properties <span class='text-primary'>, You can set </span> at once.`,
    "zz04_0100.message.selectProperty" : `Please select the property to sign up.`,
    "zz04_0100.message.selectedProperty" : `Property Select...`,
    "zz04_0100.message.subTitle" : `Start with simultaneous use.`,
    "zz04_0100.message.title" : `Perfect compatibility with WINGS`,
    "zz04_0200.btn.joinComplete" : `Sign up complete`,
    "zz04_0200.btn.login" : `Login`,
    "zz04_0200.btn.passwordInit" : `Password Reset`,
    "zz04_0200.message.checkInput" : `Please write (  )`,
    "zz04_0200.message.chkEmail" : `Invalid Email.`,
    "zz04_0200.message.chkPassword" : `If password is 3 types of combination with numbers, alphabet and special characters, it must be at least 8 characters and cannot be exceeded more than 20 characters.`,
    "zz04_0200.message.chkPasswordConfirm" : `The password and password confirmation are not matched.`,
    "zz04_0200.message.chkUserId" : `User ID should be combined with numbers and 2 characters of English. Minimum 4 characters, Maximum 12 characters.`,
    "zz04_0200.message.companyId" : `Company ID`,
    "zz04_0200.message.email" : `Email`,
    "zz04_0200.message.password" : `Password`,
    "zz04_0200.message.passwordConfirm" : `Confirm Your Password`,
    "zz04_0200.message.phoneNumber" : `Mobile`,
    "zz04_0200.message.subWelcome" : `User Registeration should be approved.`,
    "zz04_0200.message.svc" : `Service(%)`,
    "zz04_0200.message.tax" : `VAT`,
    "zz04_0200.message.taxContain" : `VAT Included/Not included`,
    "zz04_0200.message.tooltip" : `Choose whether the VAT rate / service rate is divided when a reservation is made.`,
    "zz04_0200.message.userFirstName" : `User Name (Last)`,
    "zz04_0200.message.userId" : `User ID`,
    "zz04_0200.message.userInfo" : `user information`,
    "zz04_0200.message.userLastName" : `User Name (First)`,
    "zz04_0200.message.userName" : `user name`,
    "zz04_0200.title.accountExist" : `Do you already have an account?`,
    "zz04_0200.title.subWelcome" : `<span class='text-success'>Easily and quickly manage your online reservation</span> through simple membership registration.`,
    "zz04_0200.title.welcome" : `Welcome`,
    "zz04_0300.btn.joinComplete" : `Sign up complete`,
    "zz04_0300.btn.login" : `Login`,
    "zz04_0300.btn.passwordInit" : `Password Reset`,
    "zz04_0300.message.checkInput" : `Please write (  )`,
    "zz04_0300.message.chkEmail" : `Invalid Email.`,
    "zz04_0300.message.chkPassword" : `If password is 3 types of combination with numbers, alphabet and special characters, it must be at least 8 characters and cannot be exceeded more than 20 characters.`,
    "zz04_0300.message.chkPasswordConfirm" : `The password and password confirmation are not matched.`,
    "zz04_0300.message.chkUserId" : `User ID should be combined with numbers and 2 characters of English. Minimum 4 characters, Maximum 12 characters.`,
    "zz04_0300.message.companyId" : `Company ID`,
    "zz04_0300.message.email" : `Email`,
    "zz04_0300.message.password" : `Password`,
    "zz04_0300.message.passwordConfirm" : `Confirm Your Password`,
    "zz04_0300.message.phoneNumber" : `Mobile`,
    "zz04_0300.message.subWelcome" : `User Registeration should be approved.`,
    "zz04_0300.message.userFirstName" : `User Name (Last)`,
    "zz04_0300.message.userId" : `User ID`,
    "zz04_0300.message.userInfo" : `user information`,
    "zz04_0300.message.userLastName" : `User Name (First)`,
    "zz04_0300.message.userName" : `user name`,
    "zz04_0300.title.accountExist" : `Do you already have an account?`,
    "zz04_0300.title.subWelcome" : `<span class='text-success'>Easily and quickly manage your online reservation</span> through simple membership registration.`,
    "zz04_0300.title.welcome" : `Welcome`,
    "zz04_0400.btn.joinComplete" : `Sign up complete`,
    "zz04_0400.btn.login" : `Login`,
    "zz04_0400.btn.passwordInit" : `Password Reset`,
    "zz04_0400.message.checkInput" : `Please write (  )`,
    "zz04_0400.message.chkEmail" : `Invalid Email.`,
    "zz04_0400.message.chkPassword" : `If password is 3 types of combination with numbers, alphabet and special characters, it must be at least 8 characters and cannot be exceeded more than 15 characters.`,
    "zz04_0400.message.chkPasswordConfirm" : `The password and password confirmation are not matched.`,
    "zz04_0400.message.chkUserId" : `User ID should be combined with numbers and 2 characters of English. Minimum 4 characters, Maximum 12 characters.`,
    "zz04_0400.message.coperateNo" : `Corporate No. (Numbers Only)`,
    "zz04_0400.message.email" : `Email`,
    "zz04_0400.message.hotelName" : `Hotel Name`,
    "zz04_0400.message.nation" : `Country`,
    "zz04_0400.message.password" : `Password`,
    "zz04_0400.message.passwordConfirm" : `Confirm Your Password`,
    "zz04_0400.message.phoneNumber" : `Mobile`,
    "zz04_0400.message.subWelcome" : `User Registeration should be approved.`,
    "zz04_0400.message.userFirstName" : `User Name (Last)`,
    "zz04_0400.message.userId" : `User ID`,
    "zz04_0400.message.userInfo" : `user information`,
    "zz04_0400.message.userLastName" : `User Name (First)`,
    "zz04_0400.message.userName" : `User Name`,
    "zz04_0400.title.accountExist" : `Do you already have an account?`,
    "zz04_0400.title.subWelcome" : `<span class='text-success'>Easily and quickly manage your online reservation</span> through simple membership registration.`,
    "zz04_0400.title.welcome" : `Welcome`,
    "zz04_0500.btn.login" : `Login`,
    "zz04_0500.message.info01" : `All membership registration procedures are completed.`,
    "zz04_0500.message.info02" : `You can access  after <span class='text-success'>Approval</span> from System Manager.`,
    "zz04_0500.message.info03" : `We will inform you after approval via Email.`,
    "zz04_0500.message.joinComplate" : `You have succesfully joined.`,
    "zz04_0500.message.userInfo" : `user information`,
    "zz04_0500.title.companyId" : `Company ID`,
    "zz04_0500.title.email" : `Email`,
    "zz04_0500.title.hotelName" : `Hotel Name`,
    "zz04_0500.title.mobileNo" : `Phone`,
    "zz04_0500.title.userId" : `User ID`,
    "zz04_0500.title.userName" : `User Name`,
    "zz04_0600.btn.hotelSubInfo" : `Hotel Additional Information`,
    "zz04_0600.btn.login" : `Login`,
    "zz04_0600.message.info01" : `All membership registration procedures are completed.`,
    "zz04_0600.message.info02" : `Now you can use <span class = 'text-success'> Full Services </span>.`,
    "zz04_0600.message.info03" : `We will inform you after approval via Email.`,
    "zz04_0600.message.joinComplate" : `You have succesfully joined.`,
    "zz04_0600.message.recommend" : `[Recommend]`,
    "zz04_0600.message.subNotice" : `Enter additional information about our hotel <span class = 'd-sm-block' /> and try to manage more accurate data!!`,
    "zz04_0600.message.userInfo" : `user information`,
    "zz04_0600.title.companyId" : `Company ID`,
    "zz04_0600.title.email" : `Email`,
    "zz04_0600.title.hotelName" : `Hotel Name`,
    "zz04_0600.title.mobileNo" : `Phone`,
    "zz04_0600.title.userId" : `User ID`,
    "zz04_0600.title.userName" : `User Name`,
    "zz04_0700.btn.accept" : `Customer Service`,
    "zz04_0700.message.acceptComplate" : `Inquiry is received succesfully. We will contact hotel, once process is completed.`,
    "zz04_0700.message.notFoundMessage" : `<span class='text-primary'>[Customer Service]</span> After clicking the button, the system administrator will contact you <br />with the hotel representative number after review.`,
    "zz04_0700.message.notFoundProperty" : `There is no information for selected property.`,
    "zz10_0100.button.add" : `+ Add`,
    "zz10_0100.button.addPopup" : `Append`,
    "zz10_0100.button.attach" : `+ Attachment`,
    "zz10_0100.button.cancel" : `Cancel`,
    "zz10_0100.button.init" : `Initialization`,
    "zz10_0100.button.manage" : `Management`,
    "zz10_0100.button.save" : `Save`,
    "zz10_0100.button.search" : `Search`,
    "zz10_0100.etc.active" : `Active`,
    "zz10_0100.etc.all" : `All`,
    "zz10_0100.etc.inactive" : `Inactive`,
    "zz10_0100.message.attachRemove" : `Please try again afater delete the attachment`,
    "zz10_0100.message.channelReg" : `Create the channel first.`,
    "zz10_0100.message.commnetDescription" : `Any comment for connected channels? It won't be dislocsed to users. `,
    "zz10_0100.message.duplicationServcie" : `@(0) is a duplicate service code. <br /> Please change the service code.`,
    "zz10_0100.message.duplicationSource" : `@(0) is a duplicate source code. <br /> Please change the source code.`,
    "zz10_0100.message.inputChannelCode" : `Channel Code is required.`,
    "zz10_0100.message.inputChannelName" : `Saved.`,
    "zz10_0100.message.inputChannelNameRequire" : `Channel Name is required.`,
    "zz10_0100.message.inputEmail" : `Email is requried.`,
    "zz10_0100.message.inputInterLocking" : `Connection subject is required.`,
    "zz10_0100.message.inputManagerName" : `Contact name is required.`,
    "zz10_0100.message.inputRateMappingMethod" : `Rate mapping method is required`,
    "zz10_0100.message.inputRateTypeRequire" : `Inventory transmission by rate type is mandatory.`,
    "zz10_0100.message.inputRevApiBatch" : `Reservation API batch classification is required.`,
    "zz10_0100.message.inputServiceCode" : `Service Code is required.`,
    "zz10_0100.message.inputServiceName" : `Service Name is required.`,
    "zz10_0100.message.inputSourceCode" : `Source Code is required.`,
    "zz10_0100.message.inputSourceName" : `Source is required.`,
    "zz10_0100.message.inputSourceType" : `Source Type is required.`,
    "zz10_0100.message.inputSrcManageRequire" : `Source management is a mandatory value.`,
    "zz10_0100.message.inputSvcCodeRequire" : `Whether or not to use the service code is a mandatory value.`,
    "zz10_0100.message.inputUseLimitFunc" : `Resrtriction is required.`,
    "zz10_0100.message.limitUpload" : `Files that exceed 20MB cannot be uploaded.`,
    "zz10_0100.message.manualFile" : `Please upload the manual file first.`,
    "zz10_0100.message.noModify" : `No data has been changed.`,
    "zz10_0100.message.pmsCallSuccess" : `PMS Information is successfully loaded.`,
    "zz10_0100.message.qAttachRemove" : `Do you want to delete the attachment?`,
    "zz10_0100.message.rejectExt" : `Extension is unable to be uploaded.`,
    "zz10_0100.message.saveSuccess" : `Saved.`,
    "zz10_0100.message.searchChannelName" : `Channel Name B2B Account Management`,
    "zz10_0100.message.sourceApiCall" : `Would you like to bring PMS source information?`,
    "zz10_0100.message.urlDesription" : `Users can access through the registered administrator URL.`,
    "zz10_0100.title.addSetting" : `Additional Settings`,
    "zz10_0100.title.attachment" : `Attachment`,
    "zz10_0100.title.buyPrice" : `Net Rate`,
    "zz10_0100.title.call" : `Retrieve`,
    "zz10_0100.title.callUrl" : `Process call URL`,
    "zz10_0100.title.callUrlTest" : `Process call URL(TEST)`,
    "zz10_0100.title.channelCode" : `Channel Code`,
    "zz10_0100.title.channelName" : `Channel Name`,
    "zz10_0100.title.channelRate" : `Channel Allocation / Rate`,
    "zz10_0100.title.close" : `Exit`,
    "zz10_0100.title.comment" : `Comment`,
    "zz10_0100.title.contentType" : `Content Type`,
    "zz10_0100.title.download" : `Download`,
    "zz10_0100.title.interLocking" : `Connection subject`,
    "zz10_0100.title.interlocingAlarm" : `Connection alarm`,
    "zz10_0100.title.interlockingComplete" : `Connection complete`,
    "zz10_0100.title.interlockingStep" : `Connection Step`,
    "zz10_0100.title.interlockingTerminate" : `Disconnect`,
    "zz10_0100.title.item" : `Changed Item List`,
    "zz10_0100.title.managerEmail" : `Email`,
    "zz10_0100.title.managerInfo" : `Contact Information`,
    "zz10_0100.title.managerName" : `Contact Name`,
    "zz10_0100.title.managerPhone" : `Phone`,
    "zz10_0100.title.manual" : `Manual`,
    "zz10_0100.title.methodType" : `Method Type`,
    "zz10_0100.title.modify" : `Modify`,
    "zz10_0100.title.no" : `No`,
    "zz10_0100.title.openStep" : `Open stage`,
    "zz10_0100.title.operManage" : `Manage operation`,
    "zz10_0100.title.operSetting" : `Operation Setting`,
    "zz10_0100.title.payChannel" : `Pay at Channel`,
    "zz10_0100.title.payPlace" : `Hotel Pay`,
    "zz10_0100.title.paySetting" : `Payment Type`,
    "zz10_0100.title.priceGubun" : `Rate Classification`,
    "zz10_0100.title.processCall" : `Process Call`,
    "zz10_0100.title.rateMapMethod" : `Rate mapping method`,
    "zz10_0100.title.rateTransition" : `Channel allocation / Rate send URL(TEST)`,
    "zz10_0100.title.rateTransitionUrl" : `Channel allocation / Rate send URL`,
    "zz10_0100.title.rateType" : `Allocation send by rate type `,
    "zz10_0100.title.rateTypeSend" : `Allocation send by rate type `,
    "zz10_0100.title.revApiBatch" : `Reservation API batch classification`,
    "zz10_0100.title.revGubun" : `Reservation Type`,
    "zz10_0100.title.sellPrice" : `Sell Rate`,
    "zz10_0100.title.serviceCode" : `Service Code USE Y/N`,
    "zz10_0100.title.serviceCodeUse" : `Service Code USE Y/N`,
    "zz10_0100.title.serviceManage" : `Service Management`,
    "zz10_0100.title.serviceName" : `Service Name`,
    "zz10_0100.title.serviceNotUse" : `Service No Use`,
    "zz10_0100.title.serviceType" : `Service Type`,
    "zz10_0100.title.serviceUse" : `Service Use`,
    "zz10_0100.title.settingDate" : `Setting Date`,
    "zz10_0100.title.settingInfo" : `Operation setting information`,
    "zz10_0100.title.soapType" : `SOAP Type`,
    "zz10_0100.title.sortOrder" : `Sort Order`,
    "zz10_0100.title.sourceCode" : `Soruce Code`,
    "zz10_0100.title.sourceManage" : `Source Management`,
    "zz10_0100.title.sourceName" : `Source `,
    "zz10_0100.title.sourceType" : `Source Type`,
    "zz10_0100.title.status" : `Status`,
    "zz10_0100.title.storeManage" : `B2B Account Management`,
    "zz10_0100.title.testSettingInfo" : `Test Setting information`,
    "zz10_0100.title.url" : `Admin URL`,
    "zz10_0100.title.useLimitFunc" : `Restriction Function`,
    "zz10_0100.title.useOrNot" : `Status`,
    "zz10_0100.title.useYn" : `Status`,
    "zz10_0100.title.yes" : `Yes`,
    "zz10_0200.button.cancel" : `Cancel`,
    "zz10_0200.button.detail" : `Details`,
    "zz10_0200.button.init" : `Initialization`,
    "zz10_0200.button.remove" : `Delete`,
    "zz10_0200.button.save" : `Save`,
    "zz10_0200.button.search" : `Search`,
    "zz10_0200.etc.active" : `Active`,
    "zz10_0200.etc.all" : `All`,
    "zz10_0200.etc.inactive" : `Inactive`,
    "zz10_0200.etc.notUse" : `Inactive`,
    "zz10_0200.message.exceedData" : `Please input within 2,000 characters.`,
    "zz10_0200.message.inputPageUrl" : `You can save after Page URL is entered.`,
    "zz10_0200.message.noData" : `Please input @(0) the data.`,
    "zz10_0200.message.noModifyData" : `No data has been changed.`,
    "zz10_0200.message.saveSuccess" : `Saved.`,
    "zz10_0200.title.cancel" : `Cancel`,
    "zz10_0200.title.chName" : `Channel Name`,
    "zz10_0200.title.chinese" : `Chinese`,
    "zz10_0200.title.detail" : `Channel name Precaution`,
    "zz10_0200.title.english" : `English`,
    "zz10_0200.title.japanese" : `Japanese`,
    "zz10_0200.title.korean" : `Korean`,
    "zz10_0200.title.modify" : `Modify`,
    "zz10_0200.title.notice" : `Caution`,
    "zz10_0200.title.pageUrl" : `PAGE URL`,
    "zz10_0200.title.procStep" : `Connection Step`,
    "zz10_0200.title.useYn" : `Status`,
    "zz10_0200.title.vietnam" : `Vietnamese`,
    "zz10_0300.title.pageName" : `Clients List`,
    "zz10_0300.title.tag1PageName" : `Contract Status`,
    "zz10_0300.title.tag2PageName" : `Member Status`,
    "zz10_0300.title.tag3PageName" : `Channel connection approval process`,
    "zz10_0300.title.tag4PageName" : `CMS 라이선스 현황`,
    "zz10_0301.buttom.searchInit" : `Initialization`,
    "zz10_0301.buttom.searchSearch" : `Search`,
    "zz10_0301.button.contCancel" : `Cancel`,
    "zz10_0301.button.contExcel" : `Excel`,
    "zz10_0301.button.contSave" : `Save`,
    "zz10_0301.etc.gridAutoSelectItemAll" : `All`,
    "zz10_0301.etc.gridSelectItemN" : `No Use`,
    "zz10_0301.etc.gridSelectItemY" : `Use`,
    "zz10_0301.success.contSave" : `Contract Information is modified`,
    "zz10_0301.title.contActive" : `Actual Usage \n Y/N`,
    "zz10_0301.title.contAdditionContract" : `Additional Contract`,
    "zz10_0301.title.contArea" : `Region`,
    "zz10_0301.title.contChannelCnt" : `Number of contract channels`,
    "zz10_0301.title.contChannelEndDate" : `Use End date`,
    "zz10_0301.title.contChannelRegDate" : `Project Start Date`,
    "zz10_0301.title.contChannelStateDate" : `Channel connection Date`,
    "zz10_0301.title.contCompanyId" : `Company ID`,
    "zz10_0301.title.contCondition" : `Contract Terms and Condition`,
    "zz10_0301.title.contContractManager" : `Contract Manager`,
    "zz10_0301.title.contFreeChannel" : `Free Channel`,
    "zz10_0301.title.contLinkageChannelCnt" : `Connected \nNumber of Channels`,
    "zz10_0301.title.contModify" : `Modify`,
    "zz10_0301.title.contPms" : `PMS Use`,
    "zz10_0301.title.contPropertyName" : `Property Name`,
    "zz10_0301.title.contTerm" : `Contract Period`,
    "zz10_0301.title.contUsageStatus" : `Contract Status`,
    "zz10_0301.title.pageName" : `Clients List`,
    "zz10_0301.title.searchActive" : `Actual Usage Y/N`,
    "zz10_0301.title.searchProperty" : `Property Name`,
    "zz10_0301.title.sesrchContract" : `Contract Status`,
    "zz10_0301.title.sesrchContractAll" : `All`,
    "zz10_0301.title.sesrchContractN" : `No Use`,
    "zz10_0301.title.sesrchContractY" : `Use`,
    "zz10_0301.validation.fieldValid" : `Input the @(0).`,
    "zz10_0302.buttom.searchInit" : `Initialization`,
    "zz10_0302.buttom.searchSearch" : `Search`,
    "zz10_0302.button.PMSInfo" : `PMS Information`,
    "zz10_0302.button.PMSRegist" : `PMS Registration`,
    "zz10_0302.button.contActiveN" : `No Use`,
    "zz10_0302.button.contActiveY" : `Use`,
    "zz10_0302.button.contApprov" : `Approval`,
    "zz10_0302.button.contConnection" : `Connected`,
    "zz10_0302.button.contFailed" : `Fail`,
    "zz10_0302.button.contFailed_1" : `Connection Fail`,
    "zz10_0302.button.contReject" : `Reject`,
    "zz10_0302.button.popupCancel" : `Cancel`,
    "zz10_0302.button.popupInfoButtonN" : `No`,
    "zz10_0302.button.popupInfoButtonY" : `Yes`,
    "zz10_0302.button.popupPmsConnect" : `PMS Connction Status`,
    "zz10_0302.button.popupSave" : `Save`,
    "zz10_0302.etc.popupInfoTitle" : `Guide`,
    "zz10_0302.message.popupMessageApprov" : `Would you like to approve?`,
    "zz10_0302.message.popupMessageApprov_1" : `Would you like to approve the subscription of the checked account?`,
    "zz10_0302.message.popupMessageConnectionSuccess" : `Connection Successfully.`,
    "zz10_0302.message.popupMessagePmsSave" : `Do you want to save PMS information?`,
    "zz10_0302.message.popupMessageReject" : `Are you sure you want to process the rejection?`,
    "zz10_0302.message.popupMessageReject_1" : `Would you like to reject the subscription of the checked account?`,
    "zz10_0302.message.popupMessageconnectionFailed" : `Connection Failed.`,
    "zz10_0302.success.contRejectSave" : `Approval rejected.`,
    "zz10_0302.success.contSave" : `Approval completed.`,
    "zz10_0302.title.PMSInfo" : `PMS Information`,
    "zz10_0302.title.RegEmailAddr" : `Member Email Address`,
    "zz10_0302.title.RegId" : `Member ID`,
    "zz10_0302.title.RegPhoneNumber" : `Member Mobile`,
    "zz10_0302.title.approv" : `Joining Date`,
    "zz10_0302.title.approv_1" : `Complete Dtae`,
    "zz10_0302.title.connectionYn" : `Connection Y/N`,
    "zz10_0302.title.contCompanyId" : `Company ID`,
    "zz10_0302.title.contPms" : `PMS Use`,
    "zz10_0302.title.contPmsTpye" : `PMS Use`,
    "zz10_0302.title.contPmsTpye_1" : `PMS Type`,
    "zz10_0302.title.contPropertyName" : `Property Name`,
    "zz10_0302.title.contRegDate" : `Joining Date`,
    "zz10_0302.title.contRegDate_1" : `Registred Date`,
    "zz10_0302.title.filterProperty" : `Property Name`,
    "zz10_0302.title.filterRegEmail" : `Member Email Address`,
    "zz10_0302.title.filterRegId" : `Member ID`,
    "zz10_0302.title.filterRegName" : `Member Name`,
    "zz10_0302.title.filterRegPhone" : `Member Mobile`,
    "zz10_0302.title.filterStatus" : `Status`,
    "zz10_0302.title.firstRegName" : `First user name`,
    "zz10_0302.title.notApplicable" : `Not Applicable`,
    "zz10_0302.title.popupBizCode" : `BIZ_CODE`,
    "zz10_0302.title.popupCompanyId" : `COMPANY_ID`,
    "zz10_0302.title.popupDbInfo" : `DB Information`,
    "zz10_0302.title.popupIp" : `IP`,
    "zz10_0302.title.popupPmsInfo" : `PMS Information`,
    "zz10_0302.title.popupPropertyNo" : `PROPERTY_NO`,
    "zz10_0302.title.popupPwd" : `PWD`,
    "zz10_0302.title.popupSid" : `SID`,
    "zz10_0302.title.popupTitle" : `Property Name Information Register`,
    "zz10_0302.title.popupTitle_1" : `@(0) Information Registration`,
    "zz10_0302.title.popupUserId" : `USER_ID`,
    "zz10_0302.title.popupportNo" : `PORT NO`,
    "zz10_0302.title.searchProperty" : `Property Name`,
    "zz10_0302.title.sesrchStatus" : `Status`,
    "zz10_0302.title.status" : `Status`,
    "zz10_0302.vaildation.popupVaildEmptyInput" : `DB information fields are empty.`,
    "zz10_0302.validation.popupInvalid" : `Data is empty.`,
    "zz10_0303.buttom.searchInit" : `Initialization`,
    "zz10_0303.buttom.searchSearch" : `Search`,
    "zz10_0303.title.contChannelCnt" : `Number of contract channels`,
    "zz10_0303.title.contChannelEndDate" : `Use End day`,
    "zz10_0303.title.contChannelStateDate" : `First channel connection date`,
    "zz10_0303.title.contCondition" : `Contract Terms and Condition`,
    "zz10_0303.title.contLinkageChannelCnt" : `Connected \nNumber of Channels`,
    "zz10_0303.title.contPms" : `PMS`,
    "zz10_0303.title.contPropertyName" : `Property Name`,
    "zz10_0303.title.searchProperty" : `Property Name`,
    "zz10_0303.title.sesrchContract" : `Status`,
    "zz10_0304.button.contCancel" : `취소`,
    "zz10_0304.etc.gridSelectItemY" : `사용`,
    "zz10_0304.message.noModifyData" : `변경된 데이터가 없습니다.`,
    "zz10_0304.message.saveSuccess" : `저장 되었습니다.`,
    "zz10_0304.title.all" : `전체`,
    "zz10_0304.title.closeDate" : `해지일자`,
    "zz10_0304.title.contArea" : `지역`,
    "zz10_0304.title.contChannelCnt" : `계약 채널수`,
    "zz10_0304.title.contCondition" : `계약조건`,
    "zz10_0304.title.contModify" : `수정`,
    "zz10_0304.title.contPropertyName" : `프로퍼티명`,
    "zz10_0304.title.contractDate" : `계약일자`,
    "zz10_0304.title.count" : `순번`,
    "zz10_0304.title.menuName" : `CMS 라이선스 현황`,
    "zz10_0304.title.openChannelDate" : `최초 연동 일자`,
    "zz10_0304.title.pmCode" : `호텔 코드`,
    "zz10_0304.title.pmsUse" : `PMS 사용여부`,
    "zz10_0304.title.remark" : `비고`,
    "zz10_0304.title.searchActive" : `운영상태`,
    "zz10_0304.title.searchProperty" : `호텔명`,
    "zz10_0304.title.sesrchContract" : `계약상태`,
    "zz10_0304.title.version" : `버전`,
    "zz10_0304.title.versionGubun" : `버전구분`,
    "zz10_0400.button.agreement" : `Approval`,
    "zz10_0400.button.cancel" : `Cancel`,
    "zz10_0400.button.detail" : `Details`,
    "zz10_0400.button.init" : `Initialization`,
    "zz10_0400.button.management" : `Management`,
    "zz10_0400.button.save" : `Save`,
    "zz10_0400.button.search" : `Search`,
    "zz10_0400.button.totalAgreement" : `Collective Approval`,
    "zz10_0400.message.agreeRequest" : `Approved.`,
    "zz10_0400.message.inputChannelName" : `Channel Name is required.`,
    "zz10_0400.message.inputId" : `아이디 값이 없습니다.`,
    "zz10_0400.message.inputPwd" : `비밀번호 값이 없습니다.`,
    "zz10_0400.message.noRequest" : `Please select the data that requires the approval.`,
    "zz10_0400.message.openStopConfirm" : `@(0) <br />@(1) (@(2)) <br />@(3) 하시겠습니까?`,
    "zz10_0400.message.saveSuccess" : `Saved.`,
    "zz10_0400.message.successProcess" : `정상 처리 되었습니다.`,
    "zz10_0400.title.addContract" : `Additional Contract`,
    "zz10_0400.title.addRegistration" : `Additional Registration form`,
    "zz10_0400.title.all" : `All`,
    "zz10_0400.title.channelAgreeProcess" : `Channel Extranet`,
    "zz10_0400.title.channelInfo" : `Channel batch setting`,
    "zz10_0400.title.channelName" : `Channel Name`,
    "zz10_0400.title.companyId" : `Company ID`,
    "zz10_0400.title.contractChannel" : `Number of contract channels`,
    "zz10_0400.title.contractDownload" : `Download Contract`,
    "zz10_0400.title.download" : `Contract Download`,
    "zz10_0400.title.extranetId" : `Extranet ID`,
    "zz10_0400.title.extranetPwd" : `Extranet PW`,
    "zz10_0400.title.information" : `Information`,
    "zz10_0400.title.interlockingCancel" : `Cancel connection`,
    "zz10_0400.title.interlockingComplete" : `Connection Complete`,
    "zz10_0400.title.interlockingCount" : ` Number of connected channels`,
    "zz10_0400.title.interlockingProgress" : `Connection Processing`,
    "zz10_0400.title.interlockingRelease" : `Disconnect`,
    "zz10_0400.title.no" : `아니오`,
    "zz10_0400.title.openStop" : `연동 중단`,
    "zz10_0400.title.propertyName" : `Property Name`,
    "zz10_0400.title.regDate" : `Submit Date`,
    "zz10_0400.title.regEmail" : `Submitter Email`,
    "zz10_0400.title.regName" : `Submitter `,
    "zz10_0400.title.regPhone" : `Submitter Mobile`,
    "zz10_0400.title.remainChannel" : `Remaining Channels`,
    "zz10_0400.title.status" : `Status`,
    "zz10_0400.title.yes" : `예`,
    "zz10_0401.button.download" : `Download`,
    "zz10_0401.title.addChannel" : `※ Addtional Channels`,
    "zz10_0401.title.addChannelLock" : `CMS Additional Channel Connection`,
    "zz10_0401.title.addChannelLock1" : `- PMS Reservation Connection Establishment`,
    "zz10_0401.title.addChannelLock2" : `- Synchronize OTA Rates and Room Quotas`,
    "zz10_0401.title.addChannelLock3" : `- Open and Stabilized`,
    "zz10_0401.title.addChannelLock4" : `- Operation Support`,
    "zz10_0401.title.addChannelRate" : `※ Addtional Channel Connection Cost`,
    "zz10_0401.title.addContractApplication" : `Application for additional channel connection`,
    "zz10_0401.title.agreement" : `After checking the above, I agree to the application for additional connection. (Agree)`,
    "zz10_0401.title.amount" : `Quantity`,
    "zz10_0401.title.applicationDate" : `Application Date`,
    "zz10_0401.title.applicationUnit" : ` (Unit: KRW, VAT included)`,
    "zz10_0401.title.channelApplicationInfo" : `※ Channel Conection Applicant Information`,
    "zz10_0401.title.channelName" : `※ Channel Name`,
    "zz10_0401.title.contractDownloadViewer" : `Preview of additional channel connection application`,
    "zz10_0401.title.fileName" : `Application for additional channel connection`,
    "zz10_0401.title.gubun" : `Category`,
    "zz10_0401.title.history" : `History`,
    "zz10_0401.title.price" : `Price`,
    "zz10_0401.title.ratePerMonth" : `Monthly Billing`,
    "zz10_0401.title.remark" : `Remarks`,
    "zz10_0401.title.roomName" : `Accommodation Name`,
    "zz10_0401.title.sum" : `Total`,
    "zz10_0401.title.unitPrice" : `Unit Price`,
    "zz10_0600.title.cmsChannel" : `CMS-channel connection`,
    "zz10_0600.title.cmsPms" : `CMS-PMS connection`,
    "zz10_0600.title.history" : `API Service control`,
    "zz10_0610.buttom.init" : `Initialization`,
    "zz10_0610.buttom.search" : `Search`,
    "zz10_0610.button.buttonN" : `No`,
    "zz10_0610.button.buttonY" : `Yes`,
    "zz10_0610.button.popupRequestBtn" : `Request`,
    "zz10_0610.etc.required" : `Required`,
    "zz10_0610.message.repairMessage" : `Would you like to recover?`,
    "zz10_0610.success.buttonMessageSuccess" : `Restore is completed succesfully.`,
    "zz10_0610.success.popupMessageSuccessRequest" : `connection Stop Request was succesfull`,
    "zz10_0610.title.gridHeaderChannelName" : `Property`,
    "zz10_0610.title.gridHeaderLinkageChange" : `connection Stop Request`,
    "zz10_0610.title.gridHeaderLinkageStatus" : `Connection Status`,
    "zz10_0610.title.gridHeaderLinkageStopPeriod" : `Connection stop period`,
    "zz10_0610.title.gridHeaderStatusChange" : `Status Change`,
    "zz10_0610.title.gridHeaderStop" : `Stop`,
    "zz10_0610.title.popupChannelName" : `Channel`,
    "zz10_0610.title.popupPeriod" : `Period`,
    "zz10_0610.title.popupPropertyName" : `Property`,
    "zz10_0610.title.popupStopReason" : `Reason`,
    "zz10_0610.title.searchActiveAll" : `All`,
    "zz10_0610.title.searchActiveN" : `Connection Stop`,
    "zz10_0610.title.searchActiveY" : `Normal Connection`,
    "zz10_0610.title.searchActiveYn" : `Connection Status`,
    "zz10_0610.title.searchChannel" : `Channel`,
    "zz10_0610.title.searchProperty" : `Property`,
    "zz10_0610.validation.popupMessageWarningReaon" : `Please enter the reason.`,
    "zz10_0620.buttom.init" : `Initialization`,
    "zz10_0620.buttom.search" : `Search`,
    "zz10_0620.button.buttonN" : `No`,
    "zz10_0620.button.buttonY" : `Yes`,
    "zz10_0620.button.popupRequestBtn" : `Request`,
    "zz10_0620.etc.required" : `Required`,
    "zz10_0620.message.repairMessage" : `Would you like to recover?`,
    "zz10_0620.success.buttonMessageSuccess" : `Restore is completed succesfully.`,
    "zz10_0620.success.popupMessageSuccessRequest" : `connection Stop Request was succesfull`,
    "zz10_0620.title.gridHeaderChannelName" : `Channel`,
    "zz10_0620.title.gridHeaderLinkageChange" : `connection Stop Request`,
    "zz10_0620.title.gridHeaderLinkageStatus" : `Connection Status`,
    "zz10_0620.title.gridHeaderLinkageStopPeriod" : `Connection stop period`,
    "zz10_0620.title.gridHeaderStop" : `Stop`,
    "zz10_0620.title.popupChannelName" : `Channel`,
    "zz10_0620.title.popupPeriod" : `Period`,
    "zz10_0620.title.popupPropertyName" : `Property Name`,
    "zz10_0620.title.popupStopReason" : `Reason`,
    "zz10_0620.title.searchActiveAll" : `All`,
    "zz10_0620.title.searchActiveN" : `Connection Stop`,
    "zz10_0620.title.searchActiveY" : `Normal Connection`,
    "zz10_0620.title.searchActiveYn" : `Connection Status`,
    "zz10_0620.title.searchProperty" : `Channel`,
    "zz10_0620.validation.popupMessageWarningReaon" : `Please enter the reason.`,
    "zz10_1600.msg.user_created" : `User is created.`,
    "zz10_1600.msg.user_deleted" : `User is deleted.`,
    "zz11_0100.button.detailCodeCancel" : `Cancel`,
    "zz11_0100.button.detailCodeSuccess" : `Save`,
    "zz11_0100.button.detailUseN" : `No`,
    "zz11_0100.button.detailUseY" : `Yes`,
    "zz11_0100.button.initCode" : `Initialization`,
    "zz11_0100.button.masterCodeCancel" : `Cancel`,
    "zz11_0100.button.masterCodeSuccess" : `Save`,
    "zz11_0100.button.masterUse" : `Status`,
    "zz11_0100.button.masterUseN" : `No`,
    "zz11_0100.button.masterUseY" : `Yes`,
    "zz11_0100.button.popupCodeDivMove" : `Yes`,
    "zz11_0100.button.popupCodeDivStop" : `No`,
    "zz11_0100.button.searchCode" : `Search`,
    "zz11_0100.button.use" : `Status`,
    "zz11_0100.button.useAll" : `All`,
    "zz11_0100.button.useN" : `Inactive`,
    "zz11_0100.button.useY" : `Active`,
    "zz11_0100.etc.itemAll" : `All`,
    "zz11_0100.message.popupCodeDivBody" : `Data has changed. Would you like to ignore the changed data?`,
    "zz11_0100.success.saveMessage" : `The master code has been saved.`,
    "zz11_0100.title.codeDiv" : `Type Code`,
    "zz11_0100.title.codeDivCodeDiv" : `Type Code`,
    "zz11_0100.title.codeDivCodeDivName" : `Type Code Name`,
    "zz11_0100.title.detailCode" : `Detailed Code`,
    "zz11_0100.title.detailCodeName" : `Detailed Code Name`,
    "zz11_0100.title.gridCode" : `Code`,
    "zz11_0100.title.gridCodeName" : `Code Name`,
    "zz11_0100.title.gridCol01" : `Column1`,
    "zz11_0100.title.gridCol02" : `Column2`,
    "zz11_0100.title.gridCol03" : `Column3`,
    "zz11_0100.title.gridCol04" : `Column4`,
    "zz11_0100.title.gridCol05" : `Column5`,
    "zz11_0100.title.gridCol06" : `Column6`,
    "zz11_0100.title.gridCol07" : `Column7`,
    "zz11_0100.title.gridCol08" : `Column8`,
    "zz11_0100.title.gridCol09" : `Column9`,
    "zz11_0100.title.gridCol10" : `Column10`,
    "zz11_0100.title.gridModify" : `Modify`,
    "zz11_0100.title.gridOrder" : `Order`,
    "zz11_0100.title.gridUse" : `Status`,
    "zz11_0100.title.masterCode" : `Master Code`,
    "zz11_0100.title.masterCodeCmt" : `Code detail`,
    "zz11_0100.title.masterCodeDiv" : `Type Code`,
    "zz11_0100.title.masterCodeName" : `Type Code Name`,
    "zz11_0100.title.masterUpperCodeDiv" : `Upper Code`,
    "zz11_0100.title.materCode" : `Master Code`,
    "zz11_0100.title.popupCode" : `Code`,
    "zz11_0100.title.popupCodeDivTitle" : `Warning Window`,
    "zz11_0100.title.popupCodeName" : `Code Name`,
    "zz11_0100.title.popupCol01" : `Column1`,
    "zz11_0100.title.popupCol02" : `Column2`,
    "zz11_0100.title.popupCol03" : `Column3`,
    "zz11_0100.title.popupCol04" : `Column4`,
    "zz11_0100.title.popupCol05" : `Column5`,
    "zz11_0100.title.popupCol06" : `Column6`,
    "zz11_0100.title.popupCol07" : `Column7`,
    "zz11_0100.title.popupCol08" : `Column8`,
    "zz11_0100.title.popupCol09" : `Column9`,
    "zz11_0100.title.popupCol10" : `Column10`,
    "zz11_0100.title.popupOrder" : `Order`,
    "zz11_0100.title.popupUse" : `Status`,
    "zz11_0100.title.programManagement" : `Common Code Management`,
    "zz11_0200.message.noModify" : `No data has been changed.`,
    "zz11_0200.message.success" : `Processed succesfully.`,
    "zz11_0200.title.authGroup" : `User Authority Group`,
    "zz11_0200.title.authManagement" : `Authorized Group Management`,
    "zz11_0200.title.cancel" : `Cancel`,
    "zz11_0200.title.cert" : `Cert`,
    "zz11_0200.title.excel" : `Excel`,
    "zz11_0200.title.menuAuth" : `Menu Authority`,
    "zz11_0200.title.menuName" : ` System Menu `,
    "zz11_0200.title.print" : `Print`,
    "zz11_0200.title.save" : `Save`,
    "zz11_0200.title.search" : `Search`,
    "zz11_0300.button.cancel" : `Cancel`,
    "zz11_0300.button.init" : `Initialization`,
    "zz11_0300.button.save" : `Save`,
    "zz11_0300.button.search" : `Search`,
    "zz11_0300.etc.notUse" : `Inactive`,
    "zz11_0300.etc.use" : `Active`,
    "zz11_0300.message.idValid" : `No Program ID value.`,
    "zz11_0300.message.modifyData" : `There is changed data. Please save it first.`,
    "zz11_0300.message.nameValid" : `No Program Name Value`,
    "zz11_0300.message.noModifyData" : `No data has been changed.`,
    "zz11_0300.message.programManage" : `Program Management`,
    "zz11_0300.message.saveSuccess" : `Saved.`,
    "zz11_0300.message.typeValid" : `No Program type value.`,
    "zz11_0300.title.modify" : `Modify`,
    "zz11_0300.title.programId" : `Program ID`,
    "zz11_0300.title.programIdName" : `Program name/ID`,
    "zz11_0300.title.programName" : `Program Name`,
    "zz11_0300.title.programType" : `Program Type`,
    "zz11_0300.title.programUrl" : `Program URL`,
    "zz11_0300.title.useYn" : `Status`,
    "zz11_0400.button.attach" : `+ Attachment`,
    "zz11_0400.button.initial" : `Initialization`,
    "zz11_0400.button.manage" : `Management`,
    "zz11_0400.button.save" : `Save`,
    "zz11_0400.button.search" : `Search`,
    "zz11_0400.button.tutorial" : `Tutorial Setup`,
    "zz11_0400.message.attachRemove" : `Please try again afater delete the attachment`,
    "zz11_0400.message.channelReg" : `Create the channel first.`,
    "zz11_0400.message.existChild" : `Menus with sub-menus cannot be changed to page type.`,
    "zz11_0400.message.limitUpload" : `Files that exceed 20MB cannot be uploaded.`,
    "zz11_0400.message.manualFile" : `Please upload the manual file first.`,
    "zz11_0400.message.manualReg" : `Please upload the manual first.`,
    "zz11_0400.message.menuMove" : `Do you want to move the menu(or folder)?`,
    "zz11_0400.message.notMoveMenu" : `It is not possible to move the page sub menu.`,
    "zz11_0400.message.qAttachRemove" : `Do you want to delete the attachment?`,
    "zz11_0400.message.rejectExt" : `Extension is unable to be uploaded.`,
    "zz11_0400.message.requireName" : `Please insert the title of menu.`,
    "zz11_0400.message.requirePath" : `Please enter the program path.`,
    "zz11_0400.message.requireType" : `Please enter the menu category.`,
    "zz11_0400.message.success" : `Processed succesfully.`,
    "zz11_0400.title.cancel" : `Cancel`,
    "zz11_0400.title.chinese" : `Chinese`,
    "zz11_0400.title.close" : `Exit`,
    "zz11_0400.title.english" : `English`,
    "zz11_0400.title.gubun" : `Menu Category`,
    "zz11_0400.title.icon" : `Icon`,
    "zz11_0400.title.inputMultiLang" : `Please input a multillingual`,
    "zz11_0400.title.japanese" : `Japanese`,
    "zz11_0400.title.korean" : `Korean`,
    "zz11_0400.title.language" : `Multillingual`,
    "zz11_0400.title.manual" : `Manual`,
    "zz11_0400.title.manualTitle" : `Set Title of Menu`,
    "zz11_0400.title.menuInfo" : `Menu Information`,
    "zz11_0400.title.menuManagement" : `System Menu Management`,
    "zz11_0400.title.menuName" : `Title of Menu`,
    "zz11_0400.title.no" : `No`,
    "zz11_0400.title.noIcon" : `No Icon`,
    "zz11_0400.title.noPath" : `No Path`,
    "zz11_0400.title.notUse" : `Inactive`,
    "zz11_0400.title.path" : `Program Path`,
    "zz11_0400.title.total" : `All`,
    "zz11_0400.title.tree" : `Menu Tree`,
    "zz11_0400.title.upperMenu" : `Upper Menu`,
    "zz11_0400.title.use" : `Active`,
    "zz11_0400.title.useYn" : `Status`,
    "zz11_0400.title.yes" : `Yes`,
    "zz11_0401.label.inputUse" : `Use Y/N`,
    "zz11_0401.label.tutorialComment" : `Remark`,
    "zz11_0401.label.tutorialTitle" : `Name of Tutorial`,
    "zz11_0401.success.saveMessage" : `Tutorial information has been saved successfully.`,
    "zz11_0401.title.chinese" : `중국어`,
    "zz11_0401.title.english" : `영어`,
    "zz11_0401.title.gridUse" : `Use Y/N`,
    "zz11_0401.title.japanese" : `일본어`,
    "zz11_0401.title.korean" : `한국어`,
    "zz11_0401.title.language" : `다국어`,
    "zz11_0401.title.materCode" : `Tutorial`,
    "zz11_0401.title.sortOrder" : `Order`,
    "zz11_0401.title.subTitle" : `Step setting`,
    "zz11_0401.title.teamName" : `Group Name`,
    "zz11_0600.title.activeYn" : `USE Y/N`,
    "zz11_0600.title.cancel" : `Cancel`,
    "zz11_0600.title.companyId" : `Company ID`,
    "zz11_0600.title.dormancyStatus" : `Status`,
    "zz11_0600.title.email" : `Email`,
    "zz11_0600.title.emailSuccessMessage" : `<div> A password reset email has been sent to the email address of the user. </div> <div> Please reset a password via the email </div>`,
    "zz11_0600.title.emailSuccessMessage_1" : `<div> A password reset email has been sent to @(0). </div> <div> Please reset your password via email </div>`,
    "zz11_0600.title.history" : `History`,
    "zz11_0600.title.initialize" : `Initialization`,
    "zz11_0600.title.joinDate" : `Signup Date`,
    "zz11_0600.title.joinEmail" : `User Email Address`,
    "zz11_0600.title.joinMobileNo" : `User Mobile`,
    "zz11_0600.title.joinPeriod" : `Signup Date`,
    "zz11_0600.title.mobileNo" : `Mobile`,
    "zz11_0600.title.moreInfo" : `More`,
    "zz11_0600.title.moreInfo_1" : `View`,
    "zz11_0600.title.no" : `No`,
    "zz11_0600.title.optionAll" : `All`,
    "zz11_0600.title.optionsNotUsed" : `No Use`,
    "zz11_0600.title.optionsUsed" : `Use`,
    "zz11_0600.title.passwordInit" : `Password Reset`,
    "zz11_0600.title.passwordSend" : `Send`,
    "zz11_0600.title.passwordSend_1" : `Reset`,
    "zz11_0600.title.paswordInitMessage" : `Do you reset the user password?`,
    "zz11_0600.title.property" : `Property`,
    "zz11_0600.title.propertyName" : `Property`,
    "zz11_0600.title.save" : `Save`,
    "zz11_0600.title.search" : `Search`,
    "zz11_0600.title.userControl" : `User Management`,
    "zz11_0600.title.userId" : `User ID`,
    "zz11_0600.title.userName" : `User Name`,
    "zz11_0600.title.yes" : `Yes`,
    "zz11_0600.validation.emailValidationMessage" : `There is no email in the account or the email format is incorrect.`,
    "zz11_0601.title.changeOptions" : `Changed Item List`,
    "zz11_0601.title.close" : `Close`,
    "zz11_0601.title.controlDate" : `Setting Date`,
    "zz11_0601.title.history" : `History`,
    "zz11_0601.title.messageBlank" : `Space`,
    "zz11_0601.title.userName" : `user name`,
    "zz11_0700.button.view" : `보기`,
    "zz11_0700.title.all" : `전체`,
    "zz11_0700.title.companyId" : `컴퍼니 ID`,
    "zz11_0700.title.connectHotel" : `연결된 호텔`,
    "zz11_0700.title.email" : `이메일`,
    "zz11_0700.title.propertyName" : `프로퍼티명`,
    "zz11_0700.title.useYn" : `사용여부`,
    "zz11_0700.title.userId" : `사용자 ID`,
    "zz11_0700.title.userName" : `사용자명`,
    "zz11_0700.title.userPmAuth" : `호텔 권한 관리`,
    "zz11_0701.message.condition" : `COMPANY ID, 프로퍼티 ID, 프로퍼티명`,
    "zz11_0701.message.saveSuccess" : `저장 되었습니다.`,
    "zz11_0701.title.companyId" : `컴퍼니 ID`,
    "zz11_0701.title.hotelAuthReg" : `호텔 권한 등록`,
    "zz11_0701.title.pmCode" : `프로퍼티 ID`,
    "zz11_0701.title.pmName" : `프로퍼티명`,
    "zz11_0701.title.save" : `저장`,
    "zz12_0100.title.emailAlertSet" : `Email alarm setting`,
    "zz12_0100.title.joinList" : `Request List`,
    "zz12_0100.title.sendFormat" : `Sending Format`,
    "zz12_0100_1.message.noMoreFormat" : `There is no email form to add.`,
    "zz12_0100_1.title.activeYn" : `USE Y/N`,
    "zz12_0100_1.title.all" : `All`,
    "zz12_0100_1.title.buttonAdd" : `+ Add`,
    "zz12_0100_1.title.buttonFile" : `+ Attachment`,
    "zz12_0100_1.title.cancel" : `Cancel`,
    "zz12_0100_1.title.content" : `Content`,
    "zz12_0100_1.title.contentFile" : `Attachment`,
    "zz12_0100_1.title.distribute" : `Division`,
    "zz12_0100_1.title.fileSizeOver" : `Files that exceed 10MB cannot be uploaded.`,
    "zz12_0100_1.title.initalize" : `Initialization`,
    "zz12_0100_1.title.innerMemo" : `Innermeno`,
    "zz12_0100_1.title.inputCheck" : ` Please input ~`,
    "zz12_0100_1.title.noUse" : `No Use`,
    "zz12_0100_1.title.primary" : `Required`,
    "zz12_0100_1.title.save" : `Save`,
    "zz12_0100_1.title.saveSuccess" : `Saved.`,
    "zz12_0100_1.title.search" : `Search`,
    "zz12_0100_1.title.settingDate" : `Setting Date`,
    "zz12_0100_1.title.settingEndDate" : `Settubg End Date`,
    "zz12_0100_1.title.settingStartDate" : `Settubg Start Date`,
    "zz12_0100_1.title.title" : `Subject`,
    "zz12_0100_1.title.unKnownExt" : `Extension is unable to be uploaded.`,
    "zz12_0100_1.title.use" : `Use`,
    "zz12_0100_2.message.success" : `정상적으로 등록되었습니다.`,
    "zz12_0100_2.title.activeYn" : `USE Y/N`,
    "zz12_0100_2.title.afterData" : `After Change`,
    "zz12_0100_2.title.all" : `All`,
    "zz12_0100_2.title.beforeData" : `Before Change`,
    "zz12_0100_2.title.blank" : `Blank`,
    "zz12_0100_2.title.businessName" : `Business Name`,
    "zz12_0100_2.title.cancel" : `Cancel`,
    "zz12_0100_2.title.changeHistory" : `Modification History`,
    "zz12_0100_2.title.changeItem" : `Changed Item List`,
    "zz12_0100_2.title.changeMessage" : `@(0) has been changed from '@ (1)' to '@ (2)'.`,
    "zz12_0100_2.title.chargeName" : `Name`,
    "zz12_0100_2.title.close" : `Close`,
    "zz12_0100_2.title.createSendType" : `+ Creat New Transmission Type`,
    "zz12_0100_2.title.createService" : `+ Creat New Service Code`,
    "zz12_0100_2.title.emailAddress" : `Email Address`,
    "zz12_0100_2.title.emailType" : `Email Type`,
    "zz12_0100_2.title.export" : `Export`,
    "zz12_0100_2.title.history" : `History`,
    "zz12_0100_2.title.import" : `Import`,
    "zz12_0100_2.title.inputCheck" : ` Please input ~`,
    "zz12_0100_2.title.intialize" : `Initialization`,
    "zz12_0100_2.title.modify" : `Modify`,
    "zz12_0100_2.title.more" : `More`,
    "zz12_0100_2.title.noUse" : `No Use`,
    "zz12_0100_2.title.noneData" : `Space`,
    "zz12_0100_2.title.noneServiceCode" : `No Service Code.`,
    "zz12_0100_2.title.primary" : `Required`,
    "zz12_0100_2.title.save" : `Save`,
    "zz12_0100_2.title.search" : `Search`,
    "zz12_0100_2.title.sendType" : `Send Format`,
    "zz12_0100_2.title.serviceCode" : `Service Code`,
    "zz12_0100_2.title.settingDate" : `Setting Date`,
    "zz12_0100_2.title.settingPeriod" : `Setting Period`,
    "zz12_0100_2.title.use" : `Use`,
    "zz12_0100_2.title.userId" : `user ID`,
    "zz12_0100_2.valid.emailFormatter" : `Email address form is wrong.`,
    "zz13_0100.title.dataTransferHistory" : `데이터 전송`,
    "zz13_0100.title.history" : `History`,
    "zz13_0100.title.residualRoomChange" : `잔여객실변경`,
    "zz13_0100.title.workHistory" : `작업내역`,
    "zz13_0500.message.frequentlyQuestion" : `FAQ`,
    "zz13_0500.message.moveDirectMenu" : `Direct Link`,
    "zz13_0500.message.moveMenu" : `Select the related menus`,
    "zz13_0500.message.noData" : `No data is viewed.`,
    "zz13_0600.message.agreeExtension" : `허용된 업로드 확장자가 아닙니다.`,
    "zz13_0600.message.agreeSize" : `허용된 이미지 사이즈를 초과했습니다.`,
    "zz13_0600.message.inputContent" : `Please input @(0) the contents.`,
    "zz13_0600.message.inputDataEmpty" : `@(0) Data is empty.`,
    "zz13_0600.message.inputQna" : `Please enter the information to be registered.`,
    "zz13_0600.message.inputSubject" : `Please input @(0) the title.`,
    "zz13_0600.message.inputSystemMenu" : `Please select the Connection Menu`,
    "zz13_0600.message.multiLangEmpty" : `다국어가 비어 있습니다.`,
    "zz13_0600.message.saveSuccess" : `Save`,
    "zz13_0600.title.add" : `+ Add`,
    "zz13_0600.title.all" : `All`,
    "zz13_0600.title.cancel" : `Cancel`,
    "zz13_0600.title.chinese" : `Chinese`,
    "zz13_0600.title.content" : `Contents`,
    "zz13_0600.title.english" : `English`,
    "zz13_0600.title.init" : `Initialization`,
    "zz13_0600.title.inputContent" : `Please input the content.`,
    "zz13_0600.title.inputSubject" : `Please input the title`,
    "zz13_0600.title.japanese" : `Japanese`,
    "zz13_0600.title.korean" : `Korean`,
    "zz13_0600.title.menuName" : `Connection Menu`,
    "zz13_0600.title.notUse" : `Inactive`,
    "zz13_0600.title.qnaReg" : `Q&A Write`,
    "zz13_0600.title.qnaType" : `Type`,
    "zz13_0600.title.save" : `Save`,
    "zz13_0600.title.search" : `Search`,
    "zz13_0600.title.subject" : `Subject`,
    "zz13_0600.title.use" : `Active`,
    "zz13_0600.title.useYn" : `Status`,
    "zz13_0700.message.inputPmRm" : `호텔객실 조회조건이 없습니다.`,
    "zz13_0700.message.inputProcessTime" : `처리일시 조회조건이 없습니다.`,
    "zz13_0700.message.inputSaleDate" : `판매일 조회조건이 없습니다.`,
    "zz13_0700.title.alRms" : `판매가능\n객실수`,
    "zz13_0700.title.cancelCmsOtaNo" : `취소된 OTA 예약번호`,
    "zz13_0700.title.cmsAlrms" : `CMS 판매가능 객실수`,
    "zz13_0700.title.cmsOtaNo" : `인입된 OTA 예약번호`,
    "zz13_0700.title.cmsSelledRoom" : `CMS 예약된 객실수`,
    "zz13_0700.title.createTime" : `변경시간`,
    "zz13_0700.title.createType" : `유형`,
    "zz13_0700.title.directClosed" : `수기마감`,
    "zz13_0700.title.hotelClosed" : `호텔 전체 마감`,
    "zz13_0700.title.hotelRoom" : `호텔객실`,
    "zz13_0700.title.occClosed" : `OCC 마감(%)`,
    "zz13_0700.title.occClosed2" : `점유율마감`,
    "zz13_0700.title.openReservation" : `예약 보기`,
    "zz13_0700.title.overBook" : `오버부킹`,
    "zz13_0700.title.pmsAlrms" : `PMS 판매가능 객실수`,
    "zz13_0700.title.pmsSellledRoom" : `PMS 예약된 객실수`,
    "zz13_0700.title.processId" : `처리자`,
    "zz13_0700.title.processTime" : `처리일시`,
    "zz13_0700.title.remainRoom" : `잔여\n객실수`,
    "zz13_0700.title.remark" : `비고`,
    "zz13_0700.title.revOverBook" : `오버부킹 여부`,
    "zz13_0700.title.roomClosed" : `호텔 객실 마감`,
    "zz13_0700.title.saleDate" : `판매일`,
    "zz13_0800.title.all" : `전체`,
    "zz13_0800.title.processContent" : `취급 내용`,
    "zz13_0800.title.processCount" : `취급 건수`,
    "zz13_0800.title.processPage" : `취급 화면`,
    "zz13_0800.title.processTime" : `처리시간`,
    "zz13_0800.title.processor" : `처리자`,
    "zz13_0800.title.propertyName" : `프로퍼티명`,
    "zz13_0800.title.searchDate" : `조회일자`,
    "zz13_0800.title.searchItem" : `항목`,
    "zz13_0800.title.userId" : `사용자 ID`,
    "zz20_0000.title.authChangeHistory" : `User Authority Change History`,
    "zz20_0000.title.baseTitle" : `시스템 관리 히스토리`,
    "zz20_0000.title.programHistory" : `Menu Use History`,
    "zz20_0000.title.userLoginHistory" : `User Login History`,
    "zz20_0100.btn.authentication" : `Authentication`,
    "zz20_0100.button.cancel" : `Cancel`,
    "zz20_0100.button.close" : `Exit`,
    "zz20_0100.button.download" : `Download`,
    "zz20_0100.button.excel" : `Excel`,
    "zz20_0100.button.init" : `Initialization`,
    "zz20_0100.button.save" : `Save`,
    "zz20_0100.button.search" : `Search`,
    "zz20_0100.message.dateFormat" : `Date format`,
    "zz20_0100.message.downloadReason" : `Please enter a reason for the download`,
    "zz20_0100.message.inputDate" : `Date is requried.`,
    "zz20_0100.message.inputProperty" : `프로퍼티 조건은 필수 입니다.`,
    "zz20_0100.message.maxPeriod" : `The maximum inquiry period is 3 months.`,
    "zz20_0100.message.noData" : `No Data`,
    "zz20_0100.message.requireDate" : `Please input the date`,
    "zz20_0100.message.requireReason" : `Reason is required column for download.`,
    "zz20_0100.title.accessDate" : `Access Date`,
    "zz20_0100.title.accessIp" : `Access IP`,
    "zz20_0100.title.detail" : `Detail`,
    "zz20_0100.title.downloadReason" : `Reason`,
    "zz20_0100.title.hotelName" : `Hotel Name`,
    "zz20_0100.title.loginDate" : `Login Date`,
    "zz20_0100.title.loginPeriod" : `Login Date`,
    "zz20_0100.title.property" : `Property`,
    "zz20_0100.title.updateList" : `Change History`,
    "zz20_0100.title.userId" : `User ID`,
    "zz20_0100.title.userId_userName" : `User ID/Name`,
    "zz20_0100.title.userIp" : `Access IP`,
    "zz20_0100.title.userName" : `User Name`,
    "zz20_0200.btn.authentication" : `Authentication`,
    "zz20_0200.btn.download" : `Downloader`,
    "zz20_0200.btn.excel" : `Excel`,
    "zz20_0200.btn.remove" : `Remove`,
    "zz20_0200.btn.search" : `Search`,
    "zz20_0200.buttom.init" : `Initialization`,
    "zz20_0200.buttom.search" : `Search`,
    "zz20_0200.etc.paging_number" : `Page`,
    "zz20_0200.etc.required" : `Required`,
    "zz20_0200.title.accessIp" : `Access IP`,
    "zz20_0200.title.accessPeriod" : `Menu Access Period`,
    "zz20_0200.title.first_reg_user_ip" : `User IP`,
    "zz20_0200.title.hotal_manager" : `Property`,
    "zz20_0200.title.proc_dt" : `Access Date`,
    "zz20_0200.title.proc_reason" : `Process Detail`,
    "zz20_0200.title.program_name" : `Menu`,
    "zz20_0200.title.user_operation_cn_name" : `Process Type`,
    "zz20_0200.title.userid" : `User ID`,
    "zz20_0200.title.userid_username" : `User ID/Name`,
    "zz20_0200.title.username" : `User Name`,
    "zz20_0200.validate.remove" : `Please check the data validity again.`,
    "zz20_0200.validation.connDate" : `Access Date cannot exceed more than 3 months from Start Date.`,
    "zz20_0200.validation.inputProperty" : `검색 필수 값이 필요합니다.`,
    "zz20_0300.buttom.init" : `Initialization`,
    "zz20_0300.buttom.search" : `Search`,
    "zz20_0300.etc.allColumn" : `All`,
    "zz20_0300.etc.required" : `Required`,
    "zz20_0300.message.inputProperty" : `프로퍼티 조건은 필수 입니다.`,
    "zz20_0300.title.authChangeHistory" : `Authority Change History`,
    "zz20_0300.title.connDt" : `Access Date`,
    "zz20_0300.title.filterConnDt" : `Process period`,
    "zz20_0300.title.filterConnIp" : `Access IP`,
    "zz20_0300.title.filterProgramName" : `Program Name`,
    "zz20_0300.title.filterPropertyName" : `Property Name`,
    "zz20_0300.title.filterUserName" : `User Name`,
    "zz20_0300.title.menuName" : `Menu`,
    "zz20_0300.title.procDt" : `Process Date`,
    "zz20_0300.title.procName" : `Processor`,
    "zz20_0300.title.propertyName" : `Property`,
    "zz20_0300.title.userid" : `User ID`,
    "zz20_0300.title.username" : `User Name`,
    "zz20_0300.validation.connDate" : `Access Date cannot exceed more than 3 months from Start Date.`,
    "zz20_0400.title.Reportmanagement" : `Management`,
    "zz20_0400.title.baseTitle" : `Transaction Report`,
    "zz20_0400.title.monthReport" : `Monthly`,
    "zz20_0400.title.monthReportEmptyMessage" : `There are no performance data for @(1) month of @(0) year.`,
    "zz20_0400.title.yearReport" : `Yearly`,
    "zz20_0400.title.yearReportEmptyMessage" : `There are no performance data for @(0) year.`,
    "zz20_0401.buttom.init" : `Initialization`,
    "zz20_0401.buttom.search" : `Search`,
    "zz20_0401.etc.counter" : `count`,
    "zz20_0401.etc.required" : `Required`,
    "zz20_0401.title.cancellation" : `Cancel`,
    "zz20_0401.title.channel" : `Channel`,
    "zz20_0401.title.channelName" : `Channel Name`,
    "zz20_0401.title.connectedHotels" : `connected hotels`,
    "zz20_0401.title.creation" : `Creation`,
    "zz20_0401.title.modification" : `Modification`,
    "zz20_0401.title.occuDt" : `Period of Occurrence`,
    "zz20_0401.title.sliderTitar" : `Monthly Transaction History`,
    "zz20_0401.title.totalCount" : `Total Count`,
    "zz20_0401.title.totalRoomRate" : `Total Room Rate`,
    "zz20_0401.title.transations" : `Booking Transactions`,
    "zz20_0402.buttom.init" : `Initialization`,
    "zz20_0402.buttom.search" : `Search`,
    "zz20_0402.etc.counter" : `count`,
    "zz20_0402.etc.required" : `Required`,
    "zz20_0402.title.april" : `April`,
    "zz20_0402.title.april_1" : `Apr`,
    "zz20_0402.title.august" : `August`,
    "zz20_0402.title.august_1" : `Aug`,
    "zz20_0402.title.cancellation" : `October`,
    "zz20_0402.title.category" : `Category`,
    "zz20_0402.title.channel" : `Channel`,
    "zz20_0402.title.channelName" : `Channel Name`,
    "zz20_0402.title.creation" : `February`,
    "zz20_0402.title.december_1" : `Dec`,
    "zz20_0402.title.february" : `February`,
    "zz20_0402.title.february_1" : `Feb`,
    "zz20_0402.title.january" : `January`,
    "zz20_0402.title.january_1" : `Jan`,
    "zz20_0402.title.july" : `July`,
    "zz20_0402.title.july_1" : `Jul`,
    "zz20_0402.title.june" : `June`,
    "zz20_0402.title.june_1" : `Jun`,
    "zz20_0402.title.march" : `March`,
    "zz20_0402.title.march_1" : `Mar`,
    "zz20_0402.title.may" : `May`,
    "zz20_0402.title.may_1" : `May`,
    "zz20_0402.title.modification" : `March`,
    "zz20_0402.title.nobember" : `Nobember`,
    "zz20_0402.title.nobember_1" : `Nov`,
    "zz20_0402.title.occuDt" : `Period of Occurrence`,
    "zz20_0402.title.occuDt_1" : `Year of Occurrence`,
    "zz20_0402.title.october" : `October`,
    "zz20_0402.title.october_1" : `Oct`,
    "zz20_0402.title.september" : `September`,
    "zz20_0402.title.september_1" : `Sep`,
    "zz20_0402.title.sliderTitar" : `Yearly Transaction History`,
    "zz20_0402.title.totalHotel" : `Total Hotel`,
    "zz20_0402.title.transations" : `January`,
    "zz20_0403.buttom.close" : `Close`,
    "zz20_0403.buttom.gridCancel" : `Cancel`,
    "zz20_0403.buttom.gridSave" : `Save`,
    "zz20_0403.buttom.init" : `Initialization`,
    "zz20_0403.buttom.more" : `More`,
    "zz20_0403.buttom.popupCancel" : `Cancel`,
    "zz20_0403.buttom.popupSave" : `Save`,
    "zz20_0403.buttom.search" : `Search`,
    "zz20_0403.etc.required" : `Required`,
    "zz20_0403.title.active" : `USE Y/N`,
    "zz20_0403.title.channel" : `Channel`,
    "zz20_0403.title.channelName" : `Channel Name`,
    "zz20_0403.title.company" : `Company`,
    "zz20_0403.title.contractDate" : `Contract date`,
    "zz20_0403.title.contractTerms" : `Contract Terms and Condition`,
    "zz20_0403.title.contractorInfo" : `Contractor Information`,
    "zz20_0403.title.contractorName" : `Contractor`,
    "zz20_0403.title.department" : `Department`,
    "zz20_0403.title.email" : `Email`,
    "zz20_0403.title.endDate" : `End Date`,
    "zz20_0403.title.gridUseActiveY" : `Use`,
    "zz20_0403.title.gridUseActiven" : `No Use`,
    "zz20_0403.title.memo" : `Memo`,
    "zz20_0403.title.office_phone" : `Office Phone`,
    "zz20_0403.title.orderFrequency" : `Billing Period`,
    "zz20_0403.title.phone" : `Mobile`,
    "zz20_0403.title.popuptitle" : `Channel Manager detail.`,
    "zz20_0403.title.startDate" : `Start Date`,
    "zz20_0403.title.surtax" : ` VAT (%)`,
    "zz20_0403.title.surtaxType" : ` VAT Type`,
    "zz20_0403.title.useActiveAll" : `All`,
    "zz20_0403.title.useActiveN" : `No Use`,
    "zz20_0403.title.useActiveY" : `Use`,
    "zz20_0403.title.useActiveYn" : `USE Y/N`,
    "zz20_0403.valid.close" : `Data Saved succesfully.`,
    "zz20_0403.valid.emailFormatter" : `Invalid email address`,
    "zz20_0403.valid.emptyInput" : `You should input the contractor information.`,
    "zz20_0403.valid.emptyInputContract" : `Information should be entered in the entry box.`,
    "zz20_0403.valid.emptyInputContract_1" : `Please input @(0).`,
    "zz20_0403.valid.emptyInputEmail" : `There is no email in the contractor information.`,
    "zz20_0403.valid.emptyInput_1" : `There is no contractor name on contractor information.`,
    "zz20_0403.valid.emptyUpdateDate" : `No change`,
    "zz20_0403.valid.numberFormatter" : `Non Number Values cannot be in Mobile or Office number.`,
    "zz20_0403.valid.secuuessMessage" : `Data Saved succesfully.`,
}